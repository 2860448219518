.header-wrap-bg {
  width: 100%;
  height: 140px;
  background-color: #fff;
  position: relative;
}

.header_right_wrap_d {
  z-index: 2;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.header_right_wrap_d.div_block {
  display: block;
}

.header_right_wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.hd_right_menu_wrap {
  width: 700px;

  background-color: #fff;
  position: absolute;
  z-index: 4;
  height: 100%;

  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
}
.hd_right_menu_wrap .hd_right_menu_inner {
  padding: 0 87px;
  padding-top: 0px;
}

.hd_right_menu_wrap .hd_right_menu_close_box {
  width: 100%;
  background: url("../../assets/images/group_bg2.png") center;
  background-size: cover;
  height: 110px;
}
.hd_right_menu_wrap .hd_right_menu_close_box .hd_right_menu_close_box_inner {
  padding: 0 87px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
}
.hd_right_menu_close_box_inner div {
  width: 48px;
  height: 48px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.hd_right_menu_close_box_inner div button {
  width: 42px;
  height: 42px;
  background: #f5a21f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hd_right_menu_close_box_inner div button svg {
  width: 42px;
  height: 42px;
  color: #fff;
}
.hd_right_menu_wrap .hd_right_menu_inner > div {
  padding: 35px 0;
}
.hd_right_menu_top {
  width: 100%;
  display: flex;
  border-bottom: 4px solid #dcdddd;
}

.hd_right_menu_top .hd_right_menu_img {
  width: 120px;
  height: 120px;
}
.hd_right_menu_top .hd_right_menu_img img {
  width: 100%;
  height: 100%;
}
.hd_right_menu_top .hd_right_menu_info {
  margin-left: 40px;
  font-family: "recoFont";
  flex: 1;
}
.hd_right_menu_info span {
  line-height: 0;
  height: auto;
}
.hd_right_menu_top .hd_info_one,
.hd_right_menu_top .hd_info_two,
.hd_right_menu_top .hd_info_three {
  font-size: 18px;
  display: flex;
  align-items: center;
}
.hd_right_menu_top .hd_info_one {
  justify-content: space-between;
}
.hd_right_menu_top .hd_info_one > div:first-child > span:first-child {
  font-size: 26px;
}
.hd_right_menu_top .hd_info_one > div:last-child {
  width: 110px;
  height: 35px;
  background-color: #40b3e6;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.hd_right_menu_top .hd_info_one > div:last-child button {
  line-height: 40px;
}
.hd_right_menu_top .hd_info_two {
  margin-bottom: 10px;
}
.hd_right_menu_top .hd_info_two > div:first-child {
  color: #fff;
  width: 80px;
  height: 35px;
  background-color: #40b3e6;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hd_right_menu_top .hd_info_three > div:first-child {
  color: #fff;
  width: 80px;
  height: 35px;
  background-color: #f5a21f;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hd_right_menu_top .hd_info_two > div:last-child,
.hd_right_menu_top .hd_info_three > div:last-child {
  font-family: "KopubDotum";
  font-weight: 600;
  font-size: 20px;
  margin-left: 20px;
}
.hd_right_menu_middle {
  width: 100%;
  border-bottom: 4px solid #dcdddd;
}
.hd_right_menu_middle ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.hd_right_menu_middle .middle_menu_li {
  width: 240px;
  height: 70px;
  position: relative;
  /* margin-bottom: 20px; */
  border-radius: 10px;
  background: url("../../assets/images/menu_li_bg.png") no-repeat;
  background-size: 100% 100%;
  box-shadow: 0 10px 6px -6px #777;
}

.hd_right_menu_middle .middle_menu_li a {
  width: 100%;
  height: 100%;
  display: block;
  font-family: "recoFont";
}
.hd_right_menu_middle .middle_menu_li .middle_menu_div {
  width: 100%;
  height: 80px;
  align-items: center;
  display: flex;
  padding-left: 20px;
}
.hd_right_menu_middle .middle_menu_li .middle_menu_div img {
  width: 45px;
  height: auto;
  margin-right: 8px;
}
.hd_right_menu_middle .middle_menu_li .middle_menu_div span {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}

.hd_right_menu_bottom ul {
  display: flex;
}
.hd_right_menu_bottom .bottom_menu_li {
  flex: 1;
  height: 140px;
  padding: 0 16px;
}

.hd_right_menu_bottom .bottom_menu_li a {
  width: 100%;
  height: 100%;
  display: block;
}
.hd_right_menu_bottom .bottom_menu_li .bottom_menu_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.hd_right_menu_bottom .bottom_menu_li .bottom_menu_div img {
  display: block;
  max-width: 120px;
}
.hd_right_menu_bottom .bottom_menu_li .bottom_menu_div span {
  font-size: 24px;
  font-weight: 400;
  font-family: "KopubDotum";
}

.header-wrap {
  width: 100%;
  height: 130px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: url("../../assets/images/group_bg2.png") center;
  background-size: cover;
}
.big_wrap {
  width: 1500px;
  height: 75px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.big_wrap .group {
  display: flex;
  align-items: center;
  flex: none;
}

/* 로고 */
.logo {
  width: 210px;
}
.logo .logo_anchor {
}

/* 코인박스 */

.coin_box {
  display: flex;
  align-items: center;
  height: auto;
  margin-left: 22px;
}
.coin_box .coin_item {
  margin: 0 11px;

  width: 110px;
  height: 50px;
}
.coin_box .coin_btn {
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  font-family: "KopubDotum";
  font-weight: 600;

  height: 100%;
  width: 100%;
  display: block;
}
.coin_box .coin_btn > div {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 3px solid #fff;
  overflow: hidden;
  border-radius: 10px;
}
/* .coin_box .coin_btn::before {
  clear: both;
  content: "";
} */
.coin_box .coin_btn .conin_icon {
  /* border-right: 3px solid #fff; */
  width: 30px;
  height: 100%;
  display: inline-block;
  flex: none;
  font-family: "recoFont";
  text-align: center;
  line-height: 50px;
  font-size: 25px;
  background-color: #40b3e6;
  /* background: url("../../assets/images/c.png") center no-repeat;
  background-color: 100%; */
  /* float: left; */
}
.coin_box .coin_btn span {
  /* height: 100%; */
  flex: 1;
  color: #40b3e6;
  text-align: center;
  line-height: 50px;
  display: block;
}
.coin_box .coin_btn:before {
}

.coin_box .coin_item2 {
  width: 48px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coin_box .coin_item2 svg {
  width: 100%;
  height: 100%;
}
.coin_box .all_btn {
  width: 48px;
  height: 45px;

  font-family: "recoFont";
  font-size: 18px;
  /* line-height: 22px; */
  color: #3fb3e6;
  text-align: center;
  background-color: transparent;
  position: relative;
  /* transition: all 0.3s linear; */
}
.coin_box .all_btn svg {
  /* position: absolute; */
}
.coin_box .all_btn span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  line-height: 55px;
}

.site_div {
  /* width: 100%; */
  display: flex;
  flex: 1;
  padding-left: 60px;
  justify-content: space-between;
  align-items: center;
}
.site_div .site_ul,
.site_div .site_ul2 {
  display: flex;
}
.site_div .site_ul li {
  margin: 0 10px;
}
.site_div .site_ul li a {
  padding: 2px 10px;
  border: 4px solid #fff;
  border-radius: 60px;
  font-size: 20px;
  color: #fff;
  font-family: "KopubDotum";
  font-weight: 600;
}
.site_div .site_nav2 {
  display: flex;
  align-items: center;
}
.site_div .site_ul2 li {
  width: 50px;
  height: 50px;
  background-color: #f5a21f;
  border: 3px solid #fff;
  border-radius: 10px;
  margin: 0 15px;
}
.site_div .site_ul2 li a {
  display: block;
  width: 100%;
  height: 100%;
}
.site_div .site_ul2 li div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.site_div .site_nav2 > ul > li {
  /* width: 54px;
  height: 50px; */
}

.site_div .site_nav2 div {
  width: 54px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .site_div .site_nav2 ul svg {
  width: 70%;
} */
.site_div .site_nav2 > ul svg {
  width: 100%;
  /* height: 100%; */
}
.site_div .site_nav2 > div svg {
  width: 100%;
  /* height: 100%; */
}
@media all and (max-width: 1600px) {
  .big_wrap {
    width: 100%;
    padding: 0 4.20833333333%;
  }
}
@media all and (max-width: 1300px) {
  .header-wrap-bg {
    height: 120px;
  }

  .header_right_wrap_d {
    /* z-index: 2;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; */
  }
  .header_right_wrap_d.div_block {
  }

  .header_right_wrap {
  }

  .hd_right_menu_wrap {
    width: 600px;
  }
  .hd_right_menu_wrap .hd_right_menu_inner {
    padding: 0 60px;
    padding-top: 0px;
  }

  .hd_right_menu_wrap .hd_right_menu_close_box {
    width: 100%;
    background: url("../../assets/images/group_bg2.png") center;
    background-size: cover;
    height: 110px;
  }
  .hd_right_menu_wrap .hd_right_menu_close_box .hd_right_menu_close_box_inner {
    padding: 0 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 72px;
  }
  .hd_right_menu_close_box_inner div {
    width: 48px;
    height: 48px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .hd_right_menu_close_box_inner div button {
    width: 42px;
    height: 42px;
    background: #f5a21f;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hd_right_menu_close_box_inner div button svg {
    width: 42px;
    height: 42px;
    color: #fff;
  }
  .hd_right_menu_wrap .hd_right_menu_inner > div {
    padding: 35px 0;
  }
  .hd_right_menu_top {
    width: 100%;
    display: flex;
    border-bottom: 4px solid #dcdddd;
  }

  .hd_right_menu_top .hd_right_menu_img {
    width: 120px;
    height: 120px;
  }
  .hd_right_menu_top .hd_right_menu_img img {
    width: 100%;
    height: 100%;
  }
  .hd_right_menu_top .hd_right_menu_info {
    margin-left: 40px;
    font-family: "recoFont";
    flex: 1;
  }
  .hd_right_menu_info span {
    line-height: 0;
    height: auto;
  }
  .hd_right_menu_top .hd_info_one,
  .hd_right_menu_top .hd_info_two,
  .hd_right_menu_top .hd_info_three {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .hd_right_menu_top .hd_info_one {
    justify-content: space-between;
  }
  .hd_right_menu_top .hd_info_one > div:first-child > span:first-child {
    font-size: 26px;
  }
  .hd_right_menu_top .hd_info_one > div:last-child {
    width: 110px;
    height: 35px;
    background-color: #40b3e6;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .hd_right_menu_top .hd_info_one > div:last-child button {
    line-height: 40px;
  }
  .hd_right_menu_top .hd_info_two {
    margin-bottom: 10px;
  }
  .hd_right_menu_top .hd_info_two > div:first-child {
    color: #fff;
    width: 80px;
    height: 35px;
    background-color: #40b3e6;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hd_right_menu_top .hd_info_three > div:first-child {
    color: #fff;
    width: 80px;
    height: 35px;
    background-color: #f5a21f;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hd_right_menu_top .hd_info_two > div:last-child,
  .hd_right_menu_top .hd_info_three > div:last-child {
    font-family: "KopubDotum";
    font-weight: 600;
    font-size: 20px;
    margin-left: 20px;
  }
  .hd_right_menu_middle {
    width: 100%;
    border-bottom: 4px solid #dcdddd;
  }
  .hd_right_menu_middle ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .hd_right_menu_middle .middle_menu_li {
    width: 240px;
    background-color: #40b3e6;
    height: 70px;
    position: relative;
    /* margin-bottom: 20px; */
    border-radius: 10px;
    box-shadow: 0 10px 6px -6px #777;
  }
  .hd_right_menu_middle .middle_menu_li::after {
    position: absolute;
    top: -2px;
    right: -2px;
    display: block;
    z-index: 1;
    width: 0;
    height: 0;
    border-bottom: 20px solid transparent;
    border-top: 20px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid #fff;
    content: "";
  }
  .hd_right_menu_middle .middle_menu_li a {
    width: 100%;
    height: 100%;
    display: block;
    font-family: "recoFont";
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div {
    width: 100%;
    height: 80px;
    align-items: center;
    display: flex;
    padding-left: 20px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div img {
    width: 45px;
    height: auto;
    margin-right: 8px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div span {
    font-size: 22px;
    color: #fff;
    font-weight: 400;
  }

  .hd_right_menu_bottom ul {
    display: flex;
  }
  .hd_right_menu_bottom .bottom_menu_li {
    flex: 1;
    height: 140px;
    padding: 0 20px;
  }

  .hd_right_menu_bottom .bottom_menu_li a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div img {
    display: block;
    max-width: 120px;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div span {
    font-size: 20px;
    font-weight: 400;
    font-family: "KopubDotum";
  }

  .header-wrap {
    height: 100px;
  }
  .big_wrap {
    height: 60px;
  }

  /* 로고 */
  .logo {
    width: 160px;
  }
  .logo .logo_anchor {
  }

  /* 코인박스 */
  .coin_box {
    margin-left: 10px;
  }
  .coin_box .coin_item {
    margin: 0 11px;

    width: 90px;
    height: 40px;
  }
  .coin_box .coin_btn {
    font-size: 18px;
    line-height: 22px;
  }
  .coin_box .coin_btn > div {
    border: 2px solid #fff;

    border-radius: 10px;
  }
  .coin_box .coin_btn .conin_icon {
    width: 25px;

    line-height: 40px;
    font-size: 20px;
  }
  .coin_box .coin_btn span {
    line-height: 40px;
    font-size: 14px;
  }
  .coin_box .coin_btn:before {
  }

  .coin_box .coin_item2 {
    width: 38px;
    height: 35px;
  }
  .coin_box .coin_item2 svg {
  }
  .coin_box .all_btn {
    width: 38px;
    height: 35px;

    font-size: 14px;
  }

  .coin_box .all_btn span {
    line-height: 38px;
  }

  .site_div {
    padding-left: 50px;
  }
  .site_div .site_ul,
  .site_div .site_ul2 {
  }
  .site_div .site_ul li {
    margin: 0 10px;
  }
  .site_div .site_ul li a {
    padding: 2px 10px;
    border: 4px solid #fff;
    border-radius: 60px;
    font-size: 20px;
    color: #fff;
    font-family: "KopubDotum";
    font-weight: 600;
  }
  .site_div .site_nav2 {
    display: flex;
    align-items: center;
  }
  .site_div .site_ul2 li {
    width: 38px;
    height: 38px;
    border: 2px solid #fff;
    border-radius: 10px;
    margin: 0 15px;
  }
  .site_div .site_ul2 li a {
  }
  .site_div .site_nav2 > ul > li {
  }
  .site_div .site_nav2 > ul svg {
    /* width: 55%;
    height: 55%; */
  }
  .site_div .site_nav2 div {
    width: 38px;
    height: 38px;
  }
  .site_div .site_nav2 > ul svg {
    width: 60%;
    /* height: 100%; */
  }
  .site_div .site_nav2 > div svg {
    width: 100%;
    /* height: 100%; */
  }
}

@media all and (max-width: 1100px) {
  .header-wrap-bg {
    height: 100px;
  }

  .header_right_wrap_d {
    /* z-index: 2;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; */
  }
  .header_right_wrap_d.div_block {
  }

  .header_right_wrap {
  }

  .hd_right_menu_wrap {
    width: 480px;
  }
  .hd_right_menu_wrap .hd_right_menu_inner {
    padding: 0 20px;
    padding-top: 0px;
  }

  .hd_right_menu_wrap .hd_right_menu_close_box {
    height: 90px;
  }
  .hd_right_menu_wrap .hd_right_menu_close_box .hd_right_menu_close_box_inner {
    padding: 0 20px;

    height: 50px;
  }
  .hd_right_menu_close_box_inner div {
    width: 30px;
    height: 30px;

    border-radius: 3px;
  }
  .hd_right_menu_close_box_inner div button {
    width: 24px;
    height: 24px;

    border-radius: 3px;
  }
  .hd_right_menu_close_box_inner div button svg {
    width: 24px;
    height: 24px;
  }
  .hd_right_menu_wrap .hd_right_menu_inner > div {
    padding: 10px 0;
  }
  .hd_right_menu_top {
    border-bottom: 3px solid #dcdddd;
  }

  .hd_right_menu_top .hd_right_menu_img {
    width: 100px;
    height: 100px;
  }
  .hd_right_menu_top .hd_right_menu_img img {
    width: 100%;
    height: 100%;
  }
  .hd_right_menu_top .hd_right_menu_info {
    margin-left: 14px;
  }
  .hd_right_menu_info span {
  }
  .hd_right_menu_top .hd_info_one,
  .hd_right_menu_top .hd_info_two,
  .hd_right_menu_top .hd_info_three {
    font-size: 12px;
  }
  .hd_right_menu_top .hd_info_one {
    justify-content: space-between;
  }
  .hd_right_menu_top .hd_info_one > div:first-child > span:first-child {
    font-size: 20px;
  }
  .hd_right_menu_top .hd_info_one > div:last-child {
    width: 70px;
    height: 30px;

    border-radius: 16px;
  }
  .hd_right_menu_top .hd_info_one > div:last-child button {
    line-height: 40px;
  }
  .hd_right_menu_top .hd_info_two {
    margin-bottom: 8px;
  }
  .hd_right_menu_top .hd_info_two > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }
  .hd_right_menu_top .hd_info_three > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }

  .hd_right_menu_top .hd_info_two > div:last-child,
  .hd_right_menu_top .hd_info_three > div:last-child {
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
  }
  .hd_right_menu_middle {
    border-bottom: 3px solid #dcdddd;
  }
  .hd_right_menu_middle ul {
  }
  .hd_right_menu_middle .middle_menu_li {
    width: 200px;

    height: 60px;

    border-radius: 10px;
    box-shadow: 0 10px 6px -6px #777;
  }
  .hd_right_menu_middle .middle_menu_li::after {
    border-bottom: 16px solid transparent;
    border-top: 16px solid #fff;
    border-left: 16px solid transparent;
    border-right: 16px solid #fff;
  }
  .hd_right_menu_middle .middle_menu_li a {
    width: 100%;
    height: 100%;
    display: block;
    font-family: "recoFont";
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div {
    height: 60px;
    padding-left: 12px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div img {
    width: 35px;
    height: auto;
    margin-right: 4px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }

  .hd_right_menu_bottom ul {
    display: flex;
  }
  .hd_right_menu_bottom .bottom_menu_li {
    flex: 1;
    /* justify-content: center;
    align-items: center; */
    height: 110px;
    padding: 0 14px;
  }

  .hd_right_menu_bottom .bottom_menu_li a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div img {
    display: block;
    max-width: 80px;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div span {
    font-size: 18px;
    font-weight: 400;
    font-family: "KopubDotum";
  }

  .header-wrap {
    height: 90px;
  }
  .big_wrap {
    height: 55px;
  }

  /* 로고 */
  .logo {
    width: 140px;
  }
  .logo .logo_anchor {
  }

  /* 코인박스 */
  .coin_box {
    margin-left: 10px;
  }
  .coin_box .coin_item {
    margin: 0 8px;

    width: 72px;
    height: 33px;
  }

  .coin_box .coin_btn {
    font-size: 10px;
    line-height: 20px;
    /* background-color: red; */
  }
  .coin_box .coin_btn > div {
    border: 2px solid #fff;

    border-radius: 10px;
  }

  .coin_box .coin_btn .conin_icon {
    width: 20px;

    line-height: 32px;
    font-weight: 500;
    font-size: 12px;
  }
  .coin_box .coin_btn span {
    line-height: 32px;
    font-size: 12px;
  }
  .coin_box .coin_btn:before {
  }

  .coin_box .coin_item2 {
    width: 38px;
    height: 35px;
  }
  .coin_box .coin_item2 svg {
  }
  .coin_box .all_btn {
    width: 31px;
    height: 29px;

    font-size: 10px;
  }

  .coin_box .all_btn span {
    line-height: 32px;
  }

  .site_div {
    padding-left: 30px;
  }
  .site_div .site_ul,
  .site_div .site_ul2 {
  }
  .site_div .site_ul li {
    margin: 0 8px;
  }
  .site_div .site_ul li a {
    padding: 1px 8px;
    border: 3px solid #fff;
    border-radius: 60px;
    font-size: 16px;
  }
  .site_div .site_nav2 {
    display: flex;
    align-items: center;
  }
  .site_div .site_ul2 li {
    width: 32px;
    height: 32px;
    border: 2px solid #fff;
    border-radius: 10px;
    margin: 0 8px;
  }
  .site_div .site_ul2 li a {
  }
  .site_div .site_nav2 > ul > li {
  }
  .site_div .site_nav2 > ul svg {
  }
  .site_div .site_nav2 div {
    width: 32px;
    height: 32px;
  }
  .site_div .site_nav2 > ul svg {
    width: 60%;
  }
  .site_div .site_nav2 > div svg {
    width: 100%;
  }
}
@media all and (max-width: 780px) {
  .header-wrap-bg {
    height: 90px;
  }

  .header_right_wrap_d {
    /* z-index: 2;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; */
  }
  .header_right_wrap_d.div_block {
  }

  .header_right_wrap {
  }

  .hd_right_menu_wrap {
    width: 340px;
  }
  .hd_right_menu_wrap .hd_right_menu_inner {
    padding: 0 14px;
    padding-top: 0px;
  }

  .hd_right_menu_wrap .hd_right_menu_close_box {
    height: 80px;
  }
  .hd_right_menu_wrap .hd_right_menu_close_box .hd_right_menu_close_box_inner {
    padding: 0 14px;

    height: 50px;
  }
  .hd_right_menu_close_box_inner div {
    width: 30px;
    height: 30px;

    border-radius: 3px;
  }
  .hd_right_menu_close_box_inner div button {
    width: 24px;
    height: 24px;

    border-radius: 3px;
  }
  .hd_right_menu_close_box_inner div button svg {
    width: 24px;
    height: 24px;
    /* color: #fff; */
  }
  .hd_right_menu_wrap .hd_right_menu_inner > div {
    padding: 10px 0;
  }
  .hd_right_menu_top {
    border-bottom: 2px solid #dcdddd;
  }

  .hd_right_menu_top .hd_right_menu_img {
    width: 80px;
    height: 80px;
  }
  .hd_right_menu_top .hd_right_menu_img img {
  }
  .hd_right_menu_top .hd_right_menu_info {
    margin-left: 10px;
  }
  .hd_right_menu_info span {
  }
  .hd_right_menu_top .hd_info_one,
  .hd_right_menu_top .hd_info_two,
  .hd_right_menu_top .hd_info_three {
    font-size: 12px;
  }
  .hd_right_menu_top .hd_info_one {
    justify-content: space-between;
  }
  .hd_right_menu_top .hd_info_one > div:first-child > span:first-child {
    font-size: 20px;
  }
  .hd_right_menu_top .hd_info_one > div:last-child {
    width: 70px;
    height: 30px;

    border-radius: 16px;
  }
  .hd_right_menu_top .hd_info_one > div:last-child button {
    line-height: 40px;
  }
  .hd_right_menu_top .hd_info_two {
    margin-bottom: 8px;
  }
  .hd_right_menu_top .hd_info_two > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }
  .hd_right_menu_top .hd_info_three > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }

  .hd_right_menu_top .hd_info_two > div:last-child,
  .hd_right_menu_top .hd_info_three > div:last-child {
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
  }
  .hd_right_menu_middle {
    border-bottom: 2px solid #dcdddd;
  }
  .hd_right_menu_middle ul {
  }
  .hd_right_menu_middle .middle_menu_li {
    width: 60%;

    height: 50px;
    position: relative;
    /* margin-bottom: 16px; */
    border-radius: 8px;
    box-shadow: -5px 8px 6px -6px #777;
  }
  .hd_right_menu_middle .middle_menu_li::after {
    border-bottom: 16px solid transparent;
    border-top: 16px solid #fff;
    border-left: 16px solid transparent;
    border-right: 16px solid #fff;
  }
  .hd_right_menu_middle .middle_menu_li a {
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div {
    height: 50px;

    padding-left: 10px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div img {
    width: 30px;
    height: auto;
    margin-right: 4px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }

  .hd_right_menu_bottom ul {
    display: flex;

    /* align-items: center; */
  }
  .hd_right_menu_bottom .bottom_menu_li {
    flex: 1;
    /* justify-content: center;
    align-items: center; */
    height: 80px;
    padding: 0 14px;
  }

  .hd_right_menu_bottom .bottom_menu_li a {
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div {
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div img {
    display: block;
    max-width: 50px;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div span {
    font-size: 14px;
    font-weight: 400;
    font-family: "KopubDotum";
  }

  .header-wrap {
    height: 80px;
  }
  .big_wrap {
    height: 47px;
  }

  /* 로고 */
  .logo {
    width: 130px;
  }
  .logo .logo_anchor {
  }

  /* 코인박스 */
  .coin_box {
    margin-left: 8px;
  }
  .coin_box .coin_item {
    margin: 0 8px;

    width: 55px;
    height: 25px;
  }

  .coin_box .coin_btn {
    font-size: 14px;
    line-height: 18px;
  }
  .coin_box .coin_btn > div {
    border: 2px solid #fff;

    border-radius: 6px;
  }

  .coin_box .coin_btn .conin_icon {
    width: 14px;

    line-height: 26px;
    font-weight: 500;
    font-size: 14px;
  }
  .coin_box .coin_btn span {
    line-height: 26px;
    font-size: 10px;
  }
  .coin_box .coin_btn:before {
  }

  .coin_box .coin_item2 {
    width: 30px;
    height: 27px;
  }
  .coin_box .coin_item2 svg {
  }
  .coin_box .all_btn {
    width: 30px;
    height: 27px;

    font-size: 10px;
  }

  .coin_box .all_btn span {
    line-height: 30px;
  }

  .site_div {
    padding-left: 25px;
  }
  .site_div .site_ul,
  .site_div .site_ul2 {
  }
  .site_div .site_ul li {
    margin: 0 8px;
  }
  .site_div .site_ul li a {
    padding: 1px 7px;
    border: 2px solid #fff;
    border-radius: 50px;
    font-size: 12px;
  }
  .site_div .site_nav2 {
    display: flex;
    align-items: center;
  }
  .site_div .site_ul2 li {
    width: 26px;
    height: 26px;
    border: 2px solid #fff;
    border-radius: 8px;
    margin: 0 4px;
  }
  .site_div .site_ul2 li a {
  }
  .site_div .site_nav2 > ul > li {
  }
  .site_div .site_nav2 > ul svg {
  }
  .site_div .site_nav2 div {
    width: 30px;
    height: 30px;
  }
  .site_div .site_nav2 > ul svg {
    width: 60%;
  }
  .site_div .site_nav2 > div svg {
    width: 100%;
  }
}

@media all and (max-width: 640px) {
  .header-wrap-bg {
    height: 88px;
  }
  .header-wrap {
    height: 88px;

    background: url("../../assets/images/groupbg_m.png") center;
    background-size: cover;
  }
  .big_wrap {
    height: 70px;
    /* background-color: red; */
  }

  .header_right_wrap {
  }
  .header_right_wrap_d {
  }
  .header_right_wrap_d.div_block {
  }
  .hd_right_menu_wrap {
    width: 100%;
  }
  .hd_right_menu_wrap .hd_right_menu_inner {
    padding: 0 35px;
  }

  .hd_right_menu_wrap .hd_right_menu_close_box {
    height: 90px;
  }
  .hd_right_menu_wrap .hd_right_menu_close_box .hd_right_menu_close_box_inner {
    padding: 0 35px;

    height: 60px;
  }
  .hd_right_menu_close_box_inner div {
    width: 28px;
    height: 28px;

    border-radius: 5px;
  }
  .hd_right_menu_close_box_inner div button {
    width: 24px;
    height: 24px;

    border-radius: 5px;
    /* font-size: 30px; */
  }
  .hd_right_menu_close_box_inner div button svg {
    width: 24px;
    height: 24px;
  }
  .hd_right_menu_wrap .hd_right_menu_inner > div {
    padding: 25px 0;
  }
  .hd_right_menu_top {
    border-bottom: 3px solid #dcdddd;
  }
  .hd_right_menu_top .hd_right_menu_img {
    width: 90px;
    height: 90px;
  }
  .hd_right_menu_top .hd_right_menu_img img {
  }
  .hd_right_menu_top .hd_right_menu_info {
    margin-left: 20px;
  }
  .hd_right_menu_top .hd_info_one,
  .hd_right_menu_top .hd_info_two,
  .hd_right_menu_top .hd_info_three {
    font-size: 12px;
  }
  .hd_right_menu_top .hd_info_one {
  }
  .hd_right_menu_top .hd_info_one > div:first-child > span:first-child {
    font-size: 20px;
    /* background-color: red; */
  }
  .hd_right_menu_top .hd_info_one > div:last-child {
    width: 70px;
    height: 30px;

    border-radius: 16px;
  }
  .hd_right_menu_top .hd_info_two {
    margin-bottom: 8px;
  }
  .hd_right_menu_top .hd_info_two > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }
  .hd_right_menu_top .hd_info_three > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }

  .hd_right_menu_top .hd_info_two > div:last-child,
  .hd_right_menu_top .hd_info_three > div:last-child {
    /* font-family: "KopubDotum"; */
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
  }
  .hd_right_menu_middle {
    width: 100%;
    border-bottom: 3px solid #dcdddd;
  }
  .hd_right_menu_middle ul {
  }
  .hd_right_menu_middle .middle_menu_li {
    width: 45%;

    height: 55px;
    position: relative;
    /* margin-bottom: 16px; */
    border-radius: 8px;
    box-shadow: -5px 8px 6px -6px #777;
  }
  .hd_right_menu_middle .middle_menu_li::after {
    border-bottom: 16px solid transparent;
    border-top: 16px solid #fff;
    border-left: 16px solid transparent;
    border-right: 16px solid #fff;
  }
  .hd_right_menu_middle .middle_menu_li a {
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div {
    height: 55px;

    padding-left: 10px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div img {
    width: 25px;
    height: auto;
    margin-right: 8px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div span {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    height: 20px;
    line-height: 30px;
    /* margin-right: 12%; */
  }

  .hd_right_menu_bottom ul {
    display: flex;
  }
  .hd_right_menu_bottom .bottom_menu_li {
    flex: 1;
    height: 100px;
    padding: 0 18px;
  }

  .hd_right_menu_bottom .bottom_menu_li a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div {
    width: 100%;
    height: 100%;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div img {
    display: block;
    max-width: 70px;
  }
  .hd_right_menu_bottom .bottom_menu_li:first-child .bottom_menu_div img {
    max-width: 50px;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div span {
    font-size: 16px;
    /* color: #fff; */
    font-weight: 400;
    font-family: "KopubDotum";
  }
  .logo {
    width: 130px;
  } /* 코인박스 */
  .coin_box {
    margin-left: 10px;
  }
  .coin_box .coin_item {
    margin: 0 8px;

    width: 55px;
    height: 25px;
  }
  .coin_box .coin_btn {
    font-size: 14px;
    line-height: 18px;
  }
  .coin_box .coin_btn > div {
    border: 2px solid #fff;

    border-radius: 6px;
  }
  /* .coin_box .coin_btn::before {
    clear: both;
    content: "";
  } */
  .coin_box .coin_btn .conin_icon {
    /* border-right: 3px solid #fff; */
    width: 15px;
    height: 100%;
    display: inline-block;
    flex: none;
    font-family: "recoFont";
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    /* background: url("../../assets/images/c.png") center no-repeat;
    background-color: 100%; */
    /* float: left; */
  }
  .coin_box .coin_btn span {
    background-color: #fff;
    height: 100%;
    flex: 1;
    color: #40b3e6;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
  }
  .coin_box .coin_btn:before {
  }

  .coin_box .coin_item2 {
    width: 26px;
    height: 24px;
  }
  .coin_box .all_btn {
    width: 26px;
    height: 24px;
    /* background-color: red; */
    font-family: "recoFont";
    font-size: 10px;
    /* line-height: 22px; */
    color: #3fb3e6;
    text-align: center;
    background-color: transparent;
    position: relative;
    /* transition: all 0.3s linear; */
  }
  .coin_box .all_btn svg {
    /* position: absolute; */
  }
  .coin_box .all_btn span {
    line-height: 24px;
  }
  .site_div .site_ul {
    display: none;
  }
  .site_div .site_nav2 div {
    width: 30px;
    height: 30px;
  }
}

@media all and (max-width: 440px) {
  .header-wrap-bg {
    height: 88px;
  }
  .header-wrap {
    height: 88px;

    background: url("../../assets/images/groupbg_m.png") center;
    background-size: cover;
  }
  .big_wrap {
    height: 70px;
    /* background-color: red; */
  }

  .hd_right_menu_wrap .hd_right_menu_inner {
    padding: 0 25px;
  }

  .hd_right_menu_wrap .hd_right_menu_close_box {
    height: 90px;
  }
  .hd_right_menu_wrap .hd_right_menu_close_box .hd_right_menu_close_box_inner {
    padding: 0 25px;

    height: 60px;
  }
  .hd_right_menu_close_box_inner div {
    width: 28px;
    height: 28px;

    border-radius: 5px;
  }
  .hd_right_menu_close_box_inner div button {
    width: 24px;
    height: 24px;

    border-radius: 5px;
    /* font-size: 30px; */
  }
  .hd_right_menu_close_box_inner div button svg {
    width: 24px;
    height: 24px;
  }
  .hd_right_menu_wrap .hd_right_menu_inner > div {
    padding: 20px 0;
  }
  .hd_right_menu_top {
    border-bottom: 3px solid #dcdddd;
  }
  .hd_right_menu_top .hd_right_menu_img {
    width: 90px;
    height: 90px;
  }
  .hd_right_menu_top .hd_right_menu_img img {
  }
  .hd_right_menu_top .hd_right_menu_info {
    margin-left: 20px;
  }
  .hd_right_menu_top .hd_info_one,
  .hd_right_menu_top .hd_info_two,
  .hd_right_menu_top .hd_info_three {
    font-size: 12px;
  }
  .hd_right_menu_top .hd_info_one {
  }
  .hd_right_menu_top .hd_info_one > div:first-child > span:first-child {
    font-size: 18px;
    /* background-color: red; */
  }
  .hd_right_menu_top .hd_info_one > div:last-child {
    width: 65px;
    height: 30px;

    border-radius: 16px;
  }
  .hd_right_menu_top .hd_info_two {
    margin-bottom: 8px;
  }
  .hd_right_menu_top .hd_info_two > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }
  .hd_right_menu_top .hd_info_three > div:first-child {
    width: 60px;
    height: 24px;

    border-radius: 14px;
  }

  .hd_right_menu_top .hd_info_two > div:last-child,
  .hd_right_menu_top .hd_info_three > div:last-child {
    /* font-family: "KopubDotum"; */
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
  }
  .hd_right_menu_middle {
    width: 100%;
    border-bottom: 3px solid #dcdddd;
  }
  .hd_right_menu_middle ul {
  }
  .hd_right_menu_middle .middle_menu_li {
    width: 45%;

    height: 50px;
    position: relative;
    /* margin-bottom: 16px; */
    border-radius: 8px;
    box-shadow: -5px 8px 6px -6px #777;
  }
  .hd_right_menu_middle .middle_menu_li::after {
    border-bottom: 16px solid transparent;
    border-top: 16px solid #fff;
    border-left: 16px solid transparent;
    border-right: 16px solid #fff;
  }
  .hd_right_menu_middle .middle_menu_li a {
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div {
    height: 50px;

    padding-left: 10px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div img {
    width: 22px;
    height: auto;
    margin-right: 8px;
  }
  .hd_right_menu_middle .middle_menu_li .middle_menu_div span {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    height: 20px;
    line-height: 30px;
    /* margin-right: 12%; */
  }

  .hd_right_menu_bottom ul {
    display: flex;
  }
  .hd_right_menu_bottom .bottom_menu_li {
    flex: 1;
    height: 80px;
    padding: 0 18px;
  }

  .hd_right_menu_bottom .bottom_menu_li a {
    width: 100%;
    height: 100%;
    display: block;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div {
    width: 100%;
    height: 100%;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div img {
    display: block;
    max-width: 70px;
  }
  .hd_right_menu_bottom .bottom_menu_li:first-child .bottom_menu_div img {
    max-width: 50px;
  }
  .hd_right_menu_bottom .bottom_menu_li .bottom_menu_div span {
    font-size: 14px;
    /* color: #fff; */
    font-weight: 400;
    font-family: "KopubDotum";
  }
}
