@charset "UTF-8";

.login_wrap2 {
  height: 100% !important;
}
/* 인트로 */
.login_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url(../assets/images/intro_bg.png) center center no-repeat;
  background-size: cover;
  padding: 100px 0; */
}
.login_wrap .wrap {
  height: auto;
  width: 1300px;
  /* margin: 0 auto; */
  position: relative;
}
.login_box {
  /* margin: 0 -25px; */
  /* padding-top: 216px; */
  /* font-size: 0;
  line-height: 0; */
  padding: 0 20px;
  /* background-color: red; */
  /* display: flex; */
}
.login_box_top {
  width: 100%;
  height: 120px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}
.login_box_top h2 {
  font-size: 60px;

  color: #727171;
  /* vertical-align: top; */
  font-family: "KopubDotum";
}
.login_box_top .login_top_img {
  display: flex;
  padding: 0 30px;
}
.login_box_top .login_top_img li:first-child {
  width: 167px;
  margin: 0 10px;
}
.login_box_top .login_top_img li:nth-child(2) {
  width: 173px;
  margin: 0 10px;
}
.login_box_top .login_top_img li:nth-child(3) {
  width: 127px;
  margin: 0 10px;
}
.login_box_middle {
  width: 100%;
  height: 480px;
  border-radius: 50px;
  background-color: #dff2fc;
  padding: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login_box_middle .login_middle_content {
  width: 100%;
  height: 260px;
  display: flex;
}
.login_box_middle .login_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login_box_middle .login_content input {
  display: block;
  width: 100%;
  height: 105px;
  border-radius: 20px;
  border: none;
  font-size: 50px;
  padding-left: 10px;
}
.login_box_middle .login_content input:focus {
  outline: none;
}
.login_box_middle .login_content_btn {
  width: 355px;
  height: 100%;
  background-color: #40b3e6;
  border-radius: 20px;
  margin-left: 50px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.login_box_middle .login_content_btn span {
  font-size: 60px;
  color: #fff;
  font-family: "KopubDotum";
}
.login_check_div {
  /* background-color: red; */
}
.login_check_div label {
  display: flex;
  align-items: center;
  cursor: pointer;

  /* background-color: red; */
}
.login_check {
  width: 65px;
  height: 65px;

  border-radius: 50%;
  background-color: #40b3e6;
}
.login_check svg {
  color: #fff;
  width: 100%;
  height: 100%;
}
.login_check_div label span {
  font-size: 40px;
  font-family: "KopubDotum";
  color: #727171;
  margin-left: 10px;
}
.login_box_bottom {
  margin-top: 85px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.login_box_bottom button {
  width: 300px;
  height: 100px;
  background-color: #40b3e6;
  color: #fff;
  font-size: 40px;
  font-family: "KopubDotum";
  border-radius: 20px;
}
.login_box_bottom button:last-child {
  margin-left: 10px;
}
@media all and (max-width: 1920px) {
  .login_wrap .wrap {
    width: 1084px;
    /* margin: 0 auto; */
    position: relative;
  }
  .login_box {
    /* margin: 0 -25px; */
    /* padding-top: 216px; */
    /* font-size: 0;
  line-height: 0; */
    padding: 0 20px;
    /* background-color: red; */
    /* display: flex; */
  }
  .login_box_top {
    /* width: 100%; */
    height: 100px;
    margin-bottom: 25px;
  }
  .login_box_top h2 {
    font-size: 45px;
  }
  .login_box_top .login_top_img {
    display: flex;
    padding: 0 30px;
    align-items: center;
  }
  .login_box_top .login_top_img li:first-child {
    width: 139.25230769227px;
    margin: 0 10px;
  }
  .login_box_top .login_top_img li:nth-child(2) {
    width: 144.25538461536px;
    margin: 0 10px;
  }
  .login_box_top .login_top_img li:nth-child(3) {
    width: 105.89846153845px;
    margin: 0 10px;
  }
  .login_box_middle {
    height: 400px;
    border-radius: 50px;

    padding: 37px;
  }
  .login_box_middle .login_middle_content {
    height: 216px;
  }
  .login_box_middle .login_content {
  }
  .login_box_middle .login_content input {
    height: 87px;
    border-radius: 20px;
    font-size: 46px;
  }
  .login_box_middle .login_content_btn {
    width: 296px;

    border-radius: 20px;
    margin-left: 40px;
  }
  .login_box_middle .login_content_btn span {
    font-size: 45px;
  }
  .login_check_div label {
  }
  .login_check {
    width: 55px;
    height: 55px;
  }
  .login_check svg {
    color: #fff;
    width: 100%;
    height: 100%;
  }
  .login_check_div label span {
    font-size: 34px;

    margin-left: 10px;
  }
  .login_box_bottom {
    margin-top: 65px;
  }
  .login_box_bottom button {
    width: 250px;
    height: 84px;

    font-size: 34px;

    border-radius: 20px;
  }
  .login_box_bottom button:last-child {
    margin-left: 10px;
  }
}

@media all and (max-width: 1400px) {
  .login_wrap .wrap {
    width: 947px;
    /* margin: 0 auto; */
    position: relative;
  }
  .login_box {
    padding: 0 20px;
  }
  .login_box_top {
    height: 87px;
    margin-bottom: 25px;
  }
  .login_box_top h2 {
    font-size: 40px;
  }
  .login_box_top .login_top_img {
    display: flex;
    padding: 0 30px;
  }
  .login_box_top .login_top_img li:first-child {
    width: 122px;
    margin: 0 10px;
  }
  .login_box_top .login_top_img li:nth-child(2) {
    width: 126px;
    margin: 0 10px;
  }
  .login_box_top .login_top_img li:nth-child(3) {
    width: 93px;
    margin: 0 10px;
  }
  .login_box_middle {
    height: 350px;
    border-radius: 50px;

    padding: 32px;
  }
  .login_box_middle .login_middle_content {
    height: 189px;
  }
  .login_box_middle .login_content {
  }
  .login_box_middle .login_content input {
    height: 76px;
    border-radius: 20px;
    font-size: 40px;
  }
  .login_box_middle .login_content_btn {
    width: 259px;

    border-radius: 20px;
    margin-left: 40px;
  }
  .login_box_middle .login_content_btn span {
    font-size: 40px;
  }
  .login_check_div label {
  }
  .login_check {
    width: 50px;
    height: 50px;
  }
  .login_check svg {
    color: #fff;
    width: 100%;
    height: 100%;
  }
  .login_check_div label span {
    font-size: 32px;

    margin-left: 10px;
  }
  .login_box_bottom {
    margin-top: 45px;
  }
  .login_box_bottom button {
    width: 220px;
    height: 73px;

    font-size: 34px;

    border-radius: 20px;
  }
  .login_box_bottom button:last-child {
    margin-left: 10px;
  }
}

@media all and (max-width: 1000px) {
  .login_wrap .wrap {
    width: 676px;
    /* margin: 0 auto; */
    position: relative;
  }
  .login_box {
    padding: 0 20px;
  }
  .login_box_top {
    height: 70px;
    /* background-color: red; */
    margin-bottom: 25px;
  }
  .login_box_top h2 {
    font-size: 36px;
  }
  .login_box_top .login_top_img {
    display: flex;
    padding: 0 30px;
  }
  .login_box_top .login_top_img li:first-child {
    width: 118px;
    margin: 0 10px;
  }
  .login_box_top .login_top_img li:nth-child(2) {
    width: 122px;
    margin: 0 10px;
  }
  .login_box_top .login_top_img li:nth-child(3) {
    width: 89px;
    margin: 0 10px;
  }
  .login_box_middle {
    height: 300px;
    border-radius: 45px;

    padding: 28px;
  }
  .login_box_middle .login_middle_content {
    height: 150px;
  }
  .login_box_middle .login_content {
  }
  .login_box_middle .login_content input {
    height: 68px;
    border-radius: 20px;
    font-size: 30px;
  }
  .login_box_middle .login_content_btn {
    width: 220px;

    border-radius: 20px;
    margin-left: 32px;
  }
  .login_box_middle .login_content_btn span {
    font-size: 36px;
  }
  .login_check_div label {
  }
  .login_check {
    width: 46px;
    height: 46px;
  }
  .login_check svg {
    /* color: #fff;
    width: 100%;
    height: 100%; */
  }
  .login_check_div label span {
    font-size: 30px;

    margin-left: 10px;
  }
  .login_box_bottom {
    margin-top: 42px;
  }
  .login_box_bottom button {
    width: 220px;
    height: 73px;

    font-size: 34px;

    border-radius: 20px;
  }
  .login_box_bottom button:last-child {
    margin-left: 10px;
  }
}
@media all and (max-width: 640px) {
  .login_wrap .wrap {
    width: 520px;
    /* margin: 0 auto; */
    position: relative;
  }
  .login_box {
    padding: 0 20px;
  }
  .login_box_top {
    height: 40px;
    /* background-color: red; */
    margin-bottom: 15px;
  }
  .login_box_top h2 {
    font-size: 28px;
  }

  .login_box_top .login_top_img li:first-child {
    width: 84px;
    margin: 0 5px;
  }
  .login_box_top .login_top_img li:nth-child(2) {
    width: 84px;
    margin: 0 5px;
  }
  .login_box_top .login_top_img li:nth-child(3) {
    width: 61px;
    margin: 0 5px;
  }

  .login_box_middle {
    height: 164px;
    border-radius: 11px;

    padding: 17px;
  }
  .login_box_middle .login_middle_content {
    height: 89px;
  }
  .login_box_middle .login_content {
  }
  .login_box_middle .login_content input {
    height: 36px;
    border-radius: 5px;
    font-size: 18px;
  }
  .login_box_middle .login_content_btn {
    width: 121px;

    border-radius: 20px;
    margin-left: 20px;
  }
  .login_box_middle .login_content_btn span {
    font-size: 20px;
  }
  .login_check_div label {
  }
  .login_check {
    width: 22px;
    height: 22px;
  }
  .login_check svg {
    /* color: #fff;
    width: 100%;
    height: 100%; */
  }
  .login_check_div label span {
    font-size: 18px;

    margin-left: 10px;
  }
  .login_box_bottom {
    margin-top: 34px;
  }
  .login_box_bottom button {
    width: 120px;
    height: 40px;

    font-size: 18px;

    border-radius: 5px;
  }
  .login_box_bottom button:last-child {
    margin-left: 10px;
  }
}
