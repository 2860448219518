@charset "UTF-8";

#wrapper {
  /* overflow: ; */
  /* position: relative; */
  width: 100%;
  min-width: 1500px;
  /* height: 100%; */
}
.wrap {
  position: relative;
  width: 1300px;
  margin-right: auto;
  margin-left: auto;
}
/* .common_list_swiper {
}
.common_list_swiper .swiper {
}
.common_list_swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  height: calc((100% - 30px) / 2) !important;

  display: flex;
  justify-content: center;
  align-items: center;
}
.common_list_swiper .swiper-wrapper {
  flex-direction: unset;
  flex-wrap: wrap;
} */
.common_list_swiper {
  height: 100%;
}
.common_list_swiper .slick-slider {
  height: 100%;
}
.common_list_swiper .slick-slider .slick-list {
  height: 100%;
}
.common_list_swiper .slick-slider .slick-list .slick-track {
  height: 100%;
}

.common_list_swiper .mySwiper_pc .slick-slide > div:first-child {
  margin-bottom: 80px;
}
.common_list_swiper .mySwiper_pc {
  display: block;
}
.common_list_swiper .mySwiper_pc .slick-dots {
  bottom: 0;
  /* background-color: red; */
}
.common_list_swiper .mySwiper_pc .slick-dots li {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #9fa0a0;
}
.common_list_swiper .mySwiper_pc .slick-dots li.slick-active {
  background-color: #f5a21f;
}
.common_list_swiper .mySwiper_m {
  display: none;
}

.common_list_swiper .mySwiper_m .slick-slide > div {
  margin-bottom: 70px;
}
.common_list_swiper .mySwiper_m .slick-slide > div:last-child {
  margin-bottom: 0px;
}

.common_list_swiper .mySwiper_m .slick-dots {
  bottom: -20px;
  /* background-color: red; */
}
.common_list_swiper .mySwiper_m .slick-dots li {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #9fa0a0;
}
.common_list_swiper .mySwiper_m .slick-dots li.slick-active {
  background-color: #f5a21f;
}

@media all and (max-width: 1300px) {
  .common_list_swiper .mySwiper_pc .slick-dots li {
    width: 13px;
    height: 13px;
  }
}
@media all and (max-width: 1410px) {
  .common_list_swiper .mySwiper_pc .slick-slide > div:first-child {
    margin-bottom: 60px;
  }
}
@media all and (max-width: 1100px) {
  .common_list_swiper .mySwiper_pc .slick-slide > div:first-child {
    margin-bottom: 38px;
  }
}
@media all and (max-width: 780px) {
  .common_list_swiper .mySwiper_pc .slick-slide > div:first-child {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 1700px) {
  #wrapper {
    min-width: 1300px;
  }
  .wrap {
    width: 1300px;
  }
}

@media all and (max-width: 640px) {
  .common_list_swiper .mySwiper_pc {
    display: none;
  }
  .common_list_swiper .mySwiper_m {
    display: block;
  }
}
@media all and (max-width: 1300px) {
  #wrapper {
    min-width: 1000px;
  }
  .wrap {
    width: 1000px;
  }
}

@media all and (max-width: 1000px) {
  #wrapper {
    min-width: 0;
  }
  .wrap {
    width: auto;
    margin-right: 0;
    margin-left: 0;
    padding-right: 3%;
    padding-left: 3%;
  }
}

/* 사이트맵 */
.sitemap {
  float: right;
  margin: 23px 0 0 0;
  width: 63px;
  height: 54px;
  vertical-align: middle;
}
.sitemap a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
  border-radius: 19px;
  z-index: 50;
}
.sitemap a .line {
  width: 30px;
  height: 6px;
  margin: 3px auto 0;
  background-color: #fff;
  text-align: center;
}
.sitemap a .line:first-child {
  margin-top: 13px;
}
/* 
@media all and (max-width: 1700px) {
  .coin_box {
    margin-left: 5px;
  }
  .coin_box .coin_item {
    margin: 0 5px;
  }
}
@media all and (max-width: 1300px) {
  .coin_box {
    margin-top: 29px;
    margin-left: 5px;
  }
  .coin_box .coin_item {
    margin: 0;
  }
  .coin_box .coin_btn {
    padding: 13px 10px 13px 27px;
    border: 0;
    font-size: 16px;
  }
  .coin_box .coin_btn:before {
    top: 8px;
    left: 0;
    transform: scale(0.6);
  }
  .coin_box .all_btn {
    width: 42px;
    height: 42px;
    margin-top: 0;
    padding-top: 15px;
    font-size: 16px;
    line-height: 18px;
  }
  .sitemap {
    width: 54px;
  }
}
@media all and (max-width: 1000px) {
  .sitemap {
    display: none;
  }
  .coin_box {
    margin-left: 20px;
    margin-top: 8px;
  }
  .group .coin_box {
    display: none;
  }
} */

/* 링크 */
.lnb .link {
  display: block;
  padding: 23px 0;
}
.lnb .link_list {
  margin-right: 35px;
}
.lnb .link_item {
  position: relative;
  float: left;
  margin-right: 10px;
  border-radius: 30px;
  z-index: 50;
}
.lnb .link_item.write {
}
.lnb .link_item.setting {
}
.lnb .link_anchor {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 0;
  line-height: 0;
}
.lnb .link_anchor:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 25px;
  height: 24px;
  margin: auto;
  background-image: url(../assets/images/main_sprite.png);
  background-repeat: no-repeat;
  background-position: left 73.68%;
  background-size: 140% 1366.66%;
}
.lnb .link_item.write .link_anchor:before {
  width: 27px;
  height: 30px;
  background-position: left 86.57%;
  background-size: 129.62% 1093.33%;
}
.lnb .link_item.setting .link_anchor:before {
  width: 30px;
  height: 30px;
  background-position: left 100%;
  background-size: 116.66% 1093.33%;
}

/* 주메뉴 */
@media all and (min-width: 1001px) {
  .lnb {
    position: relative;
    width: 100%;
    height: 100px;
  }
  .lnb .wrap {
    height: 100%;
  }
  .lnb .menu {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: #3fb3e6;
    transition-property: height;
  }

  /* 메뉴 높이 */
  .lnb .menu .depth2,
  .lnb .menu.after:after {
    height: calc(100% - 60px);
  }

  /* 하단 배경 활성화 */
  .lnb_open .lnb .menu.after:after {
    visibility: visible;
    opacity: 1;
  }

  /* 메뉴 상단 정보 */
  .lnb .menu_header {
    position: absolute;
    top: 0;
    right: 50px;
    font-size: 14px;
    line-height: 35px;
    vertical-align: top;
  }
  .lnb .menu_header .coin_box {
    display: none;
  }

  /* 메뉴 제목 */
  .lnb .menu_title {
    display: none;
  }

  /* 1차 메뉴 */
  .lnb .menu .depth1_list {
    display: inline-block;
    width: 100%;
    padding-left: 590px;
    padding-right: 275px;
  }
  .lnb .menu .depth1_item {
    width: auto;
    margin-right: 40px;
  }
  .lnb .menu .depth1_item:first-child {
    margin-left: 0;
  }
  .lnb .menu .depth1_text {
    display: inline-block;
    position: relative;
    padding: 42px 0 34px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.03em;
    font-weight: 300;
    text-decoration: none;
    transition-property: color;
    font-family: "recoFont";
  }
  .lnb .menu .depth1_text[target="_blank"]:after {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: 2.5px;
    margin-left: 5px;
    vertical-align: top;
    background-color: #000;
  }
  .lnb .menu .depth1_text:hover {
    text-decoration: underline;
  }

  /* 1차 메뉴 활성화 */
  .lnb .menu .depth1_item.active .depth1_text {
    text-decoration: none;
  }
  .lnb .menu .depth1_item.active .depth1_text:before {
    visibility: visible;
    opacity: 1;
  }
}

@media all and (max-width: 1700px) {
  .lnb .link_item {
    margin-right: 0;
  }
  .lnb .link_list {
    margin-right: 20px;
  }
  .lnb .menu .depth1_list {
    padding-left: 450px;
    padding-right: 225px;
  }
}

@media all and (max-width: 1300px) {
  .lnb .link {
    padding: 28px 0 27px;
  }
  .lnb .link_item {
    margin-right: 0;
  }
  .lnb .link_list {
    margin-right: 20px;
  }
  .lnb .link_anchor {
    width: 40px;
    height: 40px;
  }
  .lnb .menu .depth1_list {
    padding-left: 310px;
    padding-right: 188px;
    text-align: center;
  }
  .lnb .menu .depth1_item {
    margin-right: 20px;
  }
  .lnb .menu .depth1_text {
    font-size: 18px;
  }
}

@media all and (max-width: 1000px) {
  #header {
    height: 60px;
    background-color: #3fb3e6;
  }
  /* 로고 */
  .logo {
    position: relative;
  }

  /* 메뉴 영역 */
  .lnb .menu {
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: -100%;
    z-index: 40;
    width: 360px;
    height: 100%;
    padding-top: 64px;
    background-color: #3fb3e6;
    transition-property: visibility, right;
    transition-duration: 0.25s;
    transition-timing-function: linear;
  }
  .lnb .wrap {
    height: inherit;
    padding-left: 10px;
    padding-right: 10px;
  }

  /* 열기 버튼 */
  .lnb .menu_show {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    right: 3%;
    z-index: 30;
    margin-top: auto;
    margin-bottom: auto;
  }
  .lnb .menu_show .menu_btn {
    position: relative;
    width: 40px;
    height: 100%;
    border: 3px solid #fff;
    border-radius: 15px;
  }
  .lnb .menu_show .menu_btn .line {
    width: 24px;
    height: 4px;
    margin: 3px auto 0;
    background-color: #fff;
    text-align: center;
  }
  .lnb .menu_show .menu_btn .line:first-child {
    margin-top: 0;
  }

  /* 닫기 버튼 */
  .lnb .menu_hide {
    position: absolute;
    top: 7px;
    right: 100%;
    width: 30px;
    height: 30px;
  }
  .lnb .menu_hide .menu_btn {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .lnb .menu_hide .menu_btn:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 18px;
    height: 18px;
    width: 100%;
    height: 100%;
    background: url(../assets/images/m_close.png) center no-repeat;
  }
  .lnb_show .menu_hide {
    top: 15px;
    right: 15px;
  }

  /* 메뉴 상단 정보 */
  .lnb .big_wrap {
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #fff;
  }
  .lnb .menu_header {
    padding-right: 50px;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
  }
  .lnb .menu_header .coin_box {
    display: inline-block;
    float: right;
    margin-left: 10px;
    vertical-align: top;
  }
  .lnb .link {
    display: inline-block;
    padding: 10px 0;
    vertical-align: top;
  }
  .lnb .link_list {
    margin-right: 0;
  }
  .lnb .link_item {
    margin-right: 5px;
  }

  /* 1차 메뉴 */
  .lnb .menu .depth1 {
    height: inherit;
    z-index: 10;
  }
  .lnb .menu .depth1_list {
    position: relative;
    min-height: 100%;
    margin-top: 60px;
    padding-left: 0;
    padding-right: 0;
  }
  .lnb .menu .depth1_item {
    margin-right: 0;
    border-bottom: 1px solid #fff;
  }
  .lnb .menu .depth1_text {
    display: block;
    position: relative;
    padding-top: 18px;
    padding-bottom: 12px;
    color: #fff;
    font-size: 18px;
    line-height: 23px;
    font-family: "recoFont";
    letter-spacing: -0.03em;
    text-decoration: none;
    transition-property: font-weight;
  }
  .lnb .menu .depth1_text:after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .lnb .menu .depth1_text[target="_blank"]:after {
    width: 15px;
    height: 15px;
    background-color: #000;
    transition: none;
  }
  .lnb .menu .depth1_item.has .depth1_text:after {
    width: 15px;
    height: 2px;
    background-color: #999;
    transition: none;
  }

  /* 1차 메뉴 활성화 */
  .lnb .menu .depth1_item.active .depth1_text {
    font-weight: 500;
  }
  .lnb .menu .depth1_item.has.active .depth1_text:after {
    background-color: #000;
  }
}

@media all and (max-width: 360px) {
  .lnb .menu {
    width: 100%;
  }
}

/* 하단 정보 */
#footer {
  height: 140px;
  padding: 20px 0;
  background-color: #fff33f;
  text-align: center;
}
#footer .footer_list {
  display: inline-block;
}
#footer .footer_item {
  display: inline-block;
  margin: 0 15px;
  vertical-align: top;
}
#footer .footer_item:last-child {
  margin-right: 30px;
}
#footer .footer_anchor {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
}
#footer .footer_anchor:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 0;
  transition: all 0.3s linear;
}
#footer .footer_anchor.n1:before {
  background-image: url(../assets/images/footer_icon01.png);
}
#footer .footer_anchor.n2:before {
  background-image: url(../assets/images/footer_icon02.png);
}
#footer .footer_anchor.n3:before {
  background-image: url(../assets/images/footer_icon03.png);
}
#footer .footer_anchor.n4:before {
  background-image: url(../assets/images/footer_icon04.png);
}
#footer .footer_anchor.n5:before {
  background-image: url(../assets/images/footer_icon05.png);
}
#footer .footer_anchor:hover:before {
  transform: scale(1.2);
} /* 마우스오버 */
#footer .footer_more {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}
#footer .footer_more_btn {
  position: relative;
  padding-bottom: 35px;
  font-size: 20px;
  line-height: 22px;
  color: #231812;
  font-family: "recoFont";
}
#footer .footer_more_btn:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 32px;
  height: 32px;
  background-image: url(../assets/images/footer_more.png);
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s linear;
}
#footer .footer_more_btn:hover:before {
  transform: rotate(360deg);
}

@media all and (max-width: 640px) {
  #footer {
    height: auto;
  }
  #footer .footer_anchor {
    width: 85px;
    height: 85px;
  }
  #footer .footer_more {
    display: block;
  }
  #footer .footer_more_btn {
    padding-top: 15px;
    padding-right: 35px;
    padding-bottom: 0;
  }
  #footer .footer_more_btn br {
    display: none;
  }
  #footer .footer_more_btn:before {
    left: auto;
    bottom: auto;
    top: 7px;
    transform: scale(0.8);
  }
  #footer .footer_item {
    margin: 0 6px 10px;
  }
}
.intro_swiper {
  width: 100%;
  height: auto;
}
.intro_swiper > div {
  /* display: inline-block;
  position: relative; */
  /* width: calc(33.3% - 28px); */
  height: 640px;
  /* margin: 0 14px; */
}

.intro_swiper > div > .swiper-slide {
  width: 100%;
  height: 100%;
}
.intro_swiper > div > .swiper-slide:nth-child(2n-1) > div {
  display: flex;
  align-items: flex-end;
  /* border-left: 1px solid black; */
}
.intro_swiper > div > .swiper-slide:nth-child(2n) > div {
  display: flex;
  align-items: flex-start;
  /* border-left: 1px solid black; */
}
@media all and (max-width: 1870px) {
  .intro_swiper > div {
    height: 568px;
  }
}
@media all and (max-width: 1710px) {
  .intro_swiper > div {
    height: 520px;
  }
}
@media all and (max-width: 1600px) {
  .intro_swiper > div {
    height: 440px;
    margin: 0;
  }
}
@media all and (max-width: 1500px) {
  .intro_swiper > div {
    height: 420px;
    margin: 0;
  }
}
@media all and (max-width: 1400px) {
  .intro_swiper > div {
    height: 410px;
    margin: 0;
  }
}
@media all and (max-width: 1300px) {
  .intro_swiper > div {
    height: 360px;
    margin: 0;
  }
}
@media all and (max-width: 1200px) {
  .intro_swiper > div {
    height: 312px;
    margin: 0;
  }
}
@media all and (max-width: 1200px) {
  .intro_swiper > div {
    height: 312px;
    margin: 0;
  }
}
@media all and (max-width: 1000px) {
  .intro_swiper > div {
    height: 256px;
    margin: 0;
  }
}
@media all and (max-width: 800px) {
  .intro_swiper > div {
    height: 216px;
    margin: 0;
  }
}
@media all and (max-width: 640px) {
  .intro_swiper {
    width: 60%;
  }
  .intro_swiper > div {
    height: auto;
    /* width: 53.125%; */
  }
  .intro_swiper > div .swiper-slide:nth-child(2n-1) > div {
    display: block;
    /* border-left: 1px solid black; */
  }
  .intro_swiper > div .swiper-slide:nth-child(2n) > div {
    display: block;
    /* border-left: 1px solid black; */
  }
}
.list_swiper > div {
  height: auto !important;
}
/* .list_swiper > div .swiper-slide {
  height: auto !important;
}
@media all and (max-width: 1410px) {
  .list_swiper > div .swiper-slide {
    height: auto !important;
  }
}
@media all and (max-width: 1300px) {
  .list_swiper > div .swiper-slide {
    height: auto !important;
  }
}
@media all and (max-width: 1040px) {
  .list_swiper > div .swiper-slide {
    height: auto !important;
  }
}
@media all and (max-width: 900px) {
  .list_swiper > div .swiper-slide {
    height: auto !important;
  }
} */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.alert_div_back_back {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(177, 177, 177, 0.244);
  z-index: 998;
}

.alert_div_back {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(177, 177, 177, 0.244); */
  /* background-color: fff; */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInDown 0.4s;
}
.alert_div_back.admin_back {
  position: static;
  animation: none;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.alert_div_back .alert_div_wrap {
  width: 320px;
  min-height: 120px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.alert_div_back .alert_div_wrap .alert_div_inner {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  min-height: 120px;
}
.alert_div_back .alert_div_wrap .alert_div_title {
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
.alert_div_back .alert_div_wrap .alert_div_content {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 30px;
}
.alert_div_back .alert_div_wrap .btn_alert {
  width: 80%;
  height: 40px;
  background-color: #40b3e6;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  font-size: 18px;

  border-radius: 8px;
}

.alert_div_back .alert_div_wrap2 {
  width: 380px;
  min-height: 120px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.alert_div_back .alert_div_wrap2 .alert_div_inner {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  min-height: 120px;
}

.alert_div_back .alert_div_wrap2 .alert_div_title {
  /* font-size: 16px; */
  font-weight: 600;
  margin-bottom: 5px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content {
  width: 100%;
}
.alert_div_back .alert_div_wrap2 .alert_div_content > div {
  margin-bottom: 10px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content > div:first-child {
  font-weight: 500;
}
.alert_div_back .alert_div_wrap2 .alert_div_content > div:nth-child(2) {
  /* border: 1px solid #c8c9ca;
  border-radius: 5px; */
  /* margin-bottom: 20px; */
  width: 100%;
  font-size: 14px;
}
.alert_div_back .alert_div_wrap2 .alert_div_content > div:nth-child(2) ol {
  width: 100%;
  list-style-type: decimal;
  padding-inline-start: 14px;
}
.alert_div_back .alert_div_wrap2 .alert_div_content > div:nth-child(3) {
  border: 1px solid #c8c9ca;
  border-radius: 5px;

  padding: 5px;
  /* margin-bottom: 20px; */
}
.alert_div_back .alert_div_wrap2 .alert_div_content > div:nth-child(3) ol {
  width: 100%;
  list-style-type: decimal;
  padding-inline-start: 14px;
  font-size: 14px;
}
.alert_div_back .alert_div_wrap2 .alert_div_content > div:nth-child(3) span {
  font-size: 14px;
  text-indent: 14px;
}
.alert_div_back .alert_div_wrap2 .alert_div_content p {
  margin-bottom: 20px;
  /* font-size: 14px; */
  color: #00c73c;
  font-weight: 600;
  text-align: center;
}
.alert_div_back .alert_div_wrap2 .alert_div_content ul {
  width: 100%;

  /* text-align: left; */
}
.alert_div_back .alert_div_wrap2 .btn_box {
  display: flex;
}
.alert_div_back .alert_div_wrap2 .btn_alert {
  flex: 1;
  height: 30px;
  background-color: #00c73c;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-right: 3px;
}
.alert_div_back .alert_div_wrap2 .btn_alert_cancel {
  flex: 1;
  height: 30px;
  background-color: #6e7881;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}

.alert_div_back .alert_div_wrap3 {
  width: 420px;
  min-height: 120px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.alert_div_back .alert_div_wrap3 .alert_div_inner {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  min-height: 120px;
}

.alert_div_back .alert_div_wrap3 .alert_div_title {
  /* font-size: 16px; */
  font-weight: 600;
  margin-bottom: 20px;
}

.alert_div_back .alert_div_wrap3 .alert_div_content {
  width: 100%;
}
.alert_div_back .alert_div_wrap3 .alert_div_content > div {
  margin-bottom: 10px;
}

.alert_div_back .alert_div_wrap3 .alert_div_content > div:first-child {
  font-weight: 500;
}
.alert_div_back .alert_div_wrap3 .alert_div_content > div:nth-child(2) {
  border: 1px solid #c8c9ca;
  border-radius: 5px;
  /* margin-bottom: 20px; */
}
.alert_div_back .alert_div_wrap3 .alert_div_content p {
  margin-bottom: 10px;
  /* font-size: 14px; */
  color: #00c73c;
  font-weight: 600;
}
.alert_div_back .alert_div_wrap3 .alert_div_content ul {
  width: 100%;

  /* text-align: left; */
}

.alert_div_back .alert_div_wrap3 .btn_alert {
  height: 30px;
  width: 80%;
  background-color: #00c73c;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-top: 5px;
  /* margin-right: 3px; */
}
.myClass_Swiper {
  height: 500px;
}
.myClass_Swiper .swiper-pagination {
  position: absolute;
  bottom: -5px;
  /* background-color: red; */
}
.myClass_Swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #f4a023;
}
.myClass_Swiper .swiper-pagination .swiper-pagination-bullet {
  background: #9fa0a0;
  opacity: 1;
  width: 17px;
  height: 17px;
}
@media all and (max-width: 1300px) {
  .myClass_Swiper {
    height: 420px;
    /* width: 260px; */
  }
}
@media all and (max-width: 1150px) {
  .myClass_Swiper {
    height: 460px;
    /* width: 260px; */
  }
}
@media all and (max-width: 900px) {
  .myClass_Swiper {
    height: 420px;
    /* width: 260px; */
  }
}
@media all and (max-width: 800px) {
  .myClass_Swiper {
    height: 490px;
    /* width: 260px; */
  }
}
@media all and (max-width: 640px) {
  .myClass_Swiper {
    width: 260px;
    height: 340px;
  }
  #qna_table .pagination li {
    margin: 0 5px !important;
  }
  #qna_table .pagination li a {
    color: #2d2288;
    font-size: 14px;
    /* margin: "0 10px"; */
  }
}
@media all and (max-width: 540px) {
  .myClass_Swiper {
    height: 330px;
    /* padding-bottom: 10px; */
  }
  .myClass_Swiper .swiper-pagination {
    position: absolute;
    bottom: -2px;
    /* background-color: red; */
  }
  .myClass_Swiper
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #f4a023;
  }
  .myClass_Swiper .swiper-pagination .swiper-pagination-bullet {
    background: #9fa0a0;
    opacity: 1;
    width: 12px;
    height: 12px;
  }
}
/* qna paging */
#qna_table .pagination {
  font-size: 28px;
  /* margin-top: 20px; */
}

#qna_table .pagination li:first-child,
#qna_table .pagination li:last-child {
  display: none;
}
#qna_table .pagination li {
  margin: 0 10px;
}
#qna_table .pagination li a {
  color: #2d2288;
  /* margin: "0 10px"; */
}
#qna_table .pagination li.active a {
  color: #f5a21f;
}
