@charset "UTF-8";

/* rowgroup */
.rowgroup {
}
.my {
  width: 100%;
  height: 100%;

  position: relative;
  /* overflow: auto; */
}
.my .wrap {
  width: 1500px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0;
}

.my_class_flex {
  display: flex;
  position: relative;
}
.my_class_flex .temp_calendar {
  width: 105px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my_class_flex .class_group_wrap {
  flex: 1;
  padding-right: 100px;
}
.my_class_flex .class_group_wrap .class_group {
  width: 1200px;
  margin: 0 auto;
}
.my_class_flex .class_group_wrap .class_group_bottom {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my_class_flex .class_group_wrap .class_group_bottom ul {
  display: flex;
  /* width: 660px; */
  justify-content: space-around;
}
.my_class_flex .class_group_wrap .class_group_bottom ul li {
  position: relative;
  width: 109px;
  height: 100px;
}
.my_class_flex .class_group_wrap .class_group_bottom ul li a {
  width: 100%;
  height: 100%;
  display: block;
}
.my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  width: 100%;
  height: 100%;
}
.my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my_class_flex .class_group_wrap .class_group_bottom ul li a img {
  width: 80px;
}
.my_class_flex .class_group_wrap .class_group_bottom div {
  font-family: recoFont;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.my_class_flex .class_group_wrap .class_group_bottom div > span {
}
.my_class_flex .class_group_wrap .class_group_bottom div svg {
  font-size: 30px;
  margin-left: 5px;
  color: #f5a21f;
}
.my_class_flex .shortcut {
  width: 85px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

.shortcut_item {
  width: 100%;
  min-height: 85px;
}
.shortcut_item a {
  width: 100%;
  height: 100%;
}
.shortcut_list .shortcut_item:first-child {
  background: url("../assets/images/event.png") no-repeat;
  background-size: 100%;
}
.shortcut_list .shortcut_item:nth-child(2) {
  background: url("../assets/images/pay.png") no-repeat;
  background-size: 100%;
}
.shortcut_list .shortcut_item:last-child {
  background: url("../assets/images/now.png") no-repeat;
  background-size: 100%;
}
.shortcut_item .shortcut_anchor:after {
  display: block;
  position: absolute;
  top: -5px;
  right: 0;
  /* background-image: url(../assets/images/main_sprite.png); */
  background-repeat: no-repeat;
  background-position: left -101px;
}
.shortcut_item.n1 .shortcut_anchor:after {
  width: 30px;
  height: 30px;
  background-position: left -144px;
}
.shortcut_item.n2 .shortcut_anchor:after {
  width: 28px;
  height: 30px;
  background-position: left -144px;
}
.shortcut_item.n3 .shortcut_anchor:after {
  width: 34px;
  height: 33px;
  background-position: left -101px;
}
.temp_calendar .calendar_top {
  width: 78px;
  height: 78px;
}
.temp_calendar .calendar_top .calendar_top_inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #40b3e6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.temp_calendar .calendar_top .calendar_top_inner svg {
  width: 70%;
}

.temp_calendar .calendar_subtitle {
  margin-left: 25px;
  margin-top: 3px;
  font-size: 28px;
  line-height: 32px;
  color: #241917;
  vertical-align: top;
  font-family: "KopubDotum";
}
.temp_calendar .em_sky {
  color: #40b2e8;
}
.temp_calendar .calendar_btn_box {
  display: inline-block;
  margin-top: -5px;
  margin-left: 60px;
  vertical-align: top;
}
.temp_calendar .calendar_btn {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  padding: 8px 15px 8px 50px;
  border: 3px solid #3fb3e6;
  border-radius: 25px;
  font-size: 23px;
  line-height: 25px;
  color: #241917;
  vertical-align: top;
  font-family: "KopubDotum";
  transition: box-shadow 0.3s linear;
}
.temp_calendar .calendar_btn:before {
  display: block;
  position: absolute;
  top: 8px;
  left: 15px;
  background-repeat: no-repeat;
}
.temp_calendar .calendar_btn.timetable:before {
  width: 29px;
  height: 26px;
  background-image: url(../assets/images/main_sprite.png);
  background-position: left top;
}
.temp_calendar .calendar_btn.plan:before {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-image: url(../assets/images/main_sprite.png);
  background-position: left -36px;
}
.temp_calendar .calendar_btn:hover {
  box-shadow: 0px 5px 4.8px 0.2px rgba(213, 209, 208, 1);
}
.temp_calendar .top_box {
  width: 105px;
  height: auto;
  /* height: 560px; */
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.temp_calendar .month_box {
  width: 80px;
  border-radius: 55px;
  background-color: #f5a220;
}
.temp_calendar .month_box.scroll_active {
  transform: translateY(0px);
  opacity: 1;
}

.temp_calendar .month_box:after {
}
.temp_calendar .cont_btn {
  display: inline-block;
  width: 100%;
}
.temp_calendar .cont_btn.prev {
  padding: 30px 5px 10px;
}
.temp_calendar .cont_btn.next {
  padding: 10px 5px 30px;
}
.temp_calendar .cont_btn button {
  display: block;
  position: relative;
  height: 30px;

  font-size: 40px;
  color: #fff;
  margin: 0 auto;
}

.temp_calendar .cont_btn button.next:before {
  transform: rotate(90deg);
}
.temp_calendar .month_list {
  width: 100%;
  text-align: center;
}
.temp_calendar .month_item {
  padding: 8px 0;
}
.temp_calendar .month_anchor {
  padding: 0 15px;
  font-size: 24px;
  line-height: 35px;
  color: #fff;
  font-family: "recoFont";
}
.temp_calendar .month_anchor span {
  display: none;
}
.temp_calendar .month_item.active .month_anchor {
  position: relative;
  padding: 0 5px;
  text-align: center;
  display: none;
}
.temp_calendar .month_item.active .month_anchor span {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  color: #f5a220;
  font-size: 27px;
  line-height: 30px;
  vertical-align: top;
  font-family: "KopubDotum";
}
.temp_calendar .month_item.active .month_anchor em {
  position: relative;
  top: -11px;
  width: 45px;
  height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #f5a220;
  text-align: center;
  vertical-align: top;
  line-height: 55px;
}
.temp_calendar .month_item.active .month_anchor:before {
  display: block;
  position: absolute;
  top: -17px;
  left: -10px;
  width: calc(100% + 13px);
  height: calc(100% + 10px);
  border-radius: 45px;
  background-color: #fff;
}
.temp_calendar .month_item .month_anchor_active {
  display: none;
}
.temp_calendar .month_item.active {
  position: relative;
  height: 96px;
  margin: 8px 0;
}
.temp_calendar .month_item.active .month_anchor_active {
  width: 105px;
  height: 96px;
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 50%;
  margin-left: -52.5px;
}
.temp_calendar .month_item.active .month_anchor_active svg {
  width: 100%;
  height: 100%;
}
.temp_calendar .month_item.active .month_anchor_active div {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.temp_calendar .month_item.active .month_anchor_active span {
  font-size: 22px;
  display: block;
  font-family: "KopubDotum";
  color: #f5a21f;
}
.temp_calendar .month_item.active .month_anchor_active em {
  font-size: 24px;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 40px;
  color: #fff;
  background-color: #f5a21f;
  font-family: "recoFont";
}
.temp_calendar .month_item.active .month_anchor_active .clearfix {
  display: block;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.class_group .class_top {
  width: 612px;
  height: 62px;

  margin: 0 auto;
  margin-bottom: 30px;
}
.class_group .class_top .class_top_box {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  border: 3px solid #2d2288;
  display: flex;
  overflow: hidden;
}
.class_group .class_top .class_top_box .class_top_left {
  width: 250px;
  height: 100%;
  flex: none;
  background-color: #2d2288;
  display: flex;
  align-items: center;
  justify-content: center;
}
.class_group .class_top .class_top_box .class_top_right {
  font-size: 24px;
  height: 100%;
  font-weight: 600;
  display: flex;
  padding-left: 10px;
  /* justify-content: center; */
  font-family: "KopubDotum";
  align-items: center;
  color: #40b3e6;
}
.class_group .class_top .class_top_box .class_top_left span {
  color: #fff;
  font-size: 32px;
  height: 62px;
  line-height: 70px;
  font-family: "recoFont";
}

.calendar_item {
  display: inline-block;
  position: relative;
  height: 480px;

  width: 100%;
  padding: 0 20px;
}

.calendar_anchor {
  display: block;
  position: relative;
  width: 100%;
  height: 95.3%;
  padding-top: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 0px #d3d0d0;
  border-radius: 30px;
}
.calendar_anchor .calendar_inner_back_one {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.calendar_anchor .calendar_inner_back_two {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.calendar_anchor .calendar_inner_back_two svg {
  width: 100%;
  height: 100%;
}

.calendar_inner {
  display: block;
  position: relative;
  /* background-color: red; */
  /* box-shadow: 3px 3px 3px rgba(213, 209, 208, 1); */
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 0;
}

.calendar_inner:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  animation-name: left-deco-ani2;
  animation-duration: 2000ms;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 1;
  transform: scale(1);
}
.calendar_anchor.n1 .calendar_inner:after {
  bottom: -210px;
  right: 0px;
  width: 250px;
  height: 100%;
  background: url(../assets/images/edu_icon01.png) no-repeat;
  background-size: 100%;
}
.calendar_anchor.n2 .calendar_inner:after {
  bottom: -245px;
  right: 0px;
  width: 290px;
  height: 100%;
  background: url(../assets/images/edu_icon02.png) no-repeat;
  animation-delay: 200ms;
  animation-name: left-deco-ani1;
  background-size: 100%;
}
.calendar_anchor.n3 .calendar_inner:after {
  bottom: -210px;
  right: 0px;
  width: 280px;
  height: 100%;
  background: url(../assets/images/edu_icon03.png) no-repeat;
  animation-delay: 500ms;
  background-size: 100%;
}

.class_group .month_item {
  display: inline-block;
  vertical-align: top;
}
.class_group .c_num {
  display: block;
  position: absolute;
  top: -8px;
  left: 30px;
  width: 54px;
  height: 64px;
  padding-top: 17px;
  background: url(../assets/images/edu_num.png) center no-repeat;
  background-size: cover;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  font-family: "KopubDotum";
  font-weight: 600;
}
.class_group .c_title {
  padding-top: 58px;
  font-size: 34px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  font-family: "recoFont";
  word-break: keep-all;
}
.class_group .c_text {
  padding: 35px 40px;
  font-size: 25px;
  line-height: 28px;
  font-family: "KopubDotum";
  font-weight: 600;
  color: #fff;
}
.class_group .c_text em {
  display: block;
}
.class_group .c_text em.em_yellow {
  color: #fff241;
}
.class_group .edu_status {
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: all 0.3s linear;
}
.class_group .edu_status.n1 {
  background-color: #2d228a;
}
.class_group .edu_status.n2 {
  background-color: #fff;
}
.class_group .edu_status span {
  position: relative;
  padding: 0 10px;
  font-size: 20px;
  line-height: 25px;
  font-family: "recoFont";
  color: #fff;
}
.class_group .edu_status.n2 span {
  color: #2d228a;
}
.class_group .page {
  display: block;
  margin-top: 10px;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  font-family: "KopubDotum";
  font-weight: 600;
}
.class_group .slick-dots {
  margin: 20px 0;
  text-align: center;
}
.class_group .slick-dots li {
  display: inline-block;
  vertical-align: top;
}
.class_group .slick-dots li button {
  margin: 0 7px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #a0a0a0;
  font-size: 0;
}
.class_group .slick-dots li.slick-active button {
  background-color: #f4a023;
}

/* 마우스오버 */
.calendar_anchor:hover {
}
.class_group .calendar_anchor:hover .edu_status {
  transform: scale(1.1);
}

@keyframes left-deco-ani1 {
  0% {
    transform: rotate(1deg) translateY(0px);
  }
  50% {
    transform: rotate(3deg) translateX(5%);
  }
  100% {
    transform: rotate(0deg) translateY(0px);
  }
}

@keyframes left-deco-ani2 {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  50% {
    transform: rotate(3deg) translateY(5%);
  }
  100% {
    transform: rotate(-1deg) translateY(0px);
  }
}

@media all and (max-width: 1600px) {
  /* .my .wrap {
    width: 1400px;
  }
  .my_class_flex .class_group_wrap {
    flex: 1;
    padding-right: 50px;
  }
  .my_class_flex .class_group_wrap .class_group {
    width: 1200px;
    margin: 0 auto;
  } */

  .my {
  }
  .my .wrap {
    width: 100%;

    padding: 20px 4.20833333333%;
  }

  .my_class_flex {
  }
  .my_class_flex .temp_calendar {
    width: 100px;
  }

  .my_class_flex .class_group_wrap {
    flex: 1;
    padding-right: 80px;
  }
  .my_class_flex .class_group_wrap .class_group {
    width: 1100px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom {
    width: 100%;

    margin-top: 40px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul {
    width: 650px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    width: 91px;
    height: 83px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    display: block;
    width: 60px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 18px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div > span {
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 28px;
  }
  .my_class_flex .shortcut {
    width: 78px;

    right: 4.20833333333%;
  }

  .shortcut_item {
    width: 100%;
    min-height: 80px;
  }
  .shortcut_list .shortcut_item:first-child {
  }
  .shortcut_list .shortcut_item:nth-child(2) {
  }
  .shortcut_list .shortcut_item:last-child {
  }
  .shortcut_item .shortcut_anchor:after {
    display: block;
    position: absolute;
    top: -5px;
    right: 0;
    /* background-image: url(../assets/images/main_sprite.png); */
    background-repeat: no-repeat;
    background-position: left -101px;
  }
  .shortcut_item.n1 .shortcut_anchor:after {
    width: 30px;
    height: 30px;
    background-position: left -184px;
  }
  .shortcut_item.n2 .shortcut_anchor:after {
    width: 28px;
    height: 30px;
    background-position: left -144px;
  }
  .shortcut_item.n3 .shortcut_anchor:after {
    width: 34px;
    height: 33px;
    background-position: left -101px;
  }
  .temp_calendar .calendar_top {
    width: 75px;
    height: 75px;
  }
  .temp_calendar .calendar_top .calendar_top_inner {
    border-radius: 50%;
    border: 5px solid #40b3e6;
  }
  .temp_calendar .calendar_top .calendar_top_inner svg {
    width: 70%;
  }
  .temp_calendar .em_sky {
    color: #40b2e8;
  }
  .temp_calendar .top_box {
    width: 100px;

    margin-top: 20px;
  }
  .temp_calendar .month_box {
    width: 70px;
    border-radius: 55px;
  }
  .temp_calendar .month_box.scroll_active {
    transform: translateY(0px);
    opacity: 1;
  }

  .temp_calendar .month_box:after {
  }
  .temp_calendar .cont_btn {
    display: inline-block;
    width: 100%;
  }
  .temp_calendar .cont_btn.prev {
    padding: 30px 5px 10px;
  }
  .temp_calendar .cont_btn.next {
    padding: 10px 5px 30px;
  }
  .temp_calendar .cont_btn button {
    display: block;
    position: relative;
    height: 30px;

    font-size: 40px;
    color: #fff;
    margin: 0 auto;
  }

  .temp_calendar .cont_btn button.next:before {
    transform: rotate(90deg);
  }
  .temp_calendar .month_list {
    width: 100%;
    text-align: center;
  }
  .temp_calendar .month_item {
    padding: 6px 0;
  }
  .temp_calendar .month_anchor {
    padding: 0 15px;
    font-size: 24px;
    line-height: 35px;
    color: #fff;
    font-family: "recoFont";
  }
  .temp_calendar .month_anchor span {
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor {
    position: relative;
    padding: 0 5px;
    text-align: center;
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor span {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    color: #f5a220;
    font-size: 27px;
    line-height: 30px;
    vertical-align: top;
    font-family: "KopubDotum";
  }
  .temp_calendar .month_item.active .month_anchor em {
    position: relative;
    top: -11px;
    width: 45px;
    height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #f5a220;
    text-align: center;
    vertical-align: top;
    line-height: 55px;
  }
  .temp_calendar .month_item.active .month_anchor:before {
    display: block;
    position: absolute;
    top: -17px;
    left: -10px;
    width: calc(100% + 13px);
    height: calc(100% + 10px);
    border-radius: 45px;
    background-color: #fff;
  }
  .temp_calendar .month_item .month_anchor_active {
    display: none;
  }
  .temp_calendar .month_item.active {
    position: relative;
    height: 96px;
    margin: 8px 0;
  }
  .temp_calendar .month_item.active .month_anchor_active {
    width: 105px;
    height: 96px;

    left: 50%;
    margin-left: -52.5px;
  }
  .temp_calendar .month_item.active .month_anchor_active svg {
    width: 100%;
    height: 100%;
  }
  .temp_calendar .month_item.active .month_anchor_active div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .temp_calendar .month_item.active .month_anchor_active span {
    font-size: 22px;
    display: block;
    font-family: "KopubDotum";
    color: #f5a21f;
  }
  .temp_calendar .month_item.active .month_anchor_active em {
    font-size: 24px;
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 40px;
    color: #fff;
    background-color: #f5a21f;
    font-family: "recoFont";
  }
  .temp_calendar .month_item.active .month_anchor_active .clearfix {
    display: block;
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .class_group .class_top {
    width: 612px;
    height: 62px;

    margin: 0 auto;
    margin-bottom: 30px;
  }
  .class_group .class_top .class_top_box {
    width: 100%;
    height: 100%;
    border-radius: 35px;
    border: 3px solid #2d2288;
    display: flex;
    overflow: hidden;
  }
  .class_group .class_top .class_top_box .class_top_left {
    width: 250px;
    height: 100%;
    flex: none;
    background-color: #2d2288;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .class_group .class_top .class_top_box .class_top_left span {
    color: #fff;
    font-size: 32px;
    height: 62px;
    line-height: 70px;
    font-family: "recoFont";
  }

  .calendar_item {
    height: 460px;

    padding: 0 10px;
  }

  .calendar_anchor {
    display: block;
    position: relative;
    width: 100%;
    height: 95.3%;
    padding-top: 8px;
    overflow: hidden;
    box-shadow: 0px 5px 0px #d3d0d0;
    border-radius: 30px;
  }
  .calendar_anchor .calendar_inner_back_one {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    height: 100%;
  }

  .calendar_inner {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  .calendar_inner:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    animation-name: left-deco-ani2;
    animation-duration: 2000ms;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 1;
    transform: scale(1);
  }
  .calendar_anchor.n1 .calendar_inner:after {
    bottom: -220px;
    right: 0px;
    width: 220px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -245px;
    right: 0px;
    width: 260px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -210px;
    right: 0px;
    width: 260px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 54px;
    height: 64px;
    padding-top: 17px;

    font-size: 18px;
    line-height: 22px;
  }
  .class_group .c_title {
    padding-top: 50px;
    font-size: 32px;
    line-height: 44px;
  }
  .class_group .c_text {
    padding: 30px 36px;
    font-size: 23px;
    line-height: 26px;
  }

  .class_group .edu_status {
    bottom: 30px;
    left: 30px;
    width: 66px;
    height: 66px;
  }

  .class_group .edu_status span {
    padding: 0 5px;
    font-size: 18px;
    line-height: 25px;
  }

  .class_group .page {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
  }

  /* 마우스오버 */
  .calendar_anchor:hover {
  }
  .class_group .calendar_anchor:hover .edu_status {
    transform: scale(1.1);
  }

  @keyframes left-deco-ani1 {
    0% {
      transform: rotate(1deg) translateY(0px);
    }
    50% {
      transform: rotate(3deg) translateX(5%);
    }
    100% {
      transform: rotate(0deg) translateY(0px);
    }
  }

  @keyframes left-deco-ani2 {
    0% {
      transform: rotate(0deg) translateY(0px);
    }
    50% {
      transform: rotate(3deg) translateY(5%);
    }
    100% {
      transform: rotate(-1deg) translateY(0px);
    }
  }
}

@media all and (max-width: 1400px) {
  .my_class_flex .temp_calendar {
    width: 88px;
  }

  .my_class_flex .class_group_wrap {
    padding-right: 80px;
  }
  .my_class_flex .class_group_wrap .class_group {
    width: 1040px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom {
    margin-top: 40px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul {
    width: 560px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    width: 80px;
    height: 72px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    display: block;
    width: 50px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 18px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div > span {
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 28px;
  }
  .my_class_flex .shortcut {
    width: 70px;

    right: 4.20833333333%;
  }

  .shortcut_item {
    width: 100%;
    min-height: 72px;
  }
  .shortcut_list .shortcut_item:first-child {
  }
  .shortcut_list .shortcut_item:nth-child(2) {
  }
  .shortcut_list .shortcut_item:last-child {
  }
  .shortcut_item .shortcut_anchor:after {
    display: block;
    position: absolute;
    top: -5px;
    right: 0;
    /* background-image: url(../assets/images/main_sprite.png); */
    background-repeat: no-repeat;
    background-position: left -101px;
  }
  .shortcut_item.n1 .shortcut_anchor:after {
    width: 30px;
    height: 30px;
    background-position: left -184px;
  }
  .shortcut_item.n2 .shortcut_anchor:after {
    width: 28px;
    height: 30px;
    background-position: left -144px;
  }
  .shortcut_item.n3 .shortcut_anchor:after {
    width: 34px;
    height: 33px;
    background-position: left -101px;
  }
  .temp_calendar .calendar_top {
    width: 75px;
    height: 75px;
  }
  .temp_calendar .calendar_top .calendar_top_inner {
    border-radius: 50%;
    border: 5px solid #40b3e6;
  }
  .temp_calendar .calendar_top .calendar_top_inner svg {
    width: 70%;
  }

  .temp_calendar .top_box {
    width: 100px;

    margin-top: 20px;
  }
  .temp_calendar .month_box {
    width: 70px;
    border-radius: 55px;
  }
  .temp_calendar .month_box.scroll_active {
    transform: translateY(0px);
    opacity: 1;
  }

  .temp_calendar .month_box:after {
  }
  .temp_calendar .cont_btn {
    display: inline-block;
    width: 100%;
  }
  .temp_calendar .cont_btn.prev {
    padding: 24px 5px 8px;
  }
  .temp_calendar .cont_btn.next {
    padding: 8px 5px 24px;
  }
  .temp_calendar .cont_btn button {
    display: block;
    position: relative;
    height: 30px;

    font-size: 40px;
    color: #fff;
    margin: 0 auto;
  }

  .temp_calendar .month_anchor {
    padding: 0 15px;
    font-size: 24px;
    line-height: 35px;
  }
  .temp_calendar .month_anchor span {
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor {
    position: relative;
    padding: 0 5px;
    text-align: center;
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor span {
    display: inline-block;
    position: relative;
    margin-right: 10px;

    font-size: 27px;
    line-height: 30px;
    vertical-align: top;
    font-family: "KopubDotum";
  }
  .temp_calendar .month_item.active .month_anchor em {
    position: relative;
    top: -11px;
    width: 45px;
    height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #f5a220;
    text-align: center;
    vertical-align: top;
    line-height: 55px;
  }
  .temp_calendar .month_item.active .month_anchor:before {
    display: block;
    position: absolute;
    top: -17px;
    left: -10px;
    width: calc(100% + 13px);
    height: calc(100% + 10px);
    border-radius: 45px;
    background-color: #fff;
  }
  .temp_calendar .month_item .month_anchor_active {
    display: none;
  }
  .temp_calendar .month_item.active {
    position: relative;
    height: 96px;
    margin: 8px 0;
  }
  .temp_calendar .month_item.active .month_anchor_active {
    width: 105px;
    height: 96px;

    left: 50%;
    margin-left: -52.5px;
  }
  .temp_calendar .month_item.active .month_anchor_active svg {
    width: 100%;
    height: 100%;
  }
  .temp_calendar .month_item.active .month_anchor_active div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .temp_calendar .month_item.active .month_anchor_active span {
    font-size: 22px;
    display: block;
    font-family: "KopubDotum";
    color: #f5a21f;
  }
  .temp_calendar .month_item.active .month_anchor_active em {
    font-size: 24px;
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 40px;
    color: #fff;
    background-color: #f5a21f;
    font-family: "recoFont";
  }
  .temp_calendar .month_item.active .month_anchor_active .clearfix {
    display: block;
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .class_group .class_top {
    width: 512px;
    height: 60px;

    margin: 0 auto;
    margin-bottom: 30px;
  }
  .class_group .class_top .class_top_box {
    border-radius: 30px;
    border: 3px solid #2d2288;
  }
  .class_group .class_top .class_top_box .class_top_left {
    width: 190px;
    height: 100%;
  }
  .class_group .class_top .class_top_box .class_top_right {
    font-size: 20px;
  }
  .class_group .class_top .class_top_box .class_top_left span {
    color: #fff;
    font-size: 26px;
    height: 60px;
    line-height: 60px;
    font-family: "recoFont";
  }
  .class_group .class_top .class_top_box .class_top_left svg {
    /* font-size: 20px; */
    width: 47px;
  }
  .calendar_item {
    height: 430px;

    padding: 0 10px;
  }

  .calendar_anchor {
    display: block;
    position: relative;
    width: 100%;
    height: 95.3%;
    padding-top: 8px;
    overflow: hidden;
    box-shadow: 0px 5px 0px #d3d0d0;
    border-radius: 30px;
  }
  .calendar_anchor .calendar_inner_back_one {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  .calendar_inner {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  .calendar_inner:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    animation-name: left-deco-ani2;
    animation-duration: 2000ms;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 1;
    transform: scale(1);
  }
  .calendar_anchor.n1 .calendar_inner:after {
    bottom: -200px;
    right: 0px;
    width: 220px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -225px;
    right: 0px;
    width: 260px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -180px;
    right: 0px;
    width: 260px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 54px;
    height: 64px;
    padding-top: 17px;

    font-size: 18px;
    line-height: 22px;
  }
  .class_group .c_title {
    padding-top: 50px;
    font-size: 32px;
    line-height: 44px;
  }
  .class_group .c_text {
    padding: 30px 36px;
    font-size: 23px;
    line-height: 26px;
  }

  .class_group .edu_status {
    bottom: 30px;
    left: 30px;
    width: 66px;
    height: 66px;
  }

  .class_group .edu_status span {
    padding: 0 5px;
    font-size: 18px;
    line-height: 25px;
  }

  .class_group .page {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
  }

  /* 마우스오버 */
  .calendar_anchor:hover {
  }
  .class_group .calendar_anchor:hover .edu_status {
    transform: scale(1.1);
  }

  @keyframes left-deco-ani1 {
    0% {
      transform: rotate(1deg) translateY(0px);
    }
    50% {
      transform: rotate(3deg) translateX(5%);
    }
    100% {
      transform: rotate(0deg) translateY(0px);
    }
  }

  @keyframes left-deco-ani2 {
    0% {
      transform: rotate(0deg) translateY(0px);
    }
    50% {
      transform: rotate(3deg) translateY(5%);
    }
    100% {
      transform: rotate(-1deg) translateY(0px);
    }
  }
}

@media all and (max-width: 1300px) {
  .my_class_flex .temp_calendar {
    width: 80px;
  }

  .my_class_flex .class_group_wrap {
    padding-right: 70px;
  }
  .my_class_flex .class_group_wrap .class_group {
    width: 920px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom {
    margin-top: 3 0px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul {
    width: 525px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    width: 74px;
    height: 68px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    width: 48px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 18px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div > span {
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 28px;
  }

  .my_class_flex .shortcut {
    width: 66px;
  }
  .shortcut_item {
    width: 100%;
    min-height: 72px;
  }
  .shortcut_list .shortcut_item:first-child {
  }
  .shortcut_list .shortcut_item:nth-child(2) {
  }
  .shortcut_list .shortcut_item:last-child {
  }
  .shortcut_item .shortcut_anchor:after {
    display: block;
    position: absolute;
    top: -5px;
    right: 0;
    /* background-image: url(../assets/images/main_sprite.png); */
    background-repeat: no-repeat;
    background-position: left -101px;
  }
  .shortcut_item.n1 .shortcut_anchor:after {
    width: 30px;
    height: 30px;
    background-position: left -184px;
  }
  .shortcut_item.n2 .shortcut_anchor:after {
    width: 28px;
    height: 30px;
    background-position: left -144px;
  }
  .shortcut_item.n3 .shortcut_anchor:after {
    width: 34px;
    height: 33px;
    background-position: left -101px;
  }

  .temp_calendar .calendar_top {
    width: 60px;
    height: 60px;
  }
  .temp_calendar .calendar_top .calendar_top_inner {
    border-radius: 50%;
    border: 4px solid #40b3e6;
  }
  .temp_calendar .calendar_top .calendar_top_inner svg {
    width: 65%;
  }
  .temp_calendar .top_box {
    width: 80px;
    margin-top: 20px;
    /* height: 463px; */
  }
  .temp_calendar .month_box {
    width: 60px;
    /* border-radius: 55px; */
    margin-top: 0;
  }
  .temp_calendar .month_box.scroll_active {
    transform: translateY(0px);
    opacity: 1;
  }

  .temp_calendar .cont_btn {
    display: inline-block;
    width: 100%;
  }
  .temp_calendar .cont_btn.prev {
    padding: 24px 5px 8px;
  }
  .temp_calendar .cont_btn.next {
    padding: 8px 5px 24px;
  }
  .temp_calendar .cont_btn button {
    display: block;
    position: relative;
    height: 30px;

    font-size: 40px;
    color: #fff;
    margin: 0 auto;
  }

  .temp_calendar .month_anchor {
    padding: 0 13px;
    font-size: 22px;
    line-height: 28px;
  }
  .temp_calendar .month_anchor span {
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor {
    padding: 0 3px;
  }
  .temp_calendar .month_item.active .month_anchor span {
    font-size: 24px;
    line-height: 28px;

    /* background-color: red; */
  }
  .temp_calendar .month_item.active .month_anchor em {
    position: relative;
    top: -11px;
    width: 45px;
    height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #f5a220;
    text-align: center;
    vertical-align: top;
    line-height: 55px;
  }
  .temp_calendar .month_item.active .month_anchor:before {
    display: block;
    position: absolute;
    top: -17px;
    left: -10px;
    width: calc(100% + 13px);
    height: calc(100% + 10px);
    border-radius: 45px;
    background-color: #fff;
  }
  .temp_calendar .month_item .month_anchor_active {
    display: none;
  }

  .temp_calendar .month_item.active {
    position: relative;
    height: 74px;
    margin: 8px 0;
  }
  .temp_calendar .month_item.active .month_anchor_active {
    width: 80px;
    height: 74px;

    left: 50%;
    margin-left: -40px;
  }
  .temp_calendar .month_item.active .month_anchor_active svg {
    width: 100%;
    height: 100%;
  }
  .temp_calendar .month_item.active .month_anchor_active div {
  }
  .temp_calendar .month_item.active .month_anchor_active span {
    font-size: 18px;
  }
  .temp_calendar .month_item.active .month_anchor_active em {
    font-size: 18px;
    display: block;
    width: 28px;
    height: 28px;

    line-height: 30px;
  }
  .temp_calendar .month_item.active .month_anchor_active .clearfix {
    display: block;
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .class_group .class_top {
    width: 470px;
    height: 55px;

    margin-bottom: 30px;
  }
  .class_group .class_top .class_top_box {
    border-radius: 35px;
  }
  .class_group .class_top .class_top_box .class_top_left {
    width: 170px;
  }
  .class_group .class_top .class_top_box .class_top_right {
    font-size: 20px;
  }
  .class_group .class_top .class_top_box .class_top_left span {
    font-size: 24px;
    height: 55px;
    line-height: 55px;
  }
  .class_group .class_top .class_top_box .class_top_left svg {
    width: 40px;
  }
  .calendar_item {
    height: 390px;
    padding: 0 5px;
  }

  .calendar_anchor {
    display: block;
    position: relative;
    width: 100%;
    height: 95.3%;
    padding-top: 8px;
    overflow: hidden;
    box-shadow: 0px 5px 0px #d3d0d0;
    border-radius: 30px;
    /* background-color: red; */
  }

  .calendar_inner {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }
  .calendar_inner:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    animation-name: left-deco-ani2;
    animation-duration: 2000ms;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: 1;
    transform: scale(1);
  }
  .calendar_anchor.n1 .calendar_inner:after {
    bottom: -196px;
    right: 0px;
    width: 180px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -200px;
    right: 0px;
    width: 220px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -170px;
    right: 0px;
    width: 220px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 50px;
    height: 64px;
    padding-top: 17px;

    font-size: 16px;
    line-height: 20px;
  }
  .class_group .c_title {
    padding-top: 50px;
    font-size: 26px;
    line-height: 38px;
  }
  .class_group .c_text {
    padding: 20px 36px;
    font-size: 18px;
    line-height: 24px;
  }
  .class_group .c_text em {
    display: block;
  }

  .class_group .edu_status {
    bottom: 30px;
    left: 30px;
    width: 66px;
    height: 66px;
  }

  .class_group .edu_status span {
    padding: 0 5px;
    font-size: 18px;
    line-height: 25px;
  }

  .class_group .page {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
  }
}
@media all and (max-width: 1150px) {
  .my_class_flex .class_group_wrap .class_group {
    width: 700px;
  }

  .my_class_flex .class_group_wrap {
    padding-right: 70px;
  }

  .my_class_flex .class_group_wrap .class_group_bottom {
    margin-top: 3 0px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul {
    width: 525px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    width: 74px;
    height: 68px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    width: 48px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 18px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div > span {
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 28px;
  }

  .calendar_item {
    height: 427px;

    padding: 0 14px;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    height: auto;
    display: block;
  }

  .calendar_anchor.n1 .calendar_inner:after {
    bottom: -196px;
    right: 0px;
    width: 214px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -230px;
    right: 0px;
    width: 240px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -210px;
    right: 0px;
    width: 230px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 60px;
    height: 64px;
    padding-top: 17px;

    font-size: 18px;
    line-height: 20px;
  }
  .class_group .c_title {
    padding-top: 50px;
    font-size: 28px;
    line-height: 43px;
  }
  .class_group .c_text {
    padding: 24px 36px;
    font-size: 20px;
    line-height: 26px;
  }
  .class_group .c_text em {
    display: block;
  }

  .class_group .edu_status {
    bottom: 30px;
    left: 30px;
    width: 66px;
    height: 66px;
  }

  .class_group .edu_status span {
    padding: 0 5px;
    font-size: 18px;
    line-height: 25px;
  }
}
@media all and (max-width: 900px) {
  .my_class_flex .class_group_wrap .class_group {
    width: 600px;
  }

  .my_class_flex .class_group_wrap {
    padding-right: 70px;
  }

  .my_class_flex .class_group_wrap .class_group_bottom {
    margin-top: 3 0px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul {
    width: 420px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    width: 63px;
    height: 57px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    width: 40px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 14px;
    line-height: 20px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div > span {
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 28px;
  }

  .class_group .class_top {
    width: 340px;
    height: 38px;

    margin-bottom: 30px;
  }
  .class_group .class_top .class_top_box {
    border-radius: 30px;
  }
  .class_group .class_top .class_top_box .class_top_left {
    width: 120px;
  }
  .class_group .class_top .class_top_box .class_top_right {
    font-size: 14px;
  }
  .class_group .class_top .class_top_box .class_top_left span {
    font-size: 16px;
    height: 38px;
    line-height: 40px;
  }
  .class_group .class_top .class_top_box .class_top_left svg {
    width: 30px;
  }
  .calendar_item {
    height: 376px;

    padding: 0 8px;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    height: auto;
    display: block;
  }

  .calendar_anchor.n1 .calendar_inner:after {
    bottom: -190px;
    right: 0px;
    width: 180px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -210px;
    right: 0px;
    width: 200px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -190px;
    right: 0px;
    width: 190px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 50px;
    height: 60px;
    padding-top: 17px;

    font-size: 16px;
    line-height: 20px;
  }
  .class_group .c_title {
    padding-top: 50px;
    font-size: 24px;
    line-height: 43px;
  }
  .class_group .c_text {
    padding: 24px 36px;
    font-size: 20px;
    line-height: 26px;
  }
  .class_group .c_text em {
    display: block;
  }

  .class_group .edu_status {
    bottom: 30px;
    left: 30px;
    width: 62px;
    height: 62px;
  }

  .class_group .edu_status span {
    padding: 0 6px;
    font-size: 16px;
    line-height: 25px;
  }
}
@media all and (max-width: 800px) {
  .my_class_flex .class_group_wrap .class_group {
    width: 374px;
  }
  .calendar_item {
    height: 460px;

    padding: 0 14px;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    height: auto;
    display: block;
  }

  .calendar_anchor.n1 .calendar_inner:after {
    bottom: -210px;
    right: 0px;
    width: 240px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -240px;
    right: 0px;
    width: 270px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -210px;
    right: 0px;
    width: 260px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 60px;
    height: 64px;
    padding-top: 17px;

    font-size: 18px;
    line-height: 20px;
  }
  .class_group .c_title {
    padding-top: 55px;
    font-size: 28px;
    line-height: 43px;
  }
  .class_group .c_text {
    padding: 24px 36px;
    font-size: 24px;
    line-height: 26px;
  }
  .class_group .c_text em {
    display: block;
  }

  .class_group .edu_status {
    bottom: 30px;
    left: 30px;
    width: 70px;
    height: 70px;
  }

  .class_group .edu_status span {
    padding: 0 6px;
    font-size: 20px;
    line-height: 25px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul {
    width: 320px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    width: 63px;
    height: 57px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a svg {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a div {
  }
  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    width: 40px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 14px;
    line-height: 20px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div > span {
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 28px;
  }
}
/* @media all and (max-width: 1030px) {
  .my_class_flex .class_group_wrap .class_group {
    width: 732px;
  }
}
@media all and (max-width: 920px) {
  .my_class_flex .class_group_wrap .class_group {
    width: 653px;
  }
}
@media all and (max-width: 825px) {
  .my_class_flex .class_group_wrap .class_group {
    width: 585.75px;
  }
} */
@media all and (max-width: 640px) {
  /* 리스트 */
  .my {
    /* width: 100%;
    height: 100%;

    position: relative; */
    /* overflow: auto; */
  }
  .my .wrap {
    width: 380px;
    /* background-color: red; */
    padding: 20px 0;
  }

  .my_class_flex {
    width: 100%;
    flex-direction: column;
  }
  .my_class_flex .temp_calendar {
    width: 100%;
    /* background-color: beige; */
    flex: none;
    /* display: flex; */
    flex-direction: row;
    align-items: center;
  }
  .temp_calendar .top_box {
    /* width: 120px; */
    /* width: 320px; */
    margin-top: 0;
    /* background-color: red; */
    flex: 1;
    height: 44px;
    margin-left: 10px;
  }
  .my_class_flex .shortcut {
    width: 50px;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 160px;
    /* flex: none; */
  }
  .my_class_flex .class_group_wrap {
    flex: 1;
    padding-right: 0;
    margin-top: 30px;
  }
  .my_class_flex .class_group_wrap .class_group {
    width: 100%;
    margin: 0 auto;
  }
  .my_class_flex .class_group_wrap .class_group_bottom {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    position: relative;
    width: 60px;
    height: 55px;
  }

  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    width: 44px;
  }
  .shortcut {
    /* float: right;
    transform: translateY(50px); */
    /* opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 1000ms; */
    /* transition-timing-function: ease-in-out;
    transition-delay: 300ms; */
  }
  .shortcut.scroll_active {
    /* transform: translateY(0px);
    opacity: 1; */
  }
  .shortcut_list {
  }
  .shortcut_item {
    height: 50px;
  }

  .temp_calendar .calendar_top {
    width: 45px;
    height: 45px;
    flex: none;
  }
  .temp_calendar .calendar_top .calendar_top_inner {
    border: 3px solid #40b3e6;
  }
  .temp_calendar .calendar_top .calendar_top_inner svg {
    width: 70%;
    height: 70%;
  }

  .temp_calendar .month_box {
    border-radius: 22px;
    width: 100%;
    display: flex;
    align-items: center;

    margin-top: 0;
  }
  .temp_calendar .month_box.scroll_active {
    transform: translateY(0px);
    opacity: 1;
  }

  .temp_calendar .month_box:after {
  }
  .temp_calendar .cont_btn {
    padding: 0;
    /* vertical-align: top; */
  }
  .temp_calendar .cont_btn.prev {
    padding: 0px 5px 0px;
  }
  .temp_calendar .cont_btn.next {
    padding: 0px 5px 0px;
  }
  .temp_calendar .cont_btn button {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
  .temp_calendar .cont_btn button.prev1 {
    /* background-color: red; */
    transform: rotate(270deg);
  }
  .temp_calendar .cont_btn button.next1 {
    /* background-color: red; */
    transform: rotate(270deg);
  }

  .temp_calendar .month_list {
    display: flex;
    height: 100%;
    /* background-color: red; */
    /* display: inline-block; */
    /* vertical-align: top; */
  }
  .temp_calendar .month_item {
    padding: 0;
    height: 44px;
  }
  .temp_calendar .month_anchor {
    padding: 0 8px;
    font-size: 15px;
    line-height: 44px;
  }
  .temp_calendar .month_anchor span {
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor {
    position: relative;
    padding: 0 5px;
    text-align: center;
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor span {
    margin-right: 10px;

    font-size: 14px;
    line-height: 17px;
    vertical-align: middle;
    font-family: "KopubDotum";
  }
  .temp_calendar .month_item.active .month_anchor em {
    position: relative;
    top: -11px;
    width: 20px;
    height: 20px;

    border-radius: 100%;
    background-color: #f5a220;
    text-align: center;
    vertical-align: middle;
    line-height: 55px;
  }
  .temp_calendar .month_item.active .month_anchor:before {
    display: block;
    position: absolute;
    top: -17px;
    left: -10px;
    width: calc(100% + 13px);
    height: calc(100% + 10px);
    border-radius: 45px;
    background-color: #fff;
  }
  .temp_calendar .month_item .month_anchor_active {
    display: none;
  }
  .temp_calendar .month_item.active {
    position: relative;
    width: 61px;
    height: 100%;
    margin: 0 4px;
    /* background-color: red; */
  }
  .temp_calendar .month_item.active .month_anchor_active {
    width: 61px;
    height: 56px;
    top: -6px;
    left: 0;
    margin-left: 0;

    display: block;
  }
  .temp_calendar .month_item.active .month_anchor_active svg {
    width: 100%;
    height: 100%;
  }
  .temp_calendar .month_item.active .month_anchor_active div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .temp_calendar .month_item.active .month_anchor_active span {
    font-size: 14px;
  }
  .temp_calendar .month_item.active .month_anchor_active em {
    font-size: 14px;

    width: 20px;
    height: 20px;

    line-height: 24px;
  }
  .temp_calendar .month_item.active .month_anchor_active .clearfix {
    display: block;
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .class_group .class_top {
    width: 365px;
    height: 40px;

    margin: 0 auto;
    margin-bottom: 60px;
    /* background-color: red; */
  }
  .class_group .class_top .class_top_box {
    border-radius: 35px;
    border: 3px solid #2d2288;
  }
  .class_group .class_top .class_top_box .class_top_left {
    width: 130px;
    height: 100%;
  }
  .class_group .class_top .class_top_box .class_top_left svg {
    width: 30px;
    height: 18px;
  }
  .class_group .class_top .class_top_box .class_top_right {
    padding-left: 8px;
  }
  .class_group .class_top .class_top_box .class_top_left span {
    color: #fff;
    font-size: 18px;
  }
  .calendar_list {
    /* margin: 35px -25px 0; */
  }
  .calendar_list .slick-slide {
    /* margin: 0 25px;
    padding-bottom: 8px; */
  }
  .calendar_item {
    height: 294px;

    width: 100%;
  }

  .calendar_anchor {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    overflow: hidden;
    border-radius: 22px;
  }

  .calendar_anchor .calendar_inner_back_one {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    /* height: 100%; */
  }

  .calendar_inner {
    display: block;
    position: relative;
    /* background-color: red; */
    /* box-shadow: 3px 3px 3px rgba(213, 209, 208, 1); */
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  .calendar_anchor.n1 .calendar_inner:after {
    /* right: 11px; */

    bottom: -130px;
    right: 0px;
    width: 160px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -160px;
    right: 0px;
    width: 180px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -145px;
    right: 0px;
    width: 165px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 33px;
    height: 39px;
    padding-top: 8px;

    font-size: 12px;
    line-height: 20px;
  }
  .class_group .c_title {
    padding-top: 38px;
    font-size: 20px;
    line-height: 26px;
  }
  .class_group .c_text {
    padding: 18px 40px;
    font-size: 15px;
    line-height: 22px;
  }
  .class_group .c_text em {
    display: block;
  }

  .class_group .edu_status {
    bottom: 25px;
    left: 30px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all 0.3s linear;
  }
  .class_group .edu_status.n1 {
    background-color: #2d228a;
  }
  .class_group .edu_status.n2 {
    background-color: #fff;
  }
  .class_group .edu_status span {
    padding: 0 10px;
    font-size: 10px;
    line-height: 16px;
  }
  .class_group .edu_status.n2 span {
    color: #2d228a;
  }
  .class_group .page {
    display: block;
    margin-top: 10px;
    font-size: 20px;
    line-height: 22px;
    color: #fff;
    font-family: "KopubDotum";
    font-weight: 600;
  }
  .class_group .slick-dots {
    margin: 20px 0;
    text-align: center;
  }
  .class_group .slick-dots li {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .slick-dots li button {
    margin: 0 7px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #a0a0a0;
    font-size: 0;
  }
  .class_group .slick-dots li.slick-active button {
    background-color: #f4a023;
  }
}
@media all and (max-width: 540px) {
  /* 리스트 */
  .my {
    /* width: 100%;
    height: 100%;

    position: relative; */
    /* overflow: auto; */
  }
  .my .wrap {
    width: 320px;
    /* background-color: red; */
    padding: 14px 0;
  }

  .my_class_flex {
  }
  .my_class_flex .temp_calendar {
  }
  .temp_calendar .top_box {
    height: 40px;
    margin-left: 7px;
  }
  .my_class_flex .shortcut {
    width: 40px;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 160px;
    /* flex: none; */
  }
  .my_class_flex .class_group_wrap {
    margin-top: 20px;
  }
  .my_class_flex .class_group_wrap .class_group {
  }
  .my_class_flex .class_group_wrap .class_group_bottom {
    margin-top: 20px;
    flex-direction: column;
  }

  .my_class_flex .class_group_wrap .class_group_bottom ul li {
    position: relative;
    width: 55px;
    height: 45px;
  }

  .my_class_flex .class_group_wrap .class_group_bottom ul li a img {
    width: 30px;
  }
  .my_class_flex .class_group_wrap .class_group_bottom div {
    font-size: 10px;
    width: 100%;
    justify-content: center;
    /* text-align: center; */
  }
  .my_class_flex .class_group_wrap .class_group_bottom div svg {
    font-size: 14px;
  }
  .shortcut {
    /* float: right;
    transform: translateY(50px); */
    /* opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 1000ms; */
    /* transition-timing-function: ease-in-out;
    transition-delay: 300ms; */
  }
  .shortcut.scroll_active {
    /* transform: translateY(0px);
    opacity: 1; */
  }
  .shortcut_list {
  }
  .shortcut_item {
    height: 50px;
    min-height: 50px;
  }

  .temp_calendar .calendar_top {
    width: 45px;
    height: 45px;
    flex: none;
  }
  .temp_calendar .calendar_top .calendar_top_inner {
    border: 3px solid #40b3e6;
  }
  .temp_calendar .calendar_top .calendar_top_inner svg {
    width: 70%;
    height: 70%;
  }

  .temp_calendar .month_box {
    border-radius: 22px;
    width: 100%;
    display: flex;
    align-items: center;

    margin-top: 0;
  }
  .temp_calendar .month_box.scroll_active {
    transform: translateY(0px);
    opacity: 1;
  }

  .temp_calendar .month_box:after {
  }
  .temp_calendar .cont_btn {
    padding: 0;
    /* vertical-align: top; */
  }
  .temp_calendar .cont_btn.prev {
    padding: 0px 5px 0px;
  }
  .temp_calendar .cont_btn.next {
    padding: 0px 5px 0px;
  }
  .temp_calendar .cont_btn button {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
  .temp_calendar .cont_btn button.prev1 {
    /* background-color: red; */
    transform: rotate(270deg);
  }
  .temp_calendar .cont_btn button.next1 {
    /* background-color: red; */
    transform: rotate(270deg);
  }

  .temp_calendar .month_list {
    display: flex;
    height: 100%;
    /* background-color: red; */
    /* display: inline-block; */
    /* vertical-align: top; */
  }
  .temp_calendar .month_item {
    padding: 0;
    height: 44px;
  }
  .temp_calendar .month_anchor {
    padding: 0 8px;
    font-size: 12px;
    line-height: 44px;
  }
  .temp_calendar .month_anchor span {
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor {
    position: relative;
    padding: 0 5px;
    text-align: center;
    display: none;
  }
  .temp_calendar .month_item.active .month_anchor span {
    margin-right: 10px;

    font-size: 12px;
    line-height: 17px;
    vertical-align: middle;
    font-family: "KopubDotum";
  }
  .temp_calendar .month_item.active .month_anchor em {
    position: relative;
    top: -11px;
    width: 20px;
    height: 20px;

    border-radius: 100%;
    background-color: #f5a220;
    text-align: center;
    vertical-align: middle;
    line-height: 55px;
  }
  .temp_calendar .month_item.active .month_anchor:before {
    display: block;
    position: absolute;
    top: -17px;
    left: -10px;
    width: calc(100% + 13px);
    height: calc(100% + 10px);
    border-radius: 45px;
    background-color: #fff;
  }
  .temp_calendar .month_item .month_anchor_active {
    display: none;
  }
  .temp_calendar .month_item.active {
    position: relative;
    width: 61px;
    height: 100%;
    margin: 0 4px;
    /* background-color: red; */
  }
  .temp_calendar .month_item.active .month_anchor_active {
    width: 61px;
    height: 56px;
    top: -6px;
    left: 0;
    margin-left: 0;

    display: block;
  }
  .temp_calendar .month_item.active .month_anchor_active svg {
    width: 100%;
    height: 100%;
  }
  .temp_calendar .month_item.active .month_anchor_active div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .temp_calendar .month_item.active .month_anchor_active span {
    font-size: 12px;
  }
  .temp_calendar .month_item.active .month_anchor_active em {
    font-size: 12px;

    width: 20px;
    height: 20px;

    line-height: 24px;
  }
  .temp_calendar .month_item.active .month_anchor_active .clearfix {
    display: block;
  }
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  .class_group .class_top {
    width: 320px;
    height: 40px;

    margin: 0 auto;
    margin-bottom: 60px;
    /* background-color: red; */
  }
  .class_group .class_top .class_top_box {
    border-radius: 30px;
    border: 3px solid #2d2288;
  }
  .class_group .class_top .class_top_box .class_top_left {
    width: 130px;
    height: 100%;
  }
  .class_group .class_top .class_top_box .class_top_left svg {
    width: 30px;
    height: 18px;
  }
  .class_group .class_top .class_top_box .class_top_right {
    padding-left: 8px;
  }
  .class_group .class_top .class_top_box .class_top_left span {
    color: #fff;
    font-size: 18px;
  }
  .calendar_list {
    /* margin: 35px -25px 0; */
  }
  .calendar_list .slick-slide {
    /* margin: 0 25px;
    padding-bottom: 8px; */
  }
  .calendar_item {
    height: 294px;

    width: 100%;
  }

  .calendar_anchor {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    overflow: hidden;
    border-radius: 22px;
  }

  .calendar_anchor .calendar_inner_back_one {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .calendar_anchor .calendar_inner_back_two svg {
    width: 100%;
    /* height: 100%; */
  }

  .calendar_inner {
    display: block;
    position: relative;
    /* background-color: red; */
    /* box-shadow: 3px 3px 3px rgba(213, 209, 208, 1); */
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
  }

  .calendar_anchor.n1 .calendar_inner:after {
    /* right: 11px; */

    bottom: -160px;
    right: 0px;
    width: 140px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    bottom: -180px;
    right: 0px;
    width: 160px;

    animation-delay: 200ms;
    animation-name: left-deco-ani1;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    bottom: -165px;
    right: 0px;
    width: 145px;

    animation-delay: 500ms;
  }

  .class_group .month_item {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .c_num {
    display: block;
    position: absolute;
    top: -8px;
    left: 30px;
    width: 33px;
    height: 39px;
    padding-top: 8px;

    font-size: 12px;
    line-height: 20px;
  }
  .class_group .c_title {
    padding-top: 38px;
    font-size: 18px;
    line-height: 26px;
  }
  .class_group .c_text {
    padding: 10px 40px;
    font-size: 15px;
    line-height: 22px;
  }
  .class_group .c_text em {
    display: block;
  }

  .class_group .edu_status {
    bottom: 25px;
    left: 30px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all 0.3s linear;
  }
  .class_group .edu_status.n1 {
    background-color: #2d228a;
  }
  .class_group .edu_status.n2 {
    background-color: #fff;
  }
  .class_group .edu_status span {
    padding: 0 8px;
    font-size: 10px;
    line-height: 16px;
  }
  .class_group .edu_status.n2 span {
    color: #2d228a;
  }
  .class_group .page {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    font-family: "KopubDotum";
    font-weight: 600;
  }
  .class_group .slick-dots {
    margin: 20px 0;
    text-align: center;
  }
  .class_group .slick-dots li {
    display: inline-block;
    vertical-align: top;
  }
  .class_group .slick-dots li button {
    margin: 0 7px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #a0a0a0;
    font-size: 0;
    /* background-color: red; */
  }
  .class_group .slick-dots li.slick-active button {
    background-color: #f4a023;
  }
}
