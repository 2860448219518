@charset "UTF-8";

/* 리스트 */
.list {
  width: 100%;
  height: 100%;

  position: relative;
}
.list .wrap {
  width: 1500px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0 0;
}
.list_box {
  display: flex;
  width: 100%;
  height: auto;
}
.list_box .list_left_div {
  width: 210px;
  height: auto;
  padding: 90px 18px;
  background-color: #40b2e8;
  border-radius: 120px;
  flex: none;
  position: relative;
}
.list_box .list_left_div .list_left_arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;
  display: flex;
  font-size: 40px;
  justify-content: center;
  align-items: center;
  left: 50%;
  margin-left: -25px;
  right: 0;
}

.list_box .list_left_div.list_left_div_block {
  display: none;
}
.list_box .list_left_div .list_left_arrow_div {
  display: none;
}
.list_box .list_left_div .list_left_arrow svg {
  cursor: pointer;
}
.list_box .list_left_div .list_left_arrow.list_arrow_up {
  top: 25px;
}
.list_box .list_left_div .list_left_arrow.list_arrow_down {
  bottom: 25px;
}

.list_box .list_left_div ul {
  height: 550px;
}
.list_box .list_left_div ul > div {
  height: 100% !important;
}

.list_box .list_left_div ul li {
  width: 100%;
  height: 160px;
  margin-bottom: 20px;
  position: relative;
}
.list_box .list_left_div ul li:last-child {
  margin-bottom: 0;
}
.list_box .list_left_div ul li svg {
  width: 100%;
  height: 100%;
}
.list_box .list_left_div ul li .list_left_img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.list_box .list_left_div ul li .list_left_img img {
  width: 127px;
}
.list_box .list_right_div {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.list_box .list_right_div .list_right_year {
  width: 1074px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.list_box .list_right_div .list_right_year ul {
  display: flex;
  margin: 0 auto;
  width: 700px;
}
.list_box .list_right_div .list_right_year ul li {
  flex: 1;
  height: 54px;
  font-size: 36px;
  border: 5px solid #f5a21f;
  text-align: center;
  line-height: 45px;
  font-weight: 600;
  cursor: pointer;
}
.list_box .list_right_div .list_right_year ul.ul_second li {
  border-radius: 10px;
  cursor: default;
}
.list_box .list_right_div .list_right_year ul.ul_first li:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.list_box .list_right_div .list_right_year ul.ul_first li:last-child {
  border-left: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.list_box .list_right_div .list_right_year .list_right_li.list_right_color {
  color: #f5a21f;
  background: #fff;
}
.list_box .list_right_div .list_right_year .list_right_li {
  background: #f5a21f;
  color: #fff;
}

.list_box .list_right_div .list_right_wrap {
  width: 100%;
}
.list_box .list_right_div .list_right_wrap .list_content {
  width: 958px;
  height: 610px;
  margin: 0 auto;
  background: url("../assets/images/listBack.png") no-repeat;
  background-size: 100%;
  position: relative;
  padding: 10px 20px 0;
}

.list_item_img {
  width: 170px;
  position: relative;
  margin: 0 auto;
}
.list_item_img:hover .list_item_back {
  opacity: 1;
}
.list_item_img .list_item_back {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}
@media all and (max-width: 1600px) {
  /* 리스트 */
  .list {
  }
  .list .wrap {
    width: 100%;

    padding: 20px 4.20833333333%;
  }
  .list_box {
  }
  .list_box .list_left_div {
    width: 200px;

    padding: 85px 17px;

    border-radius: 120px;
  }
  .list_box .list_left_div .list_left_arrow {
    width: 50px;
    height: 50px;

    font-size: 40px;

    left: 50%;
    margin-left: -25px;
  }

  .list_box .list_left_div.list_left_div_block {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow_div {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow svg {
    cursor: pointer;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_up {
    top: 25px;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_down {
    bottom: 25px;
  }

  .list_box .list_left_div ul {
    height: 550px;
  }
  .list_box .list_left_div ul > div {
    height: 100% !important;
  }

  .list_box .list_left_div ul li {
    width: 100%;
    height: 160px;
    margin-bottom: 20px;
    position: relative;
  }
  .list_box .list_left_div ul li:last-child {
    margin-bottom: 0;
  }
  .list_box .list_left_div ul li svg {
    width: 100%;
    height: 100%;
  }
  .list_box .list_left_div ul li .list_left_img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }

  .list_box .list_left_div ul li .list_left_img img {
    width: 120px;
  }
  .list_box .list_right_div {
  }

  .list_box .list_right_div .list_right_year {
    width: 1024px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .list_box .list_right_div .list_right_year ul {
    width: 666px;
  }
  .list_box .list_right_div .list_right_year ul li {
    flex: 1;
    height: 50px;
    font-size: 32px;
    border: 5px solid #f5a21f;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    cursor: pointer;
  }
  .list_box .list_right_div .list_right_year ul.ul_second li {
    border-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:last-child {
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .list_box .list_right_div .list_right_wrap {
    width: 100%;
  }
  .list_box .list_right_div .list_right_wrap .list_content {
    width: 910px;
    height: 580px;

    padding: 10px 20px 0;
  }

  .list_item_img {
    width: 162px;
  }
  .list_item_img:hover .list_item_back {
    opacity: 1;
  }
  .list_item_img .list_item_back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}
@media all and (max-width: 1300px) {
  /* 리스트 */
  .list {
  }
  .list .wrap {
    width: 100%;

    padding: 20px 4.20833333333%;
  }
  .list_box {
  }
  .list_box .list_left_div {
    width: 163px;

    padding: 70px 14px;

    border-radius: 120px;
  }
  .list_box .list_left_div .list_left_arrow {
    width: 40px;
    height: 40px;

    font-size: 36px;

    left: 50%;
    margin-left: -20px;
  }

  .list_box .list_left_div.list_left_div_block {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow_div {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow svg {
    cursor: pointer;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_up {
    top: 20px;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_down {
    bottom: 20px;
  }

  .list_box .list_left_div ul {
    height: 428px;
  }
  .list_box .list_left_div ul > div {
    height: 100% !important;
  }

  .list_box .list_left_div ul li {
    width: 100%;
    height: 124px;
    margin-bottom: 20px;
    position: relative;
  }
  .list_box .list_left_div ul li:last-child {
    margin-bottom: 0;
  }
  .list_box .list_left_div ul li svg {
    width: 100%;
    height: 100%;
  }
  .list_box .list_left_div ul li .list_left_img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }

  .list_box .list_left_div ul li .list_left_img img {
    width: 100px;
  }
  .list_box .list_right_div {
  }

  .list_box .list_right_div .list_right_year {
    width: 842px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .list_box .list_right_div .list_right_year ul {
    width: 540px;
  }
  .list_box .list_right_div .list_right_year ul li {
    height: 40px;
    font-size: 28px;
    border: 4px solid #f5a21f;

    line-height: 36px;
    font-weight: 600;
    cursor: pointer;
  }
  .list_box .list_right_div .list_right_year ul.ul_second li {
    border-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:last-child {
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .list_box .list_right_div .list_right_wrap {
    width: 100%;
  }
  .list_box .list_right_div .list_right_wrap .list_content {
    width: 742px;
    height: 504px;

    padding: 10px 20px 0;
  }

  .list_item_img {
    width: 130px;
  }
  .list_item_img:hover .list_item_back {
    opacity: 1;
  }
  .list_item_img .list_item_back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}

@media all and (max-width: 1100px) {
  /* 리스트 */
  .list {
  }
  .list .wrap {
    width: 100%;

    padding: 20px 4.20833333333%;
  }
  .list_box {
  }
  .list_box .list_left_div {
    width: 138px;

    padding: 60px 12px;

    border-radius: 120px;
  }
  .list_box .list_left_div .list_left_arrow {
    width: 40px;
    height: 40px;

    font-size: 36px;

    left: 50%;
    margin-left: -20px;
  }

  .list_box .list_left_div.list_left_div_block {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow_div {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow svg {
    cursor: pointer;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_up {
    top: 20px;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_down {
    bottom: 20px;
  }

  .list_box .list_left_div ul {
    height: 360px;
  }
  .list_box .list_left_div ul > div {
    height: 100% !important;
  }

  .list_box .list_left_div ul li {
    width: 100%;
    height: 104px;
    margin-bottom: 20px;
    position: relative;
  }

  .list_box .list_left_div ul li .list_left_img img {
    width: 84px;
  }

  .list_box .list_right_div .list_right_year {
    width: 704px;
    margin-bottom: 30px;
  }
  .list_box .list_right_div .list_right_year ul {
    width: 460px;
  }
  .list_box .list_right_div .list_right_year ul li {
    height: 35px;
    font-size: 26px;
    border: 4px solid #f5a21f;

    line-height: 28px;
    font-weight: 600;
    cursor: pointer;
  }
  .list_box .list_right_div .list_right_year ul.ul_second li {
    border-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:last-child {
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .list_box .list_right_div .list_right_wrap {
    width: 100%;
  }
  .list_box .list_right_div .list_right_wrap .list_content {
    width: 627px;
    height: 400px;

    padding: 10px 20px 0;
  }

  .list_item_img {
    width: 110px;
  }
  .list_item_img:hover .list_item_back {
    opacity: 1;
  }
  .list_item_img .list_item_back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}

@media all and (max-width: 900px) {
  /* 리스트 */
  .list {
  }
  .list .wrap {
    width: 100%;

    padding: 20px 4.20833333333%;
  }
  .list_box {
  }
  .list_box .list_left_div {
    width: 110px;

    padding: 50px 12px;

    border-radius: 120px;
  }
  .list_box .list_left_div .list_left_arrow {
    width: 35px;
    height: 35px;

    font-size: 28px;

    left: 50%;
    margin-left: -17.5px;
  }

  .list_box .list_left_div.list_left_div_block {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow_div {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow svg {
    cursor: pointer;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_up {
    top: 17.5px;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_down {
    bottom: 17.5px;
  }

  .list_box .list_left_div ul {
    height: 300px;
  }
  .list_box .list_left_div ul > div {
    height: 100% !important;
  }

  .list_box .list_left_div ul li {
    width: 100%;
    height: 85px;
    margin-bottom: 20px;
  }

  .list_box .list_left_div ul li .list_left_img img {
    width: 68px;
  }

  .list_box .list_right_div .list_right_year {
    width: 580px;
    margin-bottom: 30px;
  }
  .list_box .list_right_div .list_right_year ul {
    width: 370px;
  }
  .list_box .list_right_div .list_right_year ul li {
    height: 32px;
    font-size: 24px;
    border: 4px solid #f5a21f;

    line-height: 26px;
    font-weight: 600;
    cursor: pointer;
  }
  .list_box .list_right_div .list_right_year ul.ul_second li {
    border-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:last-child {
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .list_box .list_right_div .list_right_wrap {
    width: 100%;
  }
  .list_box .list_right_div .list_right_wrap .list_content {
    width: 530px;
    height: 360px;

    padding: 10px 20px 0;
  }

  .list_item_img {
    width: 92px;
  }
  .list_item_img:hover .list_item_back {
    opacity: 1;
  }
  .list_item_img .list_item_back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}

@media all and (max-width: 780px) {
  /* 리스트 */
  .list {
  }
  .list .wrap {
    width: 100%;

    padding: 20px 4.20833333333%;
  }
  .list_box {
  }
  .list_box .list_left_div {
    width: 95px;

    padding: 40px 12px;

    border-radius: 120px;
  }
  .list_box .list_left_div .list_left_arrow {
    width: 35px;
    height: 35px;

    font-size: 28px;

    left: 50%;
    margin-left: -17.5px;
  }

  .list_box .list_left_div.list_left_div_block {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow_div {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow svg {
    cursor: pointer;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_up {
    top: 17.5px;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_down {
    bottom: 17.5px;
  }

  .list_box .list_left_div ul {
    height: 250px;
  }
  .list_box .list_left_div ul > div {
    height: 100% !important;
  }

  .list_box .list_left_div ul li {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
  }

  .list_box .list_left_div ul li .list_left_img img {
    width: 58px;
  }

  .list_box .list_right_div .list_right_year {
    width: 500px;
    margin-bottom: 30px;
  }
  .list_box .list_right_div .list_right_year ul {
    width: 370px;
  }
  .list_box .list_right_div .list_right_year ul li {
    height: 28px;
    font-size: 22px;
    border: 3px solid #f5a21f;

    line-height: 22px;
  }
  .list_box .list_right_div .list_right_year ul.ul_second li {
    border-radius: 8px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .list_box .list_right_div .list_right_wrap {
    width: 100%;
  }
  .list_box .list_right_div .list_right_wrap .list_content {
    width: 430px;
    height: 280px;

    padding: 6px 20px 0;
  }

  .list_item_img {
    width: 76px;
  }
  .list_item_img:hover .list_item_back {
    opacity: 1;
  }
  .list_item_img .list_item_back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}

@media all and (max-width: 640px) {
  /* 리스트 */
  .list {
    width: 100%;
    height: 100%;

    position: relative;
    /* overflow: auto; */
  }
  .list .wrap {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 25px 4.20833333333%;
  }
  .list_box {
    /* margin: 0 -25px; */

    flex-direction: column;
    /* display: flex;
    width: 100%;
    height: auto; */
  }
  .list_box .list_left_div {
    width: 100%;
    height: 110px;
    padding: 20px 50px;
    /* background-color: #40b2e8; */
    /* background-color: red; */
    border-radius: 120px;
    flex: none;
    position: relative !important;
  }

  .list_box .list_left_div.list_left_div_block_one {
    display: none;
  }
  .list_box .list_left_div.list_left_div_block {
    display: block;
  }

  .list_box .list_left_arrow.list_block {
    display: none;
  }
  .list_box .list_left_div .list_left_arrow {
    width: 40px;
    /* background-color: red; */
    height: 50px;
    right: 100px !important;
    /* display: none; */
    font-size: 40px;
    /* justify-content: center;
    align-items: center; */

    /* margin-left: -25px; */
  }
  .list_box .list_left_div .list_left_arrow svg {
    cursor: pointer;
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_up {
    position: absolute;
    z-index: 1;
    top: 25px;
    left: 50px;
    transform: rotate(180deg);
  }
  .list_box .list_left_div .list_left_arrow.list_arrow_down {
    position: absolute !important;
    z-index: 1;
    bottom: 25px;
    /* right: 100px !important; */
    background-color: black;
  }
  .list_box .list_left_div .list_left_arrow_div {
    display: block;
    position: absolute;

    z-index: 1;
  }
  .list_box .list_left_div .list_left_arrow_div.list_arrow_up_div {
    top: 50%;
    margin-top: -25px;
    left: 0;
  }
  .list_box .list_left_div .list_left_arrow_div.list_arrow_down_div {
    top: 50%;
    margin-top: -25px;
    right: 0;
  }
  .list_box .list_left_div .list_arrow_div {
    display: block;
    width: 100%;
    height: 100%;
    /* position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 30px; */
    /* background-color: red; */
  }
  .list_box .list_left_div .list_arrow_div > div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
  }
  .list_box .list_left_div .list_left_arrow_div {
    /* position: absol?ute; */
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list_box .list_left_div ul {
    display: block;
    height: auto;
  }
  .list_box .list_left_div ul > div > div {
    display: flex;
    flex-direction: row;
    height: auto;
  }
  .list_box .list_left_div ul li {
    width: 75px;
    height: 70px;
    margin: 0 auto;
    /* margin-bottom: 0; */
    /* position: relative; */
  }
  .list_box .list_left_div ul li:last-child {
    margin-bottom: 0;
  }
  .list_box .list_left_div ul li svg {
    width: 100%;
    height: 100%;
  }
  .list_box .list_left_div ul li .list_left_img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  }

  .list_box .list_left_div ul li .list_left_img img {
    width: 55px;
  }
  .list_box .list_right_div {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
  }

  .list_box .list_right_div .list_right_year {
    /* width: 100%; */
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .list_box .list_right_div .list_right_year ul {
    display: flex;
    margin: 0 auto;
    width: 330px;
  }
  .list_box .list_right_div .list_right_year ul li {
    /* width: 400px; */
    flex: 1;
    height: 40px;
    font-size: 20px;
    border: 2px solid #f5a21f;
    text-align: center;
    line-height: 35px;

    /* font-family: r; */
  }

  .list_box .list_right_div .list_right_year ul.ul_first li:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .list_box .list_right_div .list_right_year ul.ul_first li:last-child {
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .list_box .list_right_div .list_right_year .list_right_li.list_right_color {
    color: #f5a21f;
    background: #fff;
  }
  .list_box .list_right_div .list_right_year .list_right_li {
    background: #f5a21f;
    color: #fff;
  }

  .list_box .list_right_div .list_right_wrap {
    width: 100%;
  }
  .list_box .list_right_div .list_right_wrap .list_content {
    width: 330px;
    height: 600px;
    margin: 0 auto;
    background: url("../assets/images/listBack_m.png") no-repeat;
    background-size: 100%;
    position: relative;
    padding: 50px 0 0;
  }

  .list_item_img {
    width: 100px;
    position: relative;
  }
  .list_item_img:hover .list_item_back {
    opacity: 1;
  }
  .list_item_img .list_item_back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}
