@charset "UTF-8";

/* 리스트 */
.notice {
  width: 100%;
  height: 100%;

  position: relative;
}
.notice .wrap {
  width: 1500px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0 0;
}
.notice_box {
  width: 100%;
  height: auto;
}
.notice_box .notice_top {
  width: 100%;
  height: auto;
  margin-bottom: 60px;
}

.notice_top ul {
  margin: 0 auto;
  width: 810px;

  display: flex;
  height: 60px;
}

.notice_top ul li {
  flex: 1;
  border: 5px solid #f5a21f;
  overflow: hidden;
  cursor: pointer;
}
.notice_top ul li a {
  color: #f5a21f;
  background: #fff;
  font-size: 36px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notice_top div {
  margin: 0 auto;
  width: 400px;
  border: 5px solid #f5a21f;
  /* display: flex; */
  height: 60px;
  border-radius: 10px;
  color: #f5a21f;
  background: #fff;
  font-size: 36px;
  font-weight: 600;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice_top ul li:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.notice_top ul li:last-child {
  border-left: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.notice_top ul li.color_li a {
  background: #f5a21f;
  color: #fff;
}
.notice_content_box .notice_content_search {
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
}
.notice_content_box .notice_content_search .notice_search_wrap {
  display: flex;
  align-items: center;

  width: 400px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  border: 3px solid #dcdddd;
  background-color: #fff;
  position: relative;
}
.notice_content_search .notice_search_wrap .notice_search_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 50px;
  width: 90px;
  border-right: 3px solid #dcdddd;
}
.notice_search_wrap .notice_filter {
  display: none;
  position: absolute;

  top: 40px;
  left: -2px;
  height: auto;

  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
  width: 93px;
  background-color: #fff;
  text-align: center;
  border: 3px solid #dcdddd;
  border-top: 0;
  font-size: 18px;

  /* background-color: red; */
}
.notice_search_wrap .notice_filter.filter_view {
  display: block;
}
.notice_search_wrap .notice_filter li {
  cursor: pointer;
}
.notice_search_wrap .notice_filter li:hover {
  background: #f3f3f3;
}
.notice_search_wrap .notice_search_box {
  display: flex;
  height: 100%;
  flex: 1;
}
.notice_search_wrap .notice_search_box .notice_search_inp {
  flex: 1;
  padding: 0 14px;
}
.notice_search_wrap .notice_search_box .notice_search_inp input {
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.notice_search_wrap .notice_search_box .notice_search_inp input:focus {
  border: none;
  outline: 0;
}
.notice_search_wrap .notice_search_box button {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  font-size: 22px;
  font-weight: 700;
}

.notice_table_wrap {
  padding: 20px 80px;
}
.notice_table_wrap .notice_table {
  border: 3px solid #2d2288;
  border-left: 0;
  border-right: 0;
}
.notice_table_wrap .notice_table thead {
  height: 70px;
  text-align: center;
  border-bottom: 1px solid #dcdddd;
  font-size: 25px;
  font-weight: 700;
}
.notice_table_wrap .notice_table tbody tr {
  height: 60px;
  border-bottom: 1px solid #dcdddd;
  font-size: 20px;
  font-weight: 600;
}
.notice_table_wrap .notice_table tbody tr td:first-child,
.notice_table_wrap .notice_table tbody tr td:last-child {
  text-align: center;
}
.notice_table_wrap .notice_table tbody tr td:last-child {
  color: #9fa0a0;
}
.notice_table_wrap .notice_table .notice_category_div {
  width: 65px;
  height: 25px;
  background: #f5a21f;
  color: #fff;
  text-align: center;
  border-radius: 14px;
  line-height: 25px;
  font-weight: 600;
  margin: 0 auto;
}

.notice_table_wrap .notice_table_view {
}
.notice_table_wrap .notice_table_view .notice_view_title {
  border-bottom: 3px solid #2d2288;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.notice_table_wrap .notice_table_view .notice_view_title p {
  flex: 1;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.notice_table_wrap .notice_table_view .notice_view_title span {
  width: 180px;
  /* background-color: red; */
  flex: none;
  text-align: right;
  padding-right: 10px;
  /* float: right; */
}
.notice_table_wrap .notice_table_view .notice_view_content {
  padding: 20px;
  border-bottom: 3px solid #2d2288;
}
.notice_table_wrap .notice_table_view .notice_view_content > div {
  width: 100%;
  height: 100%;
  font-size: 20px;
}
.notice_table_wrap .notice_re {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.notice_table_wrap .notice_re button {
  font-size: 14px;
  width: 100px;
  height: 30px;
  background-color: #2d2288;
  color: #fff;
  font-weight: 600;
  /* background-color: red; */
}
.table_pagination_delete {
  margin-top: 20px;
}
.table_pagination_delete .table_pagination_delete_box {
  text-align: center;
  font-size: 28px;
}
.table_pagination_delete button {
  color: #2d2288;
}
.notice_table_wrap .qna_table {
  border: 3px solid #2d2288;
  border-left: 0;
  border-right: 0;
}
.notice_table_wrap .qna_pag {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.notice_table_wrap .qna_table .qna_table_hd {
  height: 70px;
  text-align: center;
  border-bottom: 1px solid #dcdddd;
  font-size: 25px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
.notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
  text-align: center;
  width: 130px;
}
.qna_table_view_cont {
  border-bottom: 1px solid #dcdddd;
  background: #f4f4f4;
  font-weight: 600;
  padding: 5px 0;
}
.qna_table_view_cont .qna_table_view_ca {
  width: 260px;
  height: auto;
  flex: none;
}
.notice_table_wrap .qna_table .qna_table_tb {
  height: 60px;
  border-bottom: 1px solid #dcdddd;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
  border-bottom: 0;
  min-height: 60px;
  height: auto;
}
.notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
.notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
  text-align: center;
  width: 130px;
  display: flex;
  align-items: center;
  flex: none;
  /* background-color: red; */
}

.notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
  width: 65px;
  height: 25px;
  background: #aace46;
  color: #fff;
  text-align: center;
  border-radius: 14px;
  line-height: 20px;
  font-weight: 600;
  margin: 0 auto;
}
.notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  color: #aace46;
  margin: 0 auto;
}

.notice_table_wrap .qna_table .qna_table_tb .qna_table_td_tit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  flex: 1;
}
.notice_table_wrap .qna_table .qna_table_tb .qna_up_down {
  color: #2d2288;
  /* position: absolute; */
  /* z-index: 1; */
  /* right: 5px; */
  font-size: 24px;
}
.table_pagination_delete {
  margin-top: 20px;
}
.table_pagination_delete .table_pagination_delete_box {
  text-align: center;
  font-size: 28px;
}
@media all and (max-width: 1600px) {
  .notice .wrap {
    width: 1200px;

    padding: 20px 0 0;
  }
  .notice_box {
    width: 100%;
    height: auto;
  }
  .notice_box .notice_top {
    margin-bottom: 40px;
  }

  .notice_top ul {
    margin: 0 auto;
    width: 650px;

    display: flex;
    height: 50px;
  }
  .notice_top ul li {
    flex: 1;
    border: 4px solid #f5a21f;

    cursor: pointer;
  }
  .notice_top ul li a {
    font-size: 30px;
  }
  .notice_top div {
    width: 380px;
    border: 4px solid #f5a21f;
    height: 50px;
    border-radius: 8px;

    font-size: 30px;
  }
  .notice_top ul li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .notice_top ul li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .notice_top ul li.color_li a {
  }
  .notice_content_box .notice_content_search {
    padding-right: 60px;
  }
  .notice_content_box .notice_content_search .notice_search_wrap {
    width: 340px;
    height: 44px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    border: 2px solid #dcdddd;
  }
  .notice_content_search .notice_search_wrap .notice_search_filter {
    height: 44px;
    width: 80px;
    border-right: 2px solid #dcdddd;
  }
  .notice_search_wrap .notice_filter {
    top: 34px;
    left: -3px;
    height: auto;

    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    width: 83px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #dcdddd;
    border-top: 0;
    font-size: 18px;

    /* background-color: red; */
  }

  .notice_search_wrap .notice_search_box .notice_search_inp {
    padding: 0 10px;
  }
  .notice_search_wrap .notice_search_box .notice_search_inp input {
  }

  .notice_search_wrap .notice_search_box button {
    width: 34px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    font-size: 20px;
    font-weight: 700;
  }

  .notice_table_wrap {
    padding: 14px 60px;
  }
  .notice_table_wrap .notice_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .notice_table thead {
    height: 54px;

    font-size: 22px;
    font-weight: 700;
  }
  .notice_table_wrap .notice_table tbody tr {
    height: 54px;
    border-bottom: 1px solid #dcdddd;
    font-size: 18px;
  }

  .notice_table_wrap .notice_table .notice_category_div {
    width: 65px;
    height: 25px;
    background: #f5a21f;
    color: #fff;
    text-align: center;
    border-radius: 12px;
    line-height: 20px;
  }

  .notice_table_wrap .notice_table_view .notice_view_title {
    border-bottom: 3px solid #2d2288;
    font-size: 22px;

    padding: 5px 0;
  }
  .notice_table_wrap .notice_table_view .notice_view_title p {
    flex: 1;
    padding-left: 10px;
  }
  .notice_table_wrap .notice_table_view .notice_view_title span {
    width: 180px;
    /* background-color: red; */
    flex: none;
    text-align: right;
    padding-right: 10px;
    /* float: right; */
  }
  .notice_table_wrap .notice_table_view .notice_view_content {
    padding: 20px;
    border-bottom: 3px solid #2d2288;
  }
  .notice_table_wrap .notice_table_view .notice_view_content > div {
    font-size: 18px;
  }
  .notice_table_wrap .notice_re {
    margin-top: 5px;
  }
  .notice_table_wrap .notice_re button {
    font-size: 14px;

    height: 30px;

    /* background-color: red; */
  }

  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    text-align: center;
    font-size: 22px;
    margin: 0 4px;
  }

  .notice_table_wrap .qna_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .qna_pag {
    margin-top: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_hd {
    height: 54px;
    font-size: 22px;
    font-weight: 700;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
    text-align: center;
    width: 130px;
  }
  .qna_table_view_cont {
    border-bottom: 1px solid #dcdddd;
    background: #f4f4f4;
    font-weight: 600;
    padding: 5px 0;
  }
  .qna_table_view_cont .qna_table_view_ca {
    width: 260px;
    height: auto;
  }
  .notice_table_wrap .qna_table .qna_table_tb {
    height: 54px;
    font-size: 18px;
  }
  .notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
    border-bottom: 0;
    min-height: 54px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
    text-align: center;
    width: 130px;
    display: flex;
    align-items: center;
  }

  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
    width: 65px;
    height: 25px;

    border-radius: 12px;
    line-height: 20px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  }
  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box {
    text-align: center;
    font-size: 24px;
  }
}

@media all and (max-width: 1200px) {
  .notice .wrap {
    width: 970px;

    padding: 10px 0 0;
  }
  .notice_box {
  }
  .notice_box .notice_top {
    margin-bottom: 30px;
  }

  .notice_top ul {
    width: 550px;

    height: 45px;
  }
  .notice_top ul li {
    flex: 1;
    border: 4px solid #f5a21f;

    cursor: pointer;
  }
  .notice_top ul li a {
    font-size: 24px;
  }
  .notice_top div {
    width: 320px;
    border: 4px solid #f5a21f;
    height: 45px;
    border-radius: 8px;

    font-size: 24px;
  }
  .notice_top ul li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .notice_top ul li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .notice_top ul li.color_li a {
  }
  .notice_content_box .notice_content_search {
    padding-right: 60px;
  }
  .notice_content_box .notice_content_search .notice_search_wrap {
    width: 300px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    border: 2px solid #dcdddd;
  }
  .notice_content_search .notice_search_wrap .notice_search_filter {
    height: 40px;
    width: 80px;
    border-right: 2px solid #dcdddd;
  }
  .notice_search_wrap .notice_filter {
    top: 34px;
    left: -3px;
    height: auto;

    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    width: 83px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #dcdddd;
    border-top: 0;
    font-size: 18px;

    /* background-color: red; */
  }

  .notice_search_wrap .notice_search_box .notice_search_inp {
    padding: 0 8px;
  }
  .notice_search_wrap .notice_search_box .notice_search_inp input {
  }

  .notice_search_wrap .notice_search_box button {
    width: 34px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    font-size: 20px;
    font-weight: 700;
  }

  .notice_table_wrap {
    padding: 14px 60px;
  }
  .notice_table_wrap .notice_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .notice_table thead {
    height: 50px;

    font-size: 20px;
    font-weight: 700;
  }
  .notice_table_wrap .notice_table tbody tr {
    height: 50px;
    border-bottom: 1px solid #dcdddd;
    font-size: 16px;
  }

  .notice_table_wrap .notice_table .notice_category_div {
    width: 60px;
    height: 25px;

    border-radius: 12px;
    line-height: 20px;
  }

  .notice_table_wrap .notice_table_view .notice_view_title {
    border-bottom: 2px solid #2d2288;
    font-size: 20px;

    padding: 5px 0;
  }
  .notice_table_wrap .notice_table_view .notice_view_title p {
    flex: 1;
    padding-left: 10px;
  }
  .notice_table_wrap .notice_table_view .notice_view_title span {
    width: 180px;
    /* background-color: red; */
    flex: none;
    text-align: right;
    padding-right: 10px;
    /* float: right; */
  }
  .notice_table_wrap .notice_table_view .notice_view_content {
    padding: 16px;
    border-bottom: 2px solid #2d2288;
  }
  .notice_table_wrap .notice_table_view .notice_view_content > div {
    font-size: 16px;
  }
  .notice_table_wrap .notice_re {
    margin-top: 5px;
  }
  .notice_table_wrap .notice_re button {
    font-size: 12px;
    width: 80px;
    height: 24px;
    line-height: 22px;

    /* background-color: red; */
  }

  .table_pagination_delete {
    margin-top: 12px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    text-align: center;
    font-size: 20px;
    margin: 0 4px;
  }

  .notice_table_wrap .qna_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .qna_pag {
    margin-top: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_hd {
    height: 50px;

    font-size: 20px;
    font-weight: 700;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
    text-align: center;
    width: 100px;
  }
  .qna_table_view_cont {
    border-bottom: 1px solid #dcdddd;
    background: #f4f4f4;
    font-weight: 600;
    padding: 5px 0;
  }
  .qna_table_view_cont .qna_table_view_ca {
    width: 200px;
    height: auto;
  }
  .notice_table_wrap .qna_table .qna_table_tb {
    height: 50px;
    font-size: 16px;
  }
  .notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
    border-bottom: 0;
    min-height: 50px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
    width: 100px;
  }

  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
    width: 60px;
    height: 25px;

    border-radius: 12px;
    line-height: 20px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  }
  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box {
    text-align: center;
    font-size: 24px;
  }
}

@media all and (max-width: 1000px) {
  .notice .wrap {
    width: 800px;

    padding: 10px 0 0;
  }
  .notice_box {
  }
  .notice_box .notice_top {
    margin-bottom: 24px;
  }

  .notice_top ul {
    width: 420px;

    height: 38px;
  }
  .notice_top ul li {
    border: 4px solid #f5a21f;

    cursor: pointer;
  }
  .notice_top ul li a {
    font-size: 20px;
  }
  .notice_top div {
    width: 300px;
    border: 4px solid #f5a21f;
    height: 38px;
    border-radius: 8px;

    font-size: 20px;
  }

  .notice_top ul li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .notice_top ul li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .notice_top ul li.color_li a {
  }
  .notice_content_box .notice_content_search {
    padding-right: 40px;
  }
  .notice_content_box .notice_content_search .notice_search_wrap {
    width: 280px;
    height: 36px;
    font-size: 14px;

    border-radius: 10px;
    border: 2px solid #dcdddd;
  }
  .notice_content_search .notice_search_wrap .notice_search_filter {
    height: 36px;
    width: 80px;
    border-right: 2px solid #dcdddd;
  }
  .notice_search_wrap .notice_filter {
    top: 24px;
    left: -1px;
    height: auto;

    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    width: 81px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #dcdddd;
    border-top: 0;
    font-size: 14px;

    /* background-color: red; */
  }

  .notice_search_wrap .notice_search_box .notice_search_inp {
    padding: 0 8px;
  }
  .notice_search_wrap .notice_search_box .notice_search_inp input {
  }

  .notice_search_wrap .notice_search_box button {
    width: 34px;

    font-size: 16px;
    font-weight: 700;
  }

  .notice_table_wrap {
    padding: 10px 40px;
  }
  .notice_table_wrap .notice_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .notice_table thead {
    height: 40px;

    font-size: 16px;
    font-weight: 700;
  }
  .notice_table_wrap .notice_table tbody tr {
    height: 40px;
    border-bottom: 1px solid #dcdddd;
    font-size: 14px;
  }

  .notice_table_wrap .notice_table .notice_category_div {
    width: 55px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }

  .notice_table_wrap .notice_table_view .notice_view_title {
    border-bottom: 2px solid #2d2288;
    font-size: 16px;

    padding: 5px 0;
  }
  .notice_table_wrap .notice_table_view .notice_view_title p {
    flex: 1;
    padding-left: 10px;
  }
  .notice_table_wrap .notice_table_view .notice_view_title span {
    width: 180px;
    /* background-color: red; */
    flex: none;
    text-align: right;
    padding-right: 10px;
    /* float: right; */
  }
  .notice_table_wrap .notice_table_view .notice_view_content {
    padding: 16px;
    border-bottom: 2px solid #2d2288;
  }
  .notice_table_wrap .notice_table_view .notice_view_content > div {
    font-size: 14px;
  }
  .notice_table_wrap .notice_re {
    margin-top: 5px;
  }
  .notice_table_wrap .notice_re button {
    font-size: 12px;
    width: 80px;
    height: 24px;
    line-height: 22px;

    /* background-color: red; */
  }

  .table_pagination_delete {
    margin-top: 12px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    text-align: center;
    font-size: 20px;
    margin: 0 4px;
  }

  .notice_table_wrap .qna_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .qna_pag {
    margin-top: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_hd {
    height: 40px;

    font-size: 16px;

    font-weight: 700;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
    text-align: center;
    width: 100px;
  }
  .qna_table_view_cont {
    border-bottom: 1px solid #dcdddd;
    background: #f4f4f4;
    font-weight: 600;
    padding: 5px 0;
    font-size: 14px;
    line-height: 20px;
  }
  .qna_table_view_cont .qna_table_view_ca {
    width: 200px;
    height: auto;
  }
  .notice_table_wrap .qna_table .qna_table_tb {
    height: 40px;
    font-size: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
    border-bottom: 0;
    min-height: 40px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
    width: 100px;
  }

  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
    width: 55px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  }
  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box {
    text-align: center;
    font-size: 24px;
  }
}
@media all and (max-width: 800px) {
  .notice .wrap {
    width: 600px;

    padding: 10px 0 0;
  }
  .notice_box {
  }
  .notice_box .notice_top {
    margin-bottom: 20px;
  }

  .notice_top ul {
    width: 380px;

    height: 38px;
  }
  .notice_top ul li {
    border: 4px solid #f5a21f;

    cursor: pointer;
  }
  .notice_top ul li a {
    font-size: 18px;
  }

  .notice_top div {
    width: 300px;
    border: 4px solid #f5a21f;
    height: 38px;
    border-radius: 8px;

    font-size: 18px;
  }

  .notice_top ul li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .notice_top ul li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .notice_top ul li.color_li a {
  }
  .notice_content_box .notice_content_search {
    padding-right: 40px;
  }
  .notice_content_box .notice_content_search .notice_search_wrap {
    width: 260px;
    height: 36px;
    font-size: 14px;

    border-radius: 10px;
    border: 2px solid #dcdddd;
  }
  .notice_content_search .notice_search_wrap .notice_search_filter {
    height: 36px;
    width: 80px;
    border-right: 2px solid #dcdddd;
  }
  .notice_search_wrap .notice_filter {
    top: 24px;
    left: -1px;
    height: auto;

    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    width: 81px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #dcdddd;
    border-top: 0;
    font-size: 14px;

    /* background-color: red; */
  }

  .notice_search_wrap .notice_search_box .notice_search_inp {
    padding: 0 8px;
  }
  .notice_search_wrap .notice_search_box .notice_search_inp input {
  }

  .notice_search_wrap .notice_search_box button {
    width: 34px;

    font-size: 16px;
    font-weight: 700;
  }

  .notice_table_wrap {
    padding: 10px 40px;
  }
  .notice_table_wrap .notice_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .notice_table thead {
    height: 34px;

    font-size: 14px;
    font-weight: 600;
  }
  .notice_table_wrap .notice_table tbody tr {
    height: 34px;

    border-bottom: 1px solid #dcdddd;
    font-size: 12px;
  }

  .notice_table_wrap .notice_table .notice_category_div {
    width: 50px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }
  .notice_table_wrap .notice_table_view .notice_view_title {
    border-bottom: 2px solid #2d2288;
    font-size: 14px;

    padding: 5px 0;
  }
  .notice_table_wrap .notice_table_view .notice_view_title p {
    flex: 1;
    padding-left: 5px;
  }
  .notice_table_wrap .notice_table_view .notice_view_title span {
    width: 80px;
    /* background-color: red; */
    flex: none;
    text-align: right;
    padding-right: 5px;
    /* float: right; */
  }
  .notice_table_wrap .notice_table_view .notice_view_content {
    padding: 10px;
    border-bottom: 2px solid #2d2288;
  }
  .notice_table_wrap .notice_table_view .notice_view_content > div {
    font-size: 12px;
  }
  .notice_table_wrap .notice_re {
    margin-top: 5px;
  }
  .notice_table_wrap .notice_re button {
    font-size: 10px;
    width: 60px;
    height: 20px;
    line-height: 18px;
  }

  .table_pagination_delete {
    margin-top: 12px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    text-align: center;
    font-size: 20px;
    margin: 0 4px;
  }

  .notice_table_wrap .qna_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .qna_pag {
    margin-top: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_hd {
    height: 34px;
    font-size: 14px;
    font-weight: 600;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
    text-align: center;
    width: 80px;
  }
  .qna_table_view_cont {
    border-bottom: 1px solid #dcdddd;
    background: #f4f4f4;
    font-weight: 600;
    padding: 5px 0;
    font-size: 12px;
    line-height: 16px;
  }
  .qna_table_view_cont .qna_table_view_ca {
    width: 160px;
    height: auto;
  }
  .notice_table_wrap .qna_table .qna_table_tb {
    height: 34px;

    font-size: 12px;
  }
  .notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
    border-bottom: 0;
    min-height: 34px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
    width: 80px;
  }

  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
    width: 50px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  }
  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box {
    text-align: center;
    font-size: 24px;
  }
}

@media all and (max-width: 640px) {
  .notice .wrap {
    width: 100%;
  }
  .notice_box {
  }
  .notice_box .notice_top {
    margin-bottom: 20px;
  }

  .notice_top ul {
    width: 65%;

    height: 40px;
  }
  .notice_top ul li {
    border: 4px solid #f5a21f;
  }
  .notice_top ul li a {
    font-size: 16px;
  }

  .notice_top div {
    width: 50%;
    border: 4px solid #f5a21f;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
  }

  .notice_top ul li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .notice_top ul li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .notice_top ul li.color_li a {
  }
  .notice_content_box .notice_content_search {
    padding-right: 0;
  }
  .notice_content_box .notice_content_search .notice_search_wrap {
    margin: 0 auto;
    width: 260px;
    height: 30px;
    font-size: 12px;

    border-radius: 8px;
    border: 2px solid #dcdddd;
  }
  .notice_content_search .notice_search_wrap .notice_search_filter {
    height: 30px;
    width: 74px;
    border-right: 2px solid #dcdddd;
  }
  .notice_search_wrap .notice_filter {
    top: 24px;
    left: -1px;
    height: auto;

    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    width: 75px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #dcdddd;
    border-top: 0;
    font-size: 12px;

    /* background-color: red; */
  }

  .notice_search_wrap .notice_search_box .notice_search_inp {
    padding: 0 8px;
  }
  .notice_search_wrap .notice_search_box .notice_search_inp input {
    font-size: 12px;
  }

  .notice_search_wrap .notice_search_box button {
    width: 32px;

    font-size: 14px;
    font-weight: 700;
  }

  .notice_table_wrap {
    padding: 10px 45px;
  }
  .notice_table_wrap .notice_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .notice_table thead {
    height: 34px;

    font-size: 14px;
  }
  .notice_table_wrap .notice_table tbody tr {
    height: 34px;

    border-bottom: 1px solid #dcdddd;
    font-size: 12px;
  }

  .notice_table_wrap .notice_table .notice_category_div {
    width: 50px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }

  .notice_table_wrap .notice_table_view .notice_view_title {
    border-bottom: 2px solid #2d2288;
    font-size: 14px;

    padding: 5px 0;
  }
  .notice_table_wrap .notice_table_view .notice_view_title p {
    flex: 1;
    padding-left: 5px;
  }
  .notice_table_wrap .notice_table_view .notice_view_title span {
    width: 80px;
    /* background-color: red; */
    flex: none;
    text-align: right;
    padding-right: 5px;
    /* float: right; */
  }
  .notice_table_wrap .notice_table_view .notice_view_content {
    padding: 10px;
    border-bottom: 2px solid #2d2288;
  }
  .notice_table_wrap .notice_table_view .notice_view_content > div {
    font-size: 12px;
  }
  .notice_table_wrap .notice_re {
    margin-top: 5px;
  }
  .notice_table_wrap .notice_re button {
    font-size: 10px;
    width: 60px;
    height: 20px;
    line-height: 18px;
  }

  .table_pagination_delete {
    margin-top: 12px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    text-align: center;
    font-size: 20px;
    margin: 0 4px;
  }

  .notice_table_wrap .qna_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .qna_pag {
    margin-top: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_hd {
    height: 34px;
    font-size: 14px;
    font-weight: 600;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
    text-align: center;
    width: 70px;
  }
  .qna_table_view_cont {
    border-bottom: 1px solid #dcdddd;
    background: #f4f4f4;
    font-weight: 600;
    padding: 2px 0;
    font-size: 10px;

    line-height: 14px;
  }
  .qna_table_view_cont .qna_table_view_ca {
    width: 140px;
    height: auto;
  }
  .notice_table_wrap .qna_table .qna_table_tb {
    height: 34px;

    font-size: 12px;
  }
  .notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
    border-bottom: 0;
    min-height: 34px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
    width: 70px;
  }

  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
    width: 50px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  }
  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box {
  }
  .table_pagination_delete .table_pagination_delete_box button {
    font-size: 14px;
    /* background-color: red; */
    margin: 0 5px !important;
  }
}

@media all and (max-width: 540px) {
  .notice .wrap {
    width: 100%;
  }
  .notice_box {
  }
  .notice_box .notice_top {
    margin-bottom: 20px;
  }

  .notice_top ul {
    width: 65%;

    height: 36px;
  }
  .notice_top ul li {
    border: 3px solid #f5a21f;
  }
  .notice_top ul li a {
    font-size: 14px;
  }

  .notice_top div {
    width: 50%;
    border: 3px solid #f5a21f;
    height: 36px;
    border-radius: 8px;
    font-size: 14px;
  }

  .notice_top ul li:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .notice_top ul li:last-child {
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .notice_top ul li.color_li a {
  }
  .notice_content_box .notice_content_search {
    padding-right: 0;
  }
  .notice_content_box .notice_content_search .notice_search_wrap {
    margin: 0 auto;
    width: 240px;
    height: 28px;
    font-size: 12px;

    border-radius: 8px;
    border: 2px solid #dcdddd;
  }
  .notice_content_search .notice_search_wrap .notice_search_filter {
    height: 28px;
    width: 68px;
    border-right: 2px solid #dcdddd;
  }
  .notice_search_wrap .notice_filter {
    top: 20px;
    left: -2px;
    height: auto;

    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    width: 70px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #dcdddd;
    border-top: 0;
    font-size: 12px;

    /* background-color: red; */
  }

  .notice_search_wrap .notice_search_box .notice_search_inp {
    padding: 0 8px;
  }
  .notice_search_wrap .notice_search_box .notice_search_inp input {
    font-size: 12px;
  }

  .notice_search_wrap .notice_search_box button {
    width: 30px;

    font-size: 14px;
    font-weight: 700;
  }

  .notice_table_wrap {
    padding: 10px 45px;
  }
  .notice_table_wrap .notice_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .notice_table thead {
    height: 30px;
    font-size: 13px;
    font-weight: 700;
  }
  .notice_table_wrap .notice_table tbody tr {
    height: 30px;

    border-bottom: 1px solid #dcdddd;
    font-size: 11px;
  }

  .notice_table_wrap .notice_table .notice_category_div {
    width: 50px;
    height: 22px;

    border-radius: 12px;
    line-height: 20px;
  }
  .table_pagination_delete {
    margin-top: 12px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    text-align: center;
    font-size: 20px;
    margin: 0 4px;
  }

  .notice_table_wrap .qna_table {
    border: 2px solid #2d2288;
    border-left: 0;
    border-right: 0;
  }
  .notice_table_wrap .qna_pag {
    margin-top: 14px;
  }
  .notice_table_wrap .qna_table .qna_table_hd {
    height: 30px;
    font-size: 13px;
    font-weight: 700;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca,
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_div {
    text-align: center;
    width: 70px;
  }
  .notice_table_wrap .qna_table .qna_table_hd .qna_table_hd_ca {
    width: 50px;
  }
  .qna_table_view_cont {
    border-bottom: 1px solid #dcdddd;
    background: #f4f4f4;
    font-weight: 600;
    padding: 2px 0;
    font-size: 10px;
    line-height: 14px;
  }
  .qna_table_view_cont .qna_table_view_ca {
    width: 120px;
    height: auto;
  }
  .notice_table_wrap .qna_table .qna_table_tb {
    height: 30px;
    font-size: 11px;
  }
  .notice_table_wrap .qna_table .qna_table_tb.qna_table_view {
    border-bottom: 0;
    min-height: 30px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca,
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div {
    width: 60px;
  }

  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_ca > span {
    width: 40px;
    height: 20px;

    font-size: 10px;
    border-radius: 12px;
    line-height: 18px;
  }
  .notice_table_wrap .qna_table .qna_table_tb .qna_table_tb_div > span {
  }
  .table_pagination_delete {
    margin-top: 14px;
  }
  .table_pagination_delete .table_pagination_delete_box button {
    font-size: 14px;
    /* background-color: red; */
    margin: 0 5px !important;
  }
}
