@charset "UTF-8";

/* rowgroup */
.rowgroup {
}
.temp_calendar .top_box {
  margin-top: 50px;
  margin-bottom: 60px;
}

.shortcut {
  float: right;
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-delay: 300ms;
}
.shortcut.scroll_active {
  transform: translateY(0px);
  opacity: 1;
}
.shortcut_list {
}
.shortcut_item {
  display: inline-block;
  position: relative;
  margin: 0 15px;
  vertical-align: top;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  text-align: center;
}
a.shortcut_anchor {
  display: block;
  position: relative;
  width: 90px;
  height: 90px;
  padding-top: 38px;
  border-radius: 100%;
  background-color: #3fb3e6;
  color: #fff;
  font-family: "recoFont";
}
.shortcut_item.n2 {
  font-size: 16px;
}
.shortcut_item.n1 .shortcut_anchor {
  background-color: #3fb3e6;
}
.shortcut_item.n2 .shortcut_anchor {
  padding-top: 25px;
  background-color: #abcf4b;
}
.shortcut_item.n3 .shortcut_anchor {
  background-color: #e7311b;
}
.shortcut_item .shortcut_anchor:before {
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border: 3px solid #fff;
  border-radius: 100%;
}
.shortcut_item .shortcut_anchor:after {
  display: block;
  position: absolute;
  top: -5px;
  right: 0;
  background-image: url(../assets/images/main_sprite.png);
  background-repeat: no-repeat;
  background-position: left -101px;
}
.shortcut_item.n1 .shortcut_anchor:after {
  width: 30px;
  height: 30px;
  background-position: left -184px;
}
.shortcut_item.n2 .shortcut_anchor:after {
  width: 28px;
  height: 30px;
  background-position: left -144px;
}
.shortcut_item.n3 .shortcut_anchor:after {
  width: 34px;
  height: 33px;
  background-position: left -101px;
}

.temp_calendar h2 {
  display: inline-block;
  position: relative;
  padding-left: 55px;
  font-size: 30px;
  line-height: 44px;
  color: #000;
  font-family: "recoFont";
  font-weight: 400;
  vertical-align: top;
}
.temp_calendar h2:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 31px;
  background: url(../assets/images/calendar_title_bg.png) center no-repeat;
}
.temp_calendar .calendar_subtitle {
  margin-left: 25px;
  margin-top: 3px;
  font-size: 28px;
  line-height: 32px;
  color: #241917;
  vertical-align: top;
  font-family: "KopubDotum";
}
.temp_calendar .em_sky {
  color: #40b2e8;
}
.temp_calendar .calendar_btn_box {
  display: inline-block;
  margin-top: -5px;
  margin-left: 60px;
  vertical-align: top;
}
.temp_calendar .calendar_btn {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  padding: 8px 15px 8px 50px;
  border: 3px solid #3fb3e6;
  border-radius: 25px;
  font-size: 23px;
  line-height: 25px;
  color: #241917;
  vertical-align: top;
  font-family: "KopubDotum";
  transition: box-shadow 0.3s linear;
}
.temp_calendar .calendar_btn:before {
  display: block;
  position: absolute;
  top: 8px;
  left: 15px;
  background-repeat: no-repeat;
}
.temp_calendar .calendar_btn.timetable:before {
  width: 29px;
  height: 26px;
  background-image: url(../assets/images/main_sprite.png);
  background-position: left top;
}
.temp_calendar .calendar_btn.plan:before {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-image: url(../assets/images/main_sprite.png);
  background-position: left -36px;
}
.temp_calendar .calendar_btn:hover {
  box-shadow: 0px 5px 4.8px 0.2px rgba(213, 209, 208, 1);
}
.temp_calendar .month_box {
  display: inline-block;
  position: relative;
  height: 90px;
  padding-left: 120px;
  padding-right: 25px;
  border-radius: 55px;
  background-color: #f5a220;
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}
.temp_calendar .month_box.scroll_active {
  transform: translateY(0px);
  opacity: 1;
}
.temp_calendar .month_box:before {
  display: block;
  position: absolute;
  top: -25px;
  left: -78px;
  width: 200px;
  height: 117px;
  background: url(../assets/images/calendar_bg.png) center no-repeat;
}
.temp_calendar .month_box:after {
  display: block;
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  width: 30px;
  height: 13px;
  margin: 0 auto;
  background: url(../assets/images/month_box_arrow.png) center no-repeat;
}
.temp_calendar .cont_btn {
  display: inline-block;
  padding: 35px 5px 20px;
  vertical-align: top;
}
.temp_calendar .cont_btn button {
  display: block;
  position: relative;
  width: 35px;
  height: 35px;
  font-size: 0;
  line-height: 0;
}
.temp_calendar .cont_btn button:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 19px;
  background-image: url(../assets/images/main_sprite.png);
  background-repeat: no-repeat;
  background-position: left -71px;
  transform: rotate(90deg);
}
.temp_calendar .cont_btn button.prev:before {
  transform: rotate(-90deg);
}
.temp_calendar .cont_btn button.next:before {
  transform: rotate(90deg);
}
.temp_calendar .month_list {
  display: inline-block;
  vertical-align: top;
}
.temp_calendar .month_item {
  display: inline-block;
  padding: 32px 0 23px;
  vertical-align: top;
}
.temp_calendar .month_anchor {
  padding: 0 15px;
  font-size: 28px;
  line-height: 35px;
  color: #fff;
  font-family: "recoFont";
}
.temp_calendar .month_anchor span {
  display: none;
}
.temp_calendar .month_item.active .month_anchor {
  position: relative;
  padding: 0 5px;
  text-align: center;
}
.temp_calendar .month_item.active .month_anchor span {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  color: #f5a220;
  font-size: 27px;
  line-height: 30px;
  vertical-align: top;
  font-family: "KopubDotum";
}
.temp_calendar .month_item.active .month_anchor em {
  position: relative;
  top: -11px;
  width: 45px;
  height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #f5a220;
  text-align: center;
  vertical-align: top;
  line-height: 55px;
}
.temp_calendar .month_item.active .month_anchor:before {
  display: block;
  position: absolute;
  top: -17px;
  left: -10px;
  width: calc(100% + 13px);
  height: calc(100% + 10px);
  border-radius: 45px;
  background-color: #fff;
}

.calendar_list {
  margin: 35px -25px 0;
}
.calendar_list .slick-slide {
  margin: 0 25px;
  padding-bottom: 8px;
}
.calendar_item {
  display: inline-block;
  position: relative;
  height: 490px;
  vertical-align: top;
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-delay: 600ms;
}
.calendar_item.scroll_active {
  transform: translateY(0px);
  opacity: 1;
}
.calendar_item:before {
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: #d4d0cf;
}
.calendar_anchor {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 8px;
  overflow: hidden;
}
.calendar_anchor.n1 .calendar_inner {
  background-color: #3fb3e6;
}
.calendar_anchor.n2 .calendar_inner {
  background-color: #abcf4b;
}
.calendar_anchor.n3 .calendar_inner {
  background-color: #f5a220;
}
.calendar_inner {
  position: relative;
}
.calendar_inner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: #3fb3e6;
  z-index: 0;
}
.calendar_inner:before {
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100% - 35px);
  height: calc(100% - 35px);
  border: 3px solid #fff;
  border-radius: 25px;
  z-index: -1;
}
.calendar_inner:after {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  animation-name: left-deco-ani2;
  animation-duration: 2000ms;
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 1;
  transform: scale(0.5);
}
.calendar_anchor.n1 .calendar_inner:after {
  right: 11px;
  width: 264px;
  height: 252px;
  background-image: url(../assets/images/edu_icon01.png);
}
.calendar_anchor.n2 .calendar_inner:after {
  width: 314px;
  height: 229px;
  background-image: url(../assets/images/edu_icon02.png);
  animation-delay: 200ms;
  animation-name: left-deco-ani1;
}
.calendar_anchor.n3 .calendar_inner:after {
  width: 293px;
  height: 248px;
  background-image: url(../assets/images/edu_icon03.png);
  animation-delay: 300ms;
}

.temp_calendar .month_item {
  display: inline-block;
  vertical-align: top;
}
.temp_calendar .c_num {
  display: block;
  position: absolute;
  top: -8px;
  left: 42px;
  width: 54px;
  height: 64px;
  padding-top: 17px;
  background: url(../assets/images/edu_num.png) center no-repeat;
  background-size: cover;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  font-family: "KopubDotum";
  font-weight: 600;
}
.temp_calendar .c_title {
  padding-top: 58px;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  font-family: "recoFont";
  word-break: keep-all;
}
.temp_calendar .c_text {
  padding: 35px 40px;
  font-size: 25px;
  line-height: 28px;
  font-family: "KopubDotum";
  font-weight: 600;
  color: #fff;
}
.temp_calendar .c_text em {
  display: block;
}
.temp_calendar .c_text em.em_yellow {
  color: #fff241;
}
.temp_calendar .edu_status {
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: all 0.3s linear;
}
.temp_calendar .edu_status.n1 {
  background-color: #2d228a;
}
.temp_calendar .edu_status.n2 {
  background-color: #fff;
}
.temp_calendar .edu_status span {
  position: relative;
  padding: 0 10px;
  font-size: 20px;
  line-height: 25px;
  font-family: "recoFont";
  color: #fff;
}
.temp_calendar .edu_status.n2 span {
  color: #2d228a;
}
.temp_calendar .page {
  display: block;
  margin-top: 10px;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  font-family: "KopubDotum";
  font-weight: 600;
}
.temp_calendar .slick-dots {
  margin: 20px 0;
  text-align: center;
}
.temp_calendar .slick-dots li {
  display: inline-block;
  vertical-align: top;
}
.temp_calendar .slick-dots li button {
  margin: 0 7px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #a0a0a0;
  font-size: 0;
}
.temp_calendar .slick-dots li.slick-active button {
  background-color: #f4a023;
}

/* 마우스오버 */
.calendar_anchor:hover {
}
.temp_calendar .calendar_anchor:hover .edu_status {
  transform: scale(1.1);
}

@keyframes left-deco-ani1 {
  0% {
    transform: rotate(1deg) translateY(0px);
  }
  50% {
    transform: rotate(3deg) translateX(5%);
  }
  100% {
    transform: rotate(0deg) translateY(0px);
  }
}

@keyframes left-deco-ani2 {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  50% {
    transform: rotate(3deg) translateY(5%);
  }
  100% {
    transform: rotate(-1deg) translateY(0px);
  }
}
@media all and (max-width: 1300px) {
  .shortcut_item {
    margin: 5px;
  }
  .temp_calendar .month_anchor {
    padding: 0 10px;
  }
  .temp_calendar .calendar_btn_box {
    margin-left: 10px;
  }

  .calendar_anchor .calendar_inner:after {
    background-size: cover;
  }
  .calendar_anchor.n1 .calendar_inner:after {
    width: 220px;
    height: 210px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    width: 220px;
    height: 160px;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    width: 220px;
    height: 186px;
  }
}
@media all and (max-width: 1000px) {
  .temp_calendar .top_box {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .shortcut {
    margin-bottom: 25px;
  }
  .temp_calendar .month_box {
    width: 100%;
    padding-left: 25px;
  }
  .temp_calendar .month_box:before {
    top: -100px;
    left: -40px;
    transform: scale(0.7);
  }
  .temp_calendar .month_list {
    width: calc(100% - 100px);
    text-align: center;
  }
  .temp_calendar .month_item {
    width: 11%;
    text-align: center;
  }
  .temp_calendar .month_item.active {
    width: auto;
  }
  .temp_calendar .calendar_btn_box {
    display: block;
    margin-top: 10px;
    text-align: right;
  }
  .calendar_list .slick-slide {
    margin: 0 15px;
  }
  .temp_calendar .c_title {
    padding: 58px 10px 0;
  }
}
@media all and (max-width: 640px) {
  .shortcut {
    float: none;
    text-align: center;
  }
  .shortcut_item {
    font-size: 14px;
    line-height: 18px;
  }
  a.shortcut_anchor {
    width: 65px;
    height: 65px;
    padding-top: 26px;
  }
  .shortcut_item.n2 {
    font-size: 13px;
  }
  .shortcut_item.n2 .shortcut_anchor {
    padding-top: 16px;
  }
  .temp_calendar .month_item.active .month_anchor:before {
    top: -7px;
    left: -2px;
    width: 100%;
    height: calc(100% + 7px);
  }
  .shortcut_item .shortcut_anchor:after {
    top: -8px;
    right: -5px;
    transform: scale(0.7);
  }

  .temp_calendar .month_box {
    height: 60px;
    padding: 0;
  }
  .temp_calendar .month_box:before {
    display: none;
  }
  .temp_calendar .month_list {
    width: calc(100% - 76px);
  }
  .temp_calendar .month_item {
    width: auto;
    padding: 19px 4px 13px;
  }
  .temp_calendar .month_anchor {
    padding: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .temp_calendar .month_item.active .month_anchor em {
    top: -3px;
    width: 28px;
    height: 28px;
    line-height: 32px;
  }
  .temp_calendar .month_item.active .month_anchor span {
    font-size: 16px;
    line-height: 24px;
  }
  .temp_calendar .cont_btn button.prev:before {
    transform: scale(0.8) rotate(-90deg);
  }
  .temp_calendar .cont_btn button.next:before {
    transform: scale(0.8) rotate(90deg);
  }
  .temp_calendar .cont_btn {
    padding: 21px 0 4px;
  }

  .temp_calendar h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .temp_calendar h2:before {
    top: -5px;
  }
  .calendar_list .slick-list {
    margin: 0 -8px;
  }
  .calendar_list .slick-slide {
    margin: 0 8px;
  }
  .calendar_item {
    height: 370px;
  }
  .calendar_anchor.n1 .calendar_inner:after {
    width: 160px;
    height: 153px;
  }
  .calendar_anchor.n2 .calendar_inner:after {
    width: 160px;
    height: 116px;
  }
  .calendar_anchor.n3 .calendar_inner:after {
    width: 160px;
    height: 135px;
  }
  .temp_calendar .calendar_subtitle {
    display: block;
    margin-left: 0;
    margin-top: 5px;
    font-size: 20px;
    line-height: 24px;
  }
  .temp_calendar .calendar_btn_box {
    margin-left: 0;
    text-align: left;
  }
  .temp_calendar .calendar_btn {
    margin: 0;
    padding: 5px 7px 5px 30px;
    font-size: 18px;
    line-height: 22px;
  }
  .temp_calendar .calendar_btn.timetable {
    margin-right: 5px;
  }
  .temp_calendar .calendar_btn:before {
    top: 4px;
    left: 2px;
    transform: scale(0.7);
  }
  .calendar_list {
    margin: 15px 0 0;
  }
  .temp_calendar .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 5px;
  }
  .temp_calendar .c_title {
    font-size: 32px;
    line-height: 38px;
  }
  .temp_calendar .c_text {
    padding: 20px 40px;
    font-size: 16px;
    line-height: 22px;
  }
  .temp_calendar .page {
    margin-top: 5px;
    font-size: 18px;
    line-height: 22px;
  }
  .temp_calendar .edu_status {
    width: 65px;
    height: 65px;
  }
  .temp_calendar .edu_status.n2 span {
    padding: 0;
  }
  .temp_calendar .edu_status span {
    font-size: 16px;
    line-height: 22px;
  }
}

/* 인트로 */
.intro {
  width: 100%;
  height: 100%;
  background: url(../assets/images/bg.png) center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: relative;
}
.intro_bg_1 {
  position: absolute;
  background: url(../assets/images/leftT.png) no-repeat;
  background-size: 100%;
  width: 458px;
  height: 291px;
  left: 0;
  top: 0;
}

.intro_bg_2 {
  position: absolute;
  background: url(../assets/images/rightT.png) no-repeat;
  background-size: 100%;
  width: 519px;
  height: 309px;
  right: 0;
  top: 0;
}

.intro_bg_3 {
  position: absolute;
  background: url(../assets/images/leftB.png) no-repeat;
  background-size: 100%;
  width: 309px;
  height: 326px;
  left: 0;
  bottom: 0;
}

.intro_bg_4 {
  position: absolute;
  background: url(../assets/images/rightB.png) no-repeat;
  background-size: 100%;
  width: 439px;
  height: 312px;
  right: 0;
  bottom: 0;
}
.intro .wrap {
  width: 1600px;
  height: auto;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.intro_box {
  font-size: 0;
  line-height: 0;
  width: 100%;
  height: auto;
}
.intro_item {
  position: relative;

  width: 100%;
  height: 100%;
  text-align: center;
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
}
.intro_item a {
  width: 100%;
}
.intro_item img {
  width: 100%;
}
.intro_item.scroll_active {
  transform: translateY(0px);
  opacity: 1;
  padding-top: 0;
}

.intro_item.n2.scroll_active {
  transition-delay: 300ms;
}
.intro_item.n3.scroll_active {
  transition-delay: 600ms;
}

.intro_inner {
  position: relative;
  width: 500px;
  height: 500px;
  text-align: center;
  border-radius: 100%;
  background-color: #f8f8f8;
  vertical-align: top;
  overflow: hidden;
}
.intro_anchor {
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  margin: 18px;
  border: 3px solid #efefef;
  border-radius: 100%;
  background-color: #fefefe;
  z-index: 1;
}
.intro_anchor:before {
  display: block;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.intro_item .top_bg {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 112px;
  height: 130px;
  margin: 0 auto;
  padding-top: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 22px;
  line-height: 24px;
  font-family: "recoFont";
  color: #595757;
  z-index: 1;
}

.inner_box {
  position: relative;
  height: 100%;
  padding-top: 80px;
  z-index: 1;
}
.intro_btn_box {
  position: absolute;
  top: calc(100% - 65px);
  left: 0;
  right: 0;
  width: 350px;
  height: 350px;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #3eb4e6;
}
.intro_item.n1 .intro_btn_box {
  background-color: #3fb3e6;
}
.intro_item.n2 .intro_btn_box {
  background-color: #abcf4b;
}
.intro_item.n3 .intro_btn_box {
  background-color: #f5a220;
}
.intro_btn {
  padding-top: 45px;
  font-size: 21px;
  color: #fff;
  font-family: "recoFont";
}

.intro_bottom {
  margin: 0 auto;
  width: 1040px;
  /* margin-top: 80px; */
  /* padding-top: 200px; */
  padding-bottom: 54px;
  transform: translateY(50px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-delay: 900ms;
}
.intro_bottom.scroll_active {
  transform: translateY(0px);
  opacity: 1;
}
.intro_bottom .go_item {
  text-align: center;
  /* background-color: black; */
}
.intro_bottom .intro_go {
  /* position: relative; */
  width: 100%;
}

.intro_bottom .em_green {
  margin: 0 10px;
  color: #23a339;
}
.intro_bottom span {
  font-size: 20px;
  line-height: 30px;
  color: #727171;
  font-family: "KopubDotum";
}

.list_left_arrow_div {
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 1;
  /* background-color: wheat; */
  left: -130px;
  top: 50%;
}
.list_right_arrow_div {
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 1;
  /* background-color: wheat; */
  right: -130px;
  top: 50%;
}
.intro_box svg {
  cursor: pointer;
  color: rgb(45, 34, 136);
  width: 150px;
  height: 150px;
}

/* 회원정보 */
.info {
  width: 100%;
  height: 100%;
}

.info .wrap {
  width: 580px;
  margin: 0 auto;
  height: auto;
}
.info_box {
  line-height: auto;
  width: 100%;
  height: auto;
}
.info_box .info_hd {
  width: 100%;
  height: 100px;
  background-color: #40b3e6;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.info_box .info_ft {
  width: 100%;
  height: 60px;
  background-color: #40b3e6;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  cursor: pointer;
}
.info_box .info_ft_ex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
}
.info_box > div {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
}
.info_box > div:nth-child(2),
.info_box > div:nth-child(4) {
  margin-bottom: 0;
  /* background-color: RED; */
}
.info_box > div > ul {
  border: 2px solid #dcdfe0;
}
.info_box > div > ul input {
  border: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-indent: 10px;
}
.info_box > div > ul input:focus {
  outline: 0;
}
.info_box > div > ul > li {
  height: 55px;
  border-bottom: 2px solid #dcdfe0;
  padding: 0 25px;
}
.info_box > div > ul > li:last-child {
  border-bottom: 0;
}
.info_box .info_ex {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-weight: 600;
}
.info_box .info_ex_view {
  color: #c72b04;
  height: auto;
}
.info_box .info_ex2 {
  width: 100%;
  height: 30px;

  font-weight: 600;
}
.info_box .info_ex2_view {
  padding: 5px 0;
  height: auto;
}
.info_box .info_ex3 {
  width: 100%;
  height: auto;

  font-weight: 600;
  color: #c72b04;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.info_box .info_ex3_view {
  height: auto;
}
.info_box .info_ex svg {
  font-size: 22px;
}
.info_one li:first-child {
  background-color: #dff2fc;
  display: flex;
  align-items: center;
}
.info_two {
  margin-bottom: 0 !important;
}
.info_two > ul > li:nth-child(2) {
  display: flex;
}
.info_two > ul > li:nth-child(2) > div {
  flex: 1;
}
.info_three > ul > li:first-child {
  display: flex;
}
.info_three > ul > li:first-child {
  padding-right: 0;
}
.info_three > ul > li:first-child > div:first-child {
  flex: 1;
}
.info_three > ul > li:first-child > div:last-child {
  background-color: #40b3e6;
  width: 190px;
  height: 100%;
  color: #fff;
  text-align: center;
  line-height: 55px;
  flex: none;
  cursor: pointer;
}
.info_box li input:disabled {
  background-color: transparent;
}
.info_four li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.info_four li svg {
  color: #40b3e6;
  font-size: 24px;
}
.info_four li svg.click {
  opacity: 0.5;
}
.info_address_btn_box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}
.info_address_btn {
  width: 60px;
  height: 32px;
  background-color: #40b2e8;
  text-align: center;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
@media all and (max-width: 1870px) {
  .intro .wrap {
    width: 1420px;
    /* background-color: blue; */
  }
  .intro_box {
    margin: 0;
    text-align: center;
  }
  .intro_item {
    /* width: 33.3%; */
    /* height: 548.84310618067px; */
    margin: 0;
  }
  .intro_bottom {
    width: 923px;
    padding-bottom: 40px;
  }
}
@media all and (max-width: 1710px) {
  .intro .wrap {
    width: 1300px;
    /* background-color: blue; */
  }
  .intro_box {
    margin: 0;
    text-align: center;
  }
  .intro_item {
    /* width: 33.3%; */
    /* height: 548.84310618067px; */
    margin: 0;
  }
  .intro_bottom {
    width: 845px;
    padding-bottom: 40px;
  }

  .list_left_arrow_div {
    width: 130px;
    height: 130px;

    left: -130px;
    top: 50%;
  }
  .list_right_arrow_div {
    width: 130px;
    height: 130px;

    right: -130px;
    top: 50%;
  }
  .intro_box svg {
    width: 130px;
    height: 130px;
  }
}
@media all and (max-width: 1600px) {
  .intro .wrap {
    width: 1100px;
  }

  .intro_bg_1 {
    width: 381.66666666672px;
    height: 242.5000000000339px;
  }

  .intro_bg_2 {
    width: 432.5px;
    height: 257.5px;
  }

  .intro_bg_3 {
    width: 257.5px;
    height: 271.6666666666667px;
  }

  .intro_bg_4 {
    width: 365.83333333328px;
    height: 259.9999999999621px;
  }
  .intro_box {
    margin: 0;
    text-align: center;
  }
  .intro_item {
  }

  .intro_inner {
    width: 370px;
    height: 370px;
    margin: 0 auto;
  }
  .intro_title {
    height: 125px;
  }
  .intro_anchor:before {
    bottom: 5px;
    transform: scale(0.6);
  }
  .intro_item.n2 .intro_anchor:before {
    bottom: -20px;
  }
  .intro_item.n3 .intro_anchor:before {
    bottom: 10px;
  }
  .intro_btn_box {
    top: calc(100% - 35px);
    width: 300px;
    height: 300px;
  }
  .intro_btn {
    padding-top: 31px;
  }
  .intro_bottom {
    width: 715px;
  }
}
@media all and (max-width: 1500px) {
  .intro .wrap {
    width: 1050px;
  }

  .intro_box {
    margin: 0;
    text-align: center;
  }
  .intro_item {
  }

  .intro_inner {
    width: 370px;
    height: 370px;
    margin: 0 auto;
  }
  .intro_title {
    height: 125px;
  }
  .intro_anchor:before {
    bottom: 5px;
    transform: scale(0.6);
  }
  .intro_item.n2 .intro_anchor:before {
    bottom: -20px;
  }
  .intro_item.n3 .intro_anchor:before {
    bottom: 10px;
  }
  .intro_btn_box {
    top: calc(100% - 35px);
    width: 300px;
    height: 300px;
  }
  .intro_btn {
    padding-top: 31px;
  }
  .intro_bottom {
    width: 715px;
  }
  .list_left_arrow_div {
    width: 100px;
    height: 100px;

    left: -110px;
    top: 50%;
  }
  .list_right_arrow_div {
    width: 100px;
    height: 100px;

    right: -110px;
    top: 50%;
  }
  .intro_box svg {
    width: 100px;
    height: 100px;
  }
}
@media all and (max-width: 1400px) {
  .intro .wrap {
    width: 1025px;
  }
}

@media all and (max-width: 1300px) {
  .intro_box {
    /* padding-top: 150px; */
  }
  .intro .wrap {
    width: 900px;
  }

  .intro_bg_1 {
    width: 310.10416666665px;
    height: 197.0312499999894px;
  }

  .intro_bg_2 {
    width: 351.40624999998px;
    height: 209.2187499999881px;

    /* background-color: red; */
  }

  .intro_bg_3 {
    width: 209.21874999999px;
    height: 220.7291666666561px;

    /* background-color: red; */
  }

  .intro_bg_4 {
    width: 297.23958333332px;
    height: 211.2499999999905px;
  }
  .intro_item {
  }
  .intro_item.scroll_active {
    /* transform: translateY(0px) scale(0.7); */
  }
  .intro_item .top_bg {
    width: 100%;
    left: 17px;
  }
  .intro_bottom .intro_go {
  }
  .intro_bottom {
    width: 550px;
  }
}
@media all and (max-width: 1200px) {
  .intro .wrap {
    width: 780px;
  }
  .list_left_arrow_div {
    width: 100px;
    height: 100px;

    left: -100px;
  }
  .list_right_arrow_div {
    width: 100px;
    height: 100px;

    right: -100px;
  }
  .intro_box svg {
    width: 100px;
    height: 100px;
  }
  /* 회원정보 */
  .info {
    width: 100%;
    height: 100%;
  }

  .info .wrap {
    width: 420px;
    margin: 0 auto;
    height: auto;
  }
  .info_box {
    line-height: auto;
    width: 100%;
    height: auto;
  }
  .info_box .info_hd {
    width: 100%;
    height: 70px;

    font-size: 24px;
  }
  .info_box .info_ft {
    width: 100%;
    height: 40px;
  }
  .info_box .info_ft_ex {
    font-size: 14px;
  }
  .info_box > div {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
  }

  .info_box > div > ul {
    border: 2px solid #dcdfe0;
  }
  .info_box > div > ul input {
    text-indent: 6px;
  }
  .info_box > div > ul input:focus {
    outline: 0;
  }
  .info_box > div > ul > li {
    height: 46px;
    border-bottom: 2px solid #dcdfe0;
    padding: 0 18px;
  }

  .info_box .info_ex {
    padding: 3px 0;
    font-size: 13px;
  }
  .info_box .info_ex_view {
    color: #c72b04;
    height: auto;
  }
  .info_box .info_ex2 {
    width: 100%;
    height: 24px;
    font-size: 13px;
    font-weight: 600;
  }
  .info_box .info_ex2_view {
    padding: 3px 0;
    height: auto;
  }
  .info_box .info_ex3 {
    font-size: 13px;
    padding-bottom: 3px;
  }
  .info_box .info_ex3_view {
  }
  .info_box .info_ex svg {
    font-size: 14px;
  }

  .info_three > ul > li:first-child > div:last-child {
    width: 120px;

    line-height: 45px;
  }

  .info_four li svg {
    font-size: 18px;
  }
  .info_four li svg.click {
    opacity: 0.5;
  }
  .info_address_btn_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
  }
  .info_address_btn {
    width: 60px;
    height: 32px;
    background-color: #40b2e8;
    text-align: center;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
  }
}
@media all and (max-width: 1000px) {
  .intro {
  }
  .intro_box {
  }
  .intro .wrap {
    width: 640px;
  }

  .intro_bg_1 {
    width: 238.54166666665px;
    height: 151.56249999998943px;
  }

  .intro_bg_2 {
    width: 270.31249999998px;
    height: 160.9374999999881px;

    /* background-color: red; */
  }

  .intro_bg_3 {
    width: 160.93749999999px;
    height: 169.7916666666561px;

    /* background-color: red; */
  }

  .intro_bg_4 {
    width: 228.64583333332px;
    height: 162.4999999999905px;

    /* background-color: red; */
  }
  .inner_box {
    /* padding-top: 60px; */
  }
  .intro_inner {
    width: 320px;
    height: 320px;
  }
  .intro_item {
    /* width: 320px; */
    /* height: 329.3058637084px; */
  }
  .intro_item .top_bg {
    width: 300px;
    transform: scale(0.8);
  }
  .intro_anchor:before {
    bottom: -10px;
  }
  .intro_item.n2 .intro_anchor:before {
    bottom: -28px;
  }
  .intro_item.n3 .intro_anchor:before {
    bottom: -5px;
  }
  .intro_title {
    height: 95px;
  }
  .intro_btn_box {
    top: calc(100% - 26px);
    width: 150px;
    height: 150px;
  }
  .intro_btn {
    padding-top: 28px;
    font-size: 18px;
  }

  .intro_bottom {
    width: 550px;
    padding-bottom: 10px;
  }
  .intro_bottom .intro_go {
    padding: 28px 45px 22px 90px;
    font-size: 20px;
    line-height: 25px;
  }
  .intro_bottom .intro_go:before {
    top: 0;
    left: 10px;
    transform: scale(0.8);
  }
  .intro_bottom .intro_go:after {
    top: 26px;
    right: 20px;
    transform: scale(0.8);
  }
  .list_left_arrow_div {
    width: 80px;
    height: 80px;

    left: -80px;
    top: 50%;
  }
  .list_right_arrow_div {
    width: 80px;
    height: 80px;

    right: -80px;
    top: 50%;
  }
  .intro_box svg {
    width: 80px;
    height: 80px;
  }
}

@media all and (max-width: 800px) {
  .inner_box {
    /* padding-top: 60px; */
  }
  .intro .wrap {
    width: 540px;
  }
  .intro_bg_1 {
    width: 190.83333333335px;
    height: 121.25000000001059px;

    /* background-color: red; */
  }

  .intro_bg_2 {
    width: 216.25000000002px;
    height: 128.7500000000119px;

    /* background-color: red; */
  }

  .intro_bg_3 {
    width: 128.75000000001px;
    height: 135.8333333333439px;

    /* background-color: red; */
  }

  .intro_bg_4 {
    width: 182.91666666668px;
    height: 130.00000000000946px;

    /* background-color: red; */
  }
  .intro_inner {
    width: 260px;
    height: 260px;
  }
  .intro_item {
    /* width: 260px; */
    /* height: 261.74326465927px; */
  }
  .intro_item .top_bg {
    width: 225px;
    /* transform: scale(0.8); */
  }
  .intro_anchor:before {
    bottom: -20px;
    /* transform: scale(0.5); */
  }
  .intro_item.n2 .intro_anchor:before {
    bottom: -33px;
  }
  .intro_item.n3 .intro_anchor:before {
    bottom: -15px;
  }
  .intro_title {
    height: 75px;
  }
  .intro_bottom {
    width: 403px;
  }
  .list_left_arrow_div {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1;
    /* background-color: wheat; */
    left: -50px;
  }
  .list_right_arrow_div {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 1;
    /* background-color: wheat; */
    right: -50px;
  }
  .intro_box svg {
    width: 50px;
    height: 50px;
  }
}

@media all and (max-width: 640px) {
  .intro {
    display: flex;

    /* overflow: auto; */
  }
  .intro .wrap {
    width: 100%;
    /* overflow: hidden; */
  }
  .intro_bottom {
    width: 89.375%;
    margin: 0;
  }
  .intro_box {
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .intro_item {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* display: block;
    width: 100%;
    margin-top: -70px; */
    /* width: 52.4328125%; */
    /* height: auto; */
    /* 53.125 */
  }
  .intro_item .top_bg {
    left: 5px;
    transform: scale(0.7);
  }
  .list_left_arrow_div {
    width: 60px;
    height: 60px;

    left: 25px;
    top: 60%;
  }
  .list_right_arrow_div {
    width: 60px;
    height: 60px;

    right: 25px;
    top: 60%;
  }
  .intro_box svg {
    width: 60px;
    height: 60px;
  } /* 회원정보 */
  .info {
  }

  .info .wrap {
    width: 380px;
  }
  .info_box {
    width: 100%;
  }
  .info_box .info_hd {
    height: 55px;

    font-size: 20px;
  }
  .info_box .info_ft {
    width: 100%;
    height: 35px;
    font-size: 14px;
  }
  .info_box .info_ft_ex {
    font-size: 12px;
  }
  .info_box > div {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .info_box > div > ul {
    border: 2px solid #dcdfe0;
  }
  .info_box > div > ul input {
    text-indent: 4px;
  }
  .info_box > div > ul input:focus {
    outline: 0;
  }
  .info_box > div > ul > li {
    height: 40px;
    border-bottom: 2px solid #dcdfe0;
    padding: 0 12px;
  }

  .info_box .info_ex {
    padding: 3px 0;
    font-size: 12px;
  }
  .info_box .info_ex_view {
    color: #c72b04;
    height: auto;
  }
  .info_box .info_ex2 {
    height: 20px;
    font-size: 12px;
  }
  .info_box .info_ex2_view {
    padding: 3px 0;
    height: auto;
  }
  .info_box .info_ex3 {
    font-size: 13px;
    padding-bottom: 3px;
  }
  .info_box .info_ex3_view {
  }
  .info_box .info_ex svg {
    font-size: 12px;
  }

  .info_three > ul > li:first-child > div:last-child {
    width: 120px;

    line-height: 35px;
  }

  .info_four li svg {
    font-size: 15px;
  }
  .info_four li svg.click {
    opacity: 0.5;
  }
  .info_address_btn_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
  }
  .info_address_btn {
    width: 60px;
    height: 32px;
    background-color: #40b2e8;
    text-align: center;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
  }
}

@media all and (max-width: 640px) {
}
