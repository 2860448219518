@charset "UTF-8";

/* 리스트 */
.lesson {
  width: 100%;
  height: 100%;

  position: relative;
  /* overflow: auto; */
}
.lesson .wrap {
  width: 1700px;
  height: auto;
  margin: 0 auto;
  padding: 25px 0;
}
.lesson_box {
  /* margin: 0 -25px; */

  /* display: flex; */
  width: 100%;
  height: auto;
  padding: 0 100px;
}
.lesson_box .lesson_inner {
  width: 100%;
}
.lesson_box .lesson_inner .lesson_ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.lesson_box .lesson_inner .lesson_ul > li {
  margin-bottom: 2px;
  /* flex: 1; */
  /* grid-template-columns: repeat(3, 1fr); */
}
.lesson_box .lesson_inner .lesson_ul > li:nth-child(3n-1) {
  border-left: 4px dashed #40b3e6;
}
.lesson_box .lesson_inner .lesson_ul > li:nth-child(3n) {
  border-left: 4px dashed #40b3e6;
  /* border-right: 4px dashed #40b3e6; */
}
.lesson_box .lesson_inner .lesson_ul .lesson_div {
  padding: 20px 80px;
}
.lesson_box .lesson_inner .lesson_ul .lesson_div a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.lesson_box .lesson_inner .lesson_ul .lesson_li_imgbox {
  width: 300px;
}
.lesson_box .lesson_inner .lesson_ul .lesson_li_title {
  background-color: #f5a21f;
  border-radius: 30px;
  height: 60px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  /* font-family: "KopubDotum"; */
  font-family: "recoFont";
  font-weight: 500;
  font-size: 26px;
}
.lesson_box .lesson_inner .lesson_ul .lesson_li_part ul {
  width: 100%;
}
.lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_blue {
  height: 40px;
  width: 100px;
  color: #fff;
  background-color: #40b3e6;
  font-size: 20px;
  font-family: "recoFont";
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  border-radius: 30px;
}
.lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_text {
  font-family: "KopubDotum";
  font-weight: 600;
  line-height: 40px;
  font-size: 20px;
}
.lesson_box .lesson_inner .lesson_ul .lesson_li_part ul li {
  margin-top: 10px;
}
@media all and (max-width: 1610px) {
  .lesson .wrap {
    width: 1200px;

    padding: 25px 4.20833333333%;
  }
  .lesson_box {
    /* margin: 0 -25px; */

    /* display: flex; */
    width: 100%;
    height: auto;
    padding: 0;
  }
  .lesson_box .lesson_inner {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul {
    width: 100%;
    /* display: flex; */
  }
  .lesson_box .lesson_inner .lesson_ul > li {
    /* flex: 1; */
  }
  .lesson_box .lesson_inner .lesson_ul > li:nth-child(2) {
    /* border-left: 4px dashed #40b3e6;
    border-right: 4px dashed #40b3e6; */
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div {
    padding: 20px 30px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_imgbox {
    width: 211px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_title {
    border-radius: 30px;
    height: 60px;

    margin-top: 15px;

    font-size: 24px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_blue {
    height: 40px;
    width: 80px;

    font-size: 18px;

    line-height: 40px;
    text-align: center;
    border-radius: 30px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_text {
    font-family: "KopubDotum";
    font-weight: 600;
    line-height: 40px;
    font-size: 18px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul li {
    margin-top: 10px;
  }
}
@media all and (max-width: 1200px) {
  .lesson .wrap {
    width: 895px;

    padding: 25px 4.20833333333%;
  }
  .lesson_box {
    /* margin: 0 -25px; */

    /* display: flex; */
    width: 100%;
    height: auto;
    padding: 0;
  }
  .lesson_box .lesson_inner {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul {
    width: 100%;
    /* display: flex; */
  }
  .lesson_box .lesson_inner .lesson_ul > li {
    /* flex: 1; */
  }
  .lesson_box .lesson_inner .lesson_ul > li:nth-child(2) {
    /* border-left: 4px dashed #40b3e6;
    border-right: 4px dashed #40b3e6; */
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div {
    padding: 20px 30px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_imgbox {
    width: 157px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_title {
    border-radius: 26px;
    height: 50px;

    margin-top: 13px;

    font-size: 22px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_blue {
    height: 40px;
    width: 70px;

    font-size: 14px;

    line-height: 40px;
    text-align: center;
    border-radius: 26px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_text {
    font-family: "KopubDotum";
    font-weight: 600;
    line-height: 40px;
    font-size: 14px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul li {
    margin-top: 10px;
  }
}
@media all and (max-width: 895px) {
  .lesson .wrap {
    width: 640px;

    padding: 25px 4.20833333333%;
  }
  .lesson_box {
    /* margin: 0 -25px; */

    /* display: flex; */
    width: 100%;
    height: auto;
    padding: 0;
  }
  .lesson_box .lesson_inner {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul {
    width: 100%;
    /* display: flex; */
  }
  .lesson_box .lesson_inner .lesson_ul > li {
    /* flex: 1; */
  }
  .lesson_box .lesson_inner .lesson_ul > li:nth-child(2) {
    /* border-left: 4px dashed #40b3e6;
    border-right: 4px dashed #40b3e6; */
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div {
    padding: 20px 10px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_imgbox {
    width: 113px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_title {
    border-radius: 22px;
    height: 44px;

    margin-top: 13px;

    font-size: 18px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_blue {
    height: 34px;
    width: 55px;

    font-size: 11px;

    line-height: 34px;
    text-align: center;
    border-radius: 22px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_text {
    font-family: "KopubDotum";
    font-weight: 600;
    line-height: 34px;
    font-size: 11px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul li {
    margin-top: 10px;
  }
}
@media all and (max-width: 640px) {
  .lesson .wrap {
    width: 100%;

    padding: 25px 4.20833333333%;
  }
  .lesson_box {
    /* margin: 0 -25px; */

    /* display: flex; */
    width: 100%;
    height: auto;
    padding: 0;
  }
  .lesson_box .lesson_inner {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul {
    width: 300px;
    /* display: flex; */
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
  }
  .lesson_box .lesson_inner .lesson_ul > li {
    /* flex: 1; */
  }
  .lesson_box .lesson_inner .lesson_ul > li:nth-child(3n-1) {
    border-left: 0;
  }
  .lesson_box .lesson_inner .lesson_ul > li:nth-child(3n) {
    border-left: 0;
    /* border-right: 4px dashed #40b3e6; */
  }
  .lesson_display {
    display: none;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div {
    padding: 20px 10px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_div a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_imgbox {
    width: 228px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_title {
    border-radius: 22px;
    height: 52px;

    margin-top: 13px;

    font-size: 24px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul {
    width: 100%;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_blue {
    height: 34px;
    width: 74px;

    font-size: 15px;

    line-height: 34px;
    text-align: center;
    border-radius: 22px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul .lesson_part_text {
    font-family: "KopubDotum";
    font-weight: 600;
    line-height: 34px;
    font-size: 15px;
  }
  .lesson_box .lesson_inner .lesson_ul .lesson_li_part ul li {
    margin-top: 10px;
  }
}
