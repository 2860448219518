@charset "UTF-8";

/* 본고딕 */
@font-face {
  font-family: "NotoKr";
  font-weight: 200;
  src: url("../assets/fonts/NotoSansCJKkr-Light.woff2") format("woff2")
    url("../assets/fonts/NotoSansCJKkr-Light.woff") format("woff");
  /* src: url("../fonts/NotoSansCJKkr-Light.woff2") format("woff2"),
    url("../fonts/NotoSansCJKkr-Light.woff") format("woff"); */
}
/* 레코체 */
@font-face {
  font-family: "recoFont";
  font-weight: 400;
  src: url("../assets/fonts/Recipekorea-reco_font.ttf") format("woff"),
    url("../assets/fonts/Recipekorea-reco_font.ttf") format("woff2");
}
/* Kopub */
@font-face {
  font-family: "KopubDotum";
  font-weight: 600;
  src: url("../assets/fonts/KoPubWorld-Dotum-Bold.ttf") format("woff"),
    url("../assets/fonts/KoPubWorld-Dotum-Bold.ttf") format("woff2");
}
