@charset "UTF-8";

/* 초기화 */
a:active,
a:hover,
a:link,
a:visited {
  color: #444;
  text-decoration: none;
}
a:focus {
  text-decoration: underline;
}
button,
input,
optgroup,
select,
textarea {
  border-width: 0;
}

/* 레이아웃 공통 */
#root {
  width: 100%;
  height: 100%;
}
body,
html {
  position: relative;
  height: 100%;
}
body {
  overflow-x: hidden;
  background-color: #fff;
  color: #444;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.025em;
  font-weight: 400;
  font-family: "NotoKr", "Malgun Gothic", "맑은 고딕", "Dotum", "돋움", "Gulim",
    "굴림", sans-serif;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
}
@media all and (max-width: 640px) {
  body {
    font-size: 14px;
    line-height: 22px;
  }
}
#header {
  position: relative;
  z-index: 30;
  width: 100%;
  background-color: #fff;
}
#container {
  /* position: relative; */
  /* z-index: 10; */
  width: 100%;
  /* background-color: #fff; */
  height: 100%;
}
#container:focus {
  border: 1px solid #000;
}
#footer {
  position: relative;
  z-index: 20;
  width: 100%;
  background-color: #fff;
}
#screen {
  visibility: hidden;
  overflow: scroll;
  position: absolute;
  width: 100px;
  height: 100px;
}

/* 메뉴 */
.menu.init,
.menu.init .depth,
.menu.init .depth:after,
.menu.init .depth:before,
.menu.init .depth_item,
.menu.init .depth_item:after,
.menu.init .depth_item:before,
.menu.init .depth_list,
.menu.init .depth_list:after,
.menu.init .depth_list:before,
.menu.init .depth_text,
.menu.init .depth_text:after,
.menu.init .depth_text:before,
.menu.init .depth_title,
.menu.init .depth_title:after,
.menu.init .depth_title:before,
.menu.init:after,
.menu.init:before {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: linear;
}
.menu,
.menu .depth,
.menu .depth_item,
.menu .depth_list,
.menu .depth_text {
  position: relative;
}
.menu {
  font-size: 0;
  line-height: 0;
}
.menu .depth:not(.depth1) {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
}
.menu .depth1 {
  z-index: 30;
}
.menu .depth_list {
  z-index: 10;
}
.menu .depth_text {
  display: inline-block;
}

/* 1, 2차 메뉴 배경 */
.menu.after:after,
.menu.before:before {
  display: block;
  position: absolute;
}
.menu.before:before {
  z-index: 10;
}
.menu.after:after {
  z-index: 20;
}

/* 제목 상자 */
.menu .depth_title {
  overflow: hidden;
  position: absolute;
  z-index: 20;
}

/* 옵션 */
.menu .depth1.clearfix .depth1_list {
  float: right;
}
.menu .depth_list.clearfix > .depth_item {
  float: left;
}
.menu .depth_list.cut > .depth_item {
  display: inline-block;
  vertical-align: top;
}

/* 풀 다운 */
.menu.pulldown .depth1 {
  height: inherit;
}
.menu.pulldown .depth1_item,
.menu.pulldown .depth1_list {
  height: inherit;
}
.menu.pulldown .depth2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* 이치 다운 */
.menu.eachdown .depth1 {
  height: inherit;
}
.menu.eachdown .depth1_item,
.menu.eachdown .depth1_list {
  position: static;
  height: inherit;
}
.menu.eachdown .depth2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* 드롭 다운 */
.menu.dropdown .depth2 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transform-origin: center top;
  transform: scaleY(0);
}

/* 주메뉴 */
.lnb {
  font-size: 0;
  line-height: 0;
}
@media all and (min-width: 1001px) {
  /* 메뉴 열기, 메뉴 닫기 */
  .lnb .menu_hide,
  .lnb .menu_show {
    display: none;
  }

  /* 메뉴 넓이 */
  .lnb.length3 .menu .depth1_item {
    width: 33.33%;
  }
  .lnb.length4 .menu .depth1_item {
    width: 25%;
  }
  .lnb.length5 .menu .depth1_item {
    width: 20%;
  }
  .lnb.length6 .menu .depth1_item {
    width: 16.66%;
  }
  .lnb.length7 .menu .depth1_item {
    width: 14.28%;
  }
  .lnb.length8 .menu .depth1_item {
    width: 12.5%;
  }
}
@media all and (max-width: 1000px) {
  /* 1, 2차 메뉴 배경 */
  .menu.after:after,
  .menu.before:before {
    display: none;
  }

  /* 제목 상자 */
  .menu .depth_title {
    display: none;
  }

  /* 옵션 */
  .menu .depth1.clearfix .depth1_list {
    float: none;
  }
  .menu .depth_list.clearfix > .depth_item {
    float: none;
  }
  .menu .depth_list.cut > .depth_item {
    display: block;
  }

  /* 풀 다운 */
  .menu.pulldown .depth1,
  .menu.pulldown .depth1_item,
  .menu.pulldown .depth1_list {
    height: auto;
  }
  .menu.pulldown .depth2 {
    position: relative;
    bottom: auto;
    left: auto;
  }

  /* 이치 다운 */
  .menu.eachdown .depth1 {
    height: auto;
  }
  .menu.eachdown .depth1_item,
  .menu.eachdown .depth1_list {
    position: relative;
    height: auto;
  }
  .menu.eachdown .depth2 {
    position: relative;
    bottom: auto;
    left: auto;
  }

  /* 드롭 다운 */
  .menu.dropdown .depth2 {
    position: relative;
    top: auto;
    left: auto;
    transform: scaleY(1);
  }

  /* 메뉴 영역 */
  .lnb:before {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition-property: visibility, opacity;
    transition-duration: 0.25s;
    transition-timing-function: linear;
  }
  .lnb .menu {
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -302px;
    z-index: 50;
    width: 302px;
    height: 100%;
    transition-property: visibility, right;
    transition-duration: 0.25s;
    transition-timing-function: linear;
  }
  .lnb .wrap {
    padding-right: 0;
    padding-left: 0;
  }

  /* 메뉴 열었을 때 */
  .lnb_show,
  .lnb_show body {
    overflow: hidden;
  }
  .lnb_show .lnb .menu {
    visibility: visible;
    right: 0;
  }
  .lnb_show .lnb:before {
    visibility: visible;
    opacity: 1;
  }

  /* 1차 메뉴 */
  .lnb .menu .depth1 {
    z-index: 10;
  }

  /* 열기 버튼 */
  .lnb .menu_show {
    position: absolute;
    z-index: 30;
  }

  /* 닫기 버튼 */
  .lnb .menu_hide {
    position: absolute;
    z-index: 30;
  }
}
